/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';
import { FC, Fragment } from 'react';
import { OptionTypeBase } from 'react-select';
import {
  blue,
  blue_25,
  blue_50,
  green,
  green_25,
  green_50,
  grey_100,
  grey_600,
  indigo,
  teal,
  white,
} from '../../style/colors';
import { WithStyleProps } from '../../style/helper';
import {
  fontPrimaryBold,
  fontSecondary,
  fontSecondaryBold,
} from '../../style/text';
import { monthlyUnitsStoredRange } from '../monthly-units-stored';
import { unitsPerOrderRange } from '../units-per-order';
import { FulfillmentArrowIcon } from './fulfillment-arrow-icon';
import { StorageContainerIcon } from './storage-container-icon';
import { useDispatch, useSelector } from 'react-redux';
import { getServiceDetail } from '../../redux/selectors/get-service-detail';
import { Loading } from '../../common/loading';
import { link, textLink } from '../../style/defaults';
import { unitsPerOrderChanged } from '../unit-per-order-changed';
import { monthlyUnitsStoredChanged } from '../monthly-units-stored-changed';
import { arePricesUpdating } from '../../redux/selectors/are-prices-updating';
import { desktop } from '../../common/media/media-type';
import { Select } from '../../common/select';
import {
  HelpCenter,
  linkToWebResource,
  Website,
  Resources,
} from '../../common/web-resource';
import { useIntl } from 'react-intl';
import { productDetailMessages } from './product-detail-messages';
import { getIsLanguageTypeChinese } from '../../redux/selectors/get-is-language-type-chinese';
import { getSurge } from '../../redux/selectors/get-surge';
import { surgeChanged } from '../surge/surge-changed';
import { Toggle } from '../surge/surge-toggle';
import { surgeAvailable } from '../surge/surge-available';
import { useShippingSelect } from '../use-shipping-select';

const costCalculationStyle = css`
  padding: 1.875rem 0 0 0;
`;

const storageCostCalculationStyle = css`
  padding-top: 1.875rem;
`;

const costFormBodyStyle = css`
  padding: 0.275rem 1.25rem 1.25rem 1.25rem;
  background-color: ${white};
  color: ${grey_600};

  @media ${desktop} {
    padding: 0.1rem 2.5rem 1.875rem 2.5rem;
  }
`;

const costDetailDescriptionStyle = css`
  line-height: 1.71em;

  @media ${desktop} {
    width: 220px;
  }
`;

const costDetailEntryStyle = css`
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
`;

const costDetailHeaderStyle = css`
  align-items: center;
  display: flex;
  color: ${white};
  ${fontSecondary}
  font-size: 18px;
  font-weight: ${fontSecondaryBold};
  padding: 1.25rem;

  & > * {
    margin-right: 0.875rem;
  }

  @media ${desktop} {
    padding: 1.875rem 2.5rem;
  }
`;

const storageCostNoticeTextItemStyle = css`
  margin-bottom: 0.5rem;
`;

const pricingStyle = css`
  font-size: 2rem;
  font-weight: ${fontPrimaryBold};
`;

const unitsPerOrderOptions = unitsPerOrderRange.map((value) => ({
  value,
  label: String(value),
}));

const monthlyUnitsStoredOptions = monthlyUnitsStoredRange.map((value) => ({
  value,
  label: String(value),
}));

export type CostDetailProps = {} & WithStyleProps;

export const CostDetail: FC<CostDetailProps> = ({ className }) => {
  const dispatch = useDispatch();
  const surge = useSelector(getSurge);
  const serviceDetail = useSelector(getServiceDetail);
  const pricesUpdating = useSelector(arePricesUpdating);
  const isLanguageTypeChinese = useSelector(getIsLanguageTypeChinese);
  const { formatMessage } = useIntl();

  const surgeToggleVisible = surgeAvailable();

  const {
    countryOptions,
    countrySelectValue,
    onCountryChange,
  } = useShippingSelect();

  return (
    <div className={className}>
      <section>
        <header
          css={[
            costDetailHeaderStyle,
            css`
              border-top-left-radius: 0.25rem;
              border-top-right-radius: 0.25rem;
              background-color: ${indigo};
            `,
          ]}
        >
          <FulfillmentArrowIcon foreground={white} background={indigo} />
          {formatMessage(productDetailMessages.fulfillmentCost)}
        </header>
        <div
          css={[
            costFormBodyStyle,
            css`
              padding-top: 1.25rem;
              border-bottom: 1px solid ${grey_100};

              @media ${desktop} {
                padding-top: 20px;
              }
            `,
          ]}
        >
          {surgeToggleVisible && (
            <div
              css={css`
                margin-left: -2.5rem;
                margin-bottom: 0.5rem;
                margin-top: -0.5rem;
              `}
            >
              <Toggle
                css={css`
                  padding: 0;
                `}
                onChange={() => {
                  dispatch(surgeChanged(!surge));
                }}
                enabled={surge}
                justifyItems="flex-start"
              >
                {formatMessage(productDetailMessages.peakSurchargesToggle)}
              </Toggle>
            </div>
          )}

          <div css={costDetailEntryStyle}>
            <div
              css={[
                costDetailDescriptionStyle,
                css`
                  display: flex;
                  align-items: center;
                `,
              ]}
            >
              {formatMessage(productDetailMessages.country)}
            </div>
            <Select
              css={css`
                width: 200px;
              `}
              colors={{
                primary: blue,
                primary25: blue_25,
                primary50: blue_50,
                primary75: blue_50,
              }}
              options={countryOptions}
              value={countrySelectValue}
              onChange={onCountryChange}
              isSearchable={true}
            />
          </div>

          <div css={costDetailEntryStyle}>
            <div
              css={[
                costDetailDescriptionStyle,
                css`
                  display: flex;
                  align-items: center;
                `,
              ]}
            >
              {formatMessage(productDetailMessages.unitsPerOrder)}
            </div>
            <Select
              css={css`
                width: 58px;
                height: 38px;
              `}
              options={unitsPerOrderOptions}
              colors={{
                primary: blue,
                primary25: blue_25,
                primary50: blue_50,
                primary75: blue_50,
              }}
              value={unitsPerOrderOptions.find(
                ({ value }) => value === serviceDetail.unitsPerOrder,
              )}
              onChange={({ value }: OptionTypeBase) => {
                dispatch(unitsPerOrderChanged(value));
              }}
            />
          </div>

          <div css={costCalculationStyle}>
            <div css={pricingStyle}>
              {pricesUpdating ? (
                <Loading size={'auto'} />
              ) : (
                <Fragment>${serviceDetail.price.toFixed(2)}</Fragment>
              )}
            </div>
          </div>
        </div>
        <div css={costFormBodyStyle}>
          {surgeToggleVisible && (
            <p>
              {formatMessage(productDetailMessages.peakSurcharges, {
                learnMore: (
                  <a
                    href={linkToWebResource(
                      Website,
                      '/blog/2022-carrier-peak-surcharges/',
                    )}
                    css={[link, textLink]}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {formatMessage(
                      productDetailMessages.peakSurchargesLearnMore,
                    )}
                  </a>
                ),
              })}
            </p>
          )}
          <p>
            {formatMessage(productDetailMessages.rates, {
              rates: (
                <a
                  css={[link, textLink]}
                  href={
                    isLanguageTypeChinese
                      ? 'https://resources.deliverr.com/zh/call-0'
                      : linkToWebResource(Resources, '/scheduleacall')
                  }
                >
                  {isLanguageTypeChinese ? '点击这里' : 'Get better rates.'}
                </a>
              ),
            })}
          </p>
        </div>
      </section>
      <section>
        <header
          css={[
            costDetailHeaderStyle,
            css`
              background-color: ${teal};
            `,
          ]}
        >
          <StorageContainerIcon foreground={white} background={teal} />
          {formatMessage(productDetailMessages.storageTitle)}
        </header>
        <div
          css={[
            costFormBodyStyle,
            css`
              padding-top: 1.25rem;
              border-bottom: 1px solid ${grey_100};

              @media ${desktop} {
                padding-top: 20px;
              }
            `,
          ]}
        >
          <div css={costDetailEntryStyle}>
            <div css={costDetailDescriptionStyle}>
              {formatMessage(productDetailMessages.monthlyUnits, {
                br: <br />,
              })}
            </div>
            <Select
              css={css`
                width: 80px;
              `}
              options={monthlyUnitsStoredOptions}
              colors={{
                primary: green,
                primary25: green_25,
                primary50: green_50,
                primary75: green_50,
              }}
              value={monthlyUnitsStoredOptions.find(
                ({ value }) => value === serviceDetail.monthlyUnitsStored,
              )}
              onChange={({ value }: OptionTypeBase) => {
                dispatch(monthlyUnitsStoredChanged(value));
              }}
            />
          </div>
          <div css={storageCostCalculationStyle}>
            <div css={pricingStyle}>
              {pricesUpdating ? (
                <Loading size={'auto'} />
              ) : (
                <Fragment>${serviceDetail.storage.toFixed(2)}</Fragment>
              )}
            </div>
          </div>
        </div>
        <div css={costFormBodyStyle}>
          <ul
            css={[
              css`
                padding-inline-start: 1.4em;
                margin-block-start: 1em;
                margin-block-end: 0;
              `,
            ]}
          >
            <li css={storageCostNoticeTextItemStyle}>
              {formatMessage(productDetailMessages.totalCharge, {
                monthlyUnitsStored: serviceDetail.monthlyUnitsStored,
              })}
            </li>
            <li css={storageCostNoticeTextItemStyle}>
              {formatMessage(productDetailMessages.longTermStorage)}
            </li>
            <li css={storageCostNoticeTextItemStyle}>
              {formatMessage(productDetailMessages.currentMonth)}
            </li>
            <li css={storageCostNoticeTextItemStyle}>
              {formatMessage(productDetailMessages.peakPeriod)}
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};
