import { Select, ThemeProps } from '@deliverr/ui';
import styled from '@emotion/styled';
import { B2B_FONT_FAMILY } from '../../data/constants';

export const RoundedSelect = styled(Select)<ThemeProps>(
  ({ theme }) => `
  [class*=control] {
    border-color: ${theme.border.width.B1} ${theme.border.type} #D4D4D8;
    border-radius: 28px
  }
  [class*=singleValue] {
    font-family: ${B2B_FONT_FAMILY};
  }
  [class*=Option] {
    font-family: ${B2B_FONT_FAMILY};
  }
`,
);
