import { BoxReset, Text } from '@deliverr/ui';
import React from 'react';
import { useIntl } from 'react-intl';
import { CurrencyFormat } from '../../../../common/components/Currency';
import {
  FIRST_MONTH_STORAGE_FEE,
  LONG_TERM_STORAGE_FEE,
  RECURRING_MONTHLY_STORAGE_FEE,
} from '../../../data/B2B_RATE_CARD';
import { B2BCostCard } from '../B2BCostCard';
import { HalfWidthDiv } from '../HalfWidthDiv';
import { RoundedSelect } from '../RoundedSelect';
import { useB2BStorageCost } from './useB2BStorageCost';
import { b2bDisplayCostMessages } from '../b2bDisplayCostMessages';
import { BoldCurrency } from '../BoldCurrency';
import { B2BCostStack } from '../B2BCostStack';

export const B2BStorageCost = () => {
  const { formatMessage } = useIntl();
  const {
    monthsOfStorage,
    monthsOfStorageOptions,
    setMonthsOfStorage,
    storageCost,
  } = useB2BStorageCost();
  return (
    <B2BCostCard>
      <B2BCostStack direction="HORIZONTAL">
        <HalfWidthDiv>
          <BoxReset marginTop={null}>
            <Text bold size="bodyLarge">
              {formatMessage(b2bDisplayCostMessages.storageCostTitle)}
            </Text>
          </BoxReset>
          <BoldCurrency currency={CurrencyFormat.USD} value={storageCost} />
          <Text>
            {formatMessage(b2bDisplayCostMessages.firstMonthStorageCostDesc, {
              reserveFirstMonthStorageFee: FIRST_MONTH_STORAGE_FEE,
            })}
          </Text>
          <Text as="div">
            {formatMessage(b2bDisplayCostMessages.recurringStorageCostDesc, {
              reserveRecurringStorageFee: RECURRING_MONTHLY_STORAGE_FEE,
            })}
          </Text>
          <Text as="div">
            {formatMessage(b2bDisplayCostMessages.longTermStorageCostDesc, {
              reserveLongTermStorageFee: LONG_TERM_STORAGE_FEE,
            })}
          </Text>
        </HalfWidthDiv>
        <HalfWidthDiv>
          <RoundedSelect
            label={formatMessage(b2bDisplayCostMessages.monthsOfStorage)}
            onChange={(option) => setMonthsOfStorage(option.value)}
            options={monthsOfStorageOptions}
            value={monthsOfStorageOptions.find(
              (option) => option.value === monthsOfStorage,
            )}
          />
        </HalfWidthDiv>
      </B2BCostStack>
    </B2BCostCard>
  );
};
