import { ManualEntryForm } from './manual-entry-form';
import {
  ManualEntryValidationError,
  NegativeDimensionsError,
} from './manual-entry-validation-error';

export const validateManualEntryForm = ({
  size,
  ...form
}: ManualEntryForm): true | ManualEntryValidationError => {
  if (
    size.width <= 0 ||
    size.height <= 0 ||
    size.length <= 0 ||
    size.height <= 0
  ) {
    return NegativeDimensionsError;
  }

  return true;
};
