import React, { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { BoxReset, IconV2, Stack, Text } from '@deliverr/ui';
import { CurrencyFormat } from '../../../../common/components/Currency';
import { b2bTotalCostMessages } from './b2bTotalCostMessages';
import { B2BCostCard } from '../B2BCostCard';
import styled from '@emotion/styled';
import { useB2BCostCalculatorContext } from '../../../store/B2BCostCalculatorContext';
import { StyledSecondaryButton } from '../../../../common/components/StyledSecondaryButton';
import {
  Resources,
  linkToWebResource,
} from '../../../../../view/common/web-resource';
import { SellerPortal } from '../../../../../view/common/web-resource';
import { BoldCurrency } from '../BoldCurrency';
import { B2B_FONT_FAMILY } from '../../../data/constants';
import { StyledAnchor } from '../StyledAnchor';

const WhiteUnderlinedAnchor = styled(StyledAnchor)`
  color: white;
  text-decoration: underline;
`;

const StyledTotalCostCard = styled(B2BCostCard)`
  background-color: black;
  color: white;
`;

const StyledButton = styled(StyledSecondaryButton)`
  font-family: ${B2B_FONT_FAMILY};
`;

const B2B_SCHEDULE_A_CALL_PATH =
  '/scheduleacall?services_interested_in=Reserve Storage';

const IconWithText: FC<{ text: string }> = ({ text }) => (
  <BoxReset marginTop={'S1'}>
    <Stack direction="HORIZONTAL" gap="S1">
      <IconV2 type="check" color="#C1FBD4" size="large" />
      <Text as="div" size="body">
        {text}
      </Text>
    </Stack>
  </BoxReset>
);

export const B2BTotalCost: FC = () => {
  const { formatMessage } = useIntl();
  const { totalCost } = useB2BCostCalculatorContext();
  const onGetStartedButtonClick = useCallback(() => {
    window.open(
      `${linkToWebResource(SellerPortal)}/inbounds`,
      '_blank',
      'noopener,noreferrer',
    );
  }, []);
  const b2bScheduleACallUrl = linkToWebResource(
    Resources,
    B2B_SCHEDULE_A_CALL_PATH,
  );

  return (
    <StyledTotalCostCard>
      <BoxReset marginTop={null}>
        <Text bold size="bodyLarge">
          {formatMessage(b2bTotalCostMessages.totalCost)}
        </Text>
      </BoxReset>
      <BoldCurrency currency={CurrencyFormat.USD} value={totalCost} />
      <BoxReset marginBottom={'S4'} marginTop={'S5'}>
        <Text bold size="bodyLarge">
          {formatMessage(b2bTotalCostMessages.includedInPrice)}
        </Text>
      </BoxReset>
      <IconWithText
        text={formatMessage(b2bTotalCostMessages.fastShippingDesc)}
      />
      <IconWithText text={formatMessage(b2bTotalCostMessages.compliantDesc)} />
      <IconWithText
        text={formatMessage(b2bTotalCostMessages.d2cReplenishmentDesc)}
      />
      <IconWithText text={formatMessage(b2bTotalCostMessages.noMinOrderDesc)} />
      <BoxReset marginTop="S5">
        <StyledButton onClick={onGetStartedButtonClick}>
          {formatMessage(b2bTotalCostMessages.getStartedButton)}
        </StyledButton>
      </BoxReset>
      <Text>
        {formatMessage(b2bTotalCostMessages.letsChat, {
          a: (text) => (
            <WhiteUnderlinedAnchor
              href={b2bScheduleACallUrl}
              target="_blank noreferrer"
              key={b2bScheduleACallUrl}
              appearance="INFO"
            >
              {text}
            </WhiteUnderlinedAnchor>
          ),
        })}
      </Text>
    </StyledTotalCostCard>
  );
};
