import { createProviderWithHook } from '../helpers/provider-with-hook';
import { FullProduct } from './product';

export type GetProductSampleById = (id: string) => FullProduct | undefined;

const { provider, useHook } = createProviderWithHook<GetProductSampleById>();

export const GetProductSampleByIdProvider = provider;

export const useGetProductSampleById = useHook;
