import { VariableQueryInput } from '../../domain/variable-query-input';
import { ActiveQuery } from '../redux/state/query/active';

export const VariableInputUpdateSent = 'VARIABLE_INPUT_UPDATE_SENT';

export type VariableInputUpdateSent = ReturnType<
  typeof variableInputUpdateSent
>;

export const variableInputUpdateSent = (
  input: VariableQueryInput,
  query: Omit<ActiveQuery, 'cache'>,
) =>
  ({
    type: VariableInputUpdateSent,
    input,
    query,
  } as const);
