export const NegativeDimensionsError = 'NEGATIVE_DIMENSIONS_ERROR';
export type NegativeDimensionsError = typeof NegativeDimensionsError;

export type ManualEntryValidationError = NegativeDimensionsError;

export const isManualEntryValidationError = (
  subject: any,
): subject is ManualEntryValidationError => {
  return typeof subject === 'string' && subject === NegativeDimensionsError;
};
