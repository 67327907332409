import { useActions } from '../redux/actions-middleware/use-actions';
import { VariableInputUpdateSent } from './variable-input-update-sent';
import { ofType } from '../redux/actions-middleware/of-type';
import { GetDetailError } from '../../domain/get-detail-error';
import { useDispatch, useSelector } from 'react-redux';
import { useGetManualDetail } from '../../domain/get-manual-detail';
import { variableInputUpdateFailed } from './variable-input-update-failed';
import { variableInputUpdateSucceeded } from './variable-input-update-succeeded';
import { getDimensions } from '../redux/selectors/get-dimensions';
import { logError } from '../datadog';

export const useSendVariableInputUpdate = () => {
  const dimensions = useSelector(getDimensions);
  const dispatch = useDispatch();
  const getManualDetail = useGetManualDetail();
  const ctx = { fn: 'getVariableInputUpdate' };

  useActions<VariableInputUpdateSent>(
    async ({
      input: { surge, hasBattery, country, minimumPackagingType, ...input },
    }) => {
      try {
        const result = await getManualDetail({
          ...dimensions,
          ...input,
          surge,
          hasBattery,
          country,
          minimumPackagingType,
        });

        dispatch(
          variableInputUpdateSucceeded(
            { surge, hasBattery, country, minimumPackagingType, ...input },
            result,
          ),
        );
      } catch (error) {
        dispatch(variableInputUpdateFailed(error.message as GetDetailError));
        logError(error.message, ctx);
      }
    },
    ofType(VariableInputUpdateSent),
  );
};
