import { useB2BCostCalculatorContext } from '../../../store/B2BCostCalculatorContext';
import { range } from 'lodash';

export const useB2BStorageCost = () => {
  const {
    monthsOfStorage,
    setMonthsOfStorage,
    storageCost,
  } = useB2BCostCalculatorContext();
  const monthsOfStorageOptions = range(1, 16).map((value) => ({
    value,
    label: value,
  }));

  return {
    monthsOfStorage,
    monthsOfStorageOptions,
    setMonthsOfStorage,
    storageCost,
  };
};
