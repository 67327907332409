/** @jsx jsx */
import { jsx } from '@emotion/core';

import {
  NotAnAsinError,
  GetDetailError,
  BadDimsError,
  ProductNotFoundError,
} from '../../domain/get-detail-error';
import { textLink } from '../style/defaults';
import { HelpCenter, linkToWebResource } from '../common/web-resource';
import { ErrorMessage } from '../../domain/query-message';
import { FormattedMessage } from 'react-intl';

const errorMap: Record<GetDetailError, ErrorMessage['display']> = {
  [NotAnAsinError]: (
    <FormattedMessage
      id="errorMessage.notAnAsin"
      defaultMessage="Hmm, that doesn't look like an ASIN or an Amazon product link"
    />
  ),
  [BadDimsError]: (
    <FormattedMessage
      id="errorMessage.badDims"
      defaultMessage="Product exceeds standard size/weight limits. Please see {helpCenterArticle} for product size/weight limits and exceptions"
      values={{
        helpCenterArticle: (
          <a
            href={linkToWebResource(
              HelpCenter,
              '/hc/en-us/articles/115003488233-Product-Dimensional-Requirements',
            )}
            target="_blank"
            title="Product Dimensional Requirements"
            rel="noopener noreferrer"
            css={textLink}
          >
            here
          </a>
        ),
      }}
    />
  ),
  [ProductNotFoundError]: (
    <FormattedMessage
      id="errorMessage.productNotFound"
      defaultMessage="Unable to find this item. Please double check your ASIN or link."
    />
  ),
};

export const getDetailErrorMessage = (
  error: GetDetailError,
): ErrorMessage['display'] => errorMap[error] ?? 'An error occurred';
