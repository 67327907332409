import React from 'react';
import { useIntl } from 'react-intl';
import { Box, BoxReset, Text } from '@deliverr/ui';
import { CurrencyFormat } from '../../../../common/components/Currency';
import {
  OUTBOUND_CARTON_HANDLING_FEE,
  OUTBOUND_LABEL_FEE,
  OUTBOUND_PALLET_HANDLING_FEE,
} from '../../../data/B2B_RATE_CARD';
import { useB2BOutboundingCost } from './useB2BOutboundingCost';
import { OutboundShipmentType } from '../../../types/OutboundShipmentType';
import { B2BCostCard } from '../B2BCostCard';
import { HalfWidthDiv } from '../HalfWidthDiv';
import { RoundedSelect } from '../RoundedSelect';
import { b2bDisplayCostMessages } from '../b2bDisplayCostMessages';
import { BoldCurrency } from '../BoldCurrency';
import { B2BCostStack } from '../B2BCostStack';

export const B2BOutboundingCost = () => {
  const { formatMessage, formatNumber } = useIntl();
  const {
    outboundShipmentType,
    outboundShipmentTypeOptions,
    setOutboundShipmentType,
    outboundHandlingCost,
    outboundLabellingCost,
  } = useB2BOutboundingCost();
  return (
    <B2BCostCard>
      <B2BCostStack direction="HORIZONTAL">
        <HalfWidthDiv>
          <BoxReset marginTop={null}>
            <Text bold size="bodyLarge">
              {formatMessage(b2bDisplayCostMessages.outboundingCostTitle)}
            </Text>
          </BoxReset>
          <BoldCurrency
            currency={CurrencyFormat.USD}
            value={outboundHandlingCost}
          />
          <Text>
            {outboundShipmentType === OutboundShipmentType.FREIGHT_PALLETS
              ? formatMessage(
                  b2bDisplayCostMessages.outboundPalletHandlingCostDesc,
                  { outboundPalletHandlingFee: OUTBOUND_PALLET_HANDLING_FEE },
                )
              : formatMessage(
                  b2bDisplayCostMessages.outboundCaseHandlingCostDesc,
                  {
                    outboundCaseHandlingFee: formatNumber(
                      OUTBOUND_CARTON_HANDLING_FEE,
                      {
                        maximumFractionDigits: 2,
                        style: 'currency',
                        currencyDisplay: 'symbol',
                        currency: 'USD',
                      },
                    ),
                  },
                )}
          </Text>
          <Box marginTop={'S6'}>
            <BoldCurrency
              currency={CurrencyFormat.USD}
              value={outboundLabellingCost}
            />
          </Box>
          <Text>
            {formatMessage(b2bDisplayCostMessages.outboundLabelCostDesc, {
              outboundLabelFee: formatNumber(OUTBOUND_LABEL_FEE, {
                maximumFractionDigits: 2,
                style: 'currency',
                currencyDisplay: 'symbol',
                currency: 'USD',
              }),
            })}
          </Text>
        </HalfWidthDiv>
        <HalfWidthDiv>
          <RoundedSelect
            label={formatMessage(b2bDisplayCostMessages.shippingMethod)}
            onChange={(option) => setOutboundShipmentType(option.value)}
            options={outboundShipmentTypeOptions}
            value={outboundShipmentTypeOptions.find(
              (option) => option.value === outboundShipmentType,
            )}
          />
        </HalfWidthDiv>
      </B2BCostStack>
    </B2BCostCard>
  );
};
