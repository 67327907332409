import { GetDetailError } from '../../../domain/get-detail-error';

export const AmazonQueryFailed = 'AMAZON_QUERY_FAILED';
export type AmazonQueryFailed = ReturnType<typeof amazonQueryFailed>;

export const amazonQueryFailed = (error: GetDetailError) =>
  ({
    type: AmazonQueryFailed,
    error,
  } as const);
