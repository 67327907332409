import { ManualEntryValidationFailed } from '../../../../view/product-entry/manual-entry/manual-entry-validation-failed';
import { ManualEntryValidationError } from '../../../../domain/entry/manual-entry-validation-error';
import { ManualQuerySent } from '../../../product-entry/manual-entry/manual-query-sent';
import { ProductQuerySent } from '../../../main/product-query-sent';
import { AmazonQuerySent } from '../../../product-entry/amazon-entry/amazon-query-sent';

export type EntryError = null | ManualEntryValidationError;

export const initialEntryError = null;

export type EntryErrorAction =
  | ManualEntryValidationFailed
  | ManualQuerySent
  | ProductQuerySent
  | AmazonQuerySent;

export const entryError = (
  state = initialEntryError,
  action: EntryErrorAction,
) => {
  switch (action.type) {
    case ManualEntryValidationFailed:
      return action.error;
    case ManualQuerySent:
    case ProductQuerySent:
    case AmazonQuerySent:
      return initialEntryError;
    default:
      return state;
  }
};
