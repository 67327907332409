/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';
import { FC } from 'react';

import { midnight_blue } from '../style/colors';
import { WithStyleProps } from '../style/helper';
import { Nav } from './nav';
import { Hero } from './hero';
import { notDesktop } from '../common/media/media-type';

export const bannerStyle = css`
  background-color: ${midnight_blue};
`;

const bannerContentStyle = css`
  @media ${notDesktop} {
    padding-left: 8vw;
    padding-right: 8vw;
  }
`;

export type HeaderProps = WithStyleProps &
  WithStyleProps<'bodyStyle'> & {
    isLanguageTypeChinese: boolean;
  };

export const Header: FC<HeaderProps> = ({
  className,
  bodyStyle,
  isLanguageTypeChinese,
}) => (
  <header className={className} css={bannerStyle}>
    <div css={[bodyStyle, bannerContentStyle]}>
      <Nav />
      <Hero isLanguageTypeChinese={isLanguageTypeChinese} />
    </div>
  </header>
);
