import { BoxReset, Stack, Text, ThemeProps } from '@deliverr/ui';
import React, { FC } from 'react';
import { B2BInboundingCost } from '../B2BInboundingCost/B2BInboundingCost';
import { B2BStorageCost } from '../B2BStorageCost/B2BStorageCost';
import { B2BOutboundingCost } from '../B2BOutboundingCost/B2BOutboundingCost';
import { B2BTotalCost } from '../B2BTotalCost/B2BTotalCost';
import styled from '@emotion/styled';
import { b2bDisplayCostMessages } from '../b2bDisplayCostMessages';
import { RoundedNumberInput } from '../RoundedNumberInput';
import { useIntl } from 'react-intl';
import { useB2BDisplayCostLayout } from './useB2BDisplayCostLayout';
import { StyledAnchor } from '../StyledAnchor';

const D2C_COST_CALCULATOR_URL = 'https://cost-calculator.deliverr.com';

const StyledNumberInput = styled(RoundedNumberInput)<ThemeProps>(
  ({ theme }) => `
  width: 90%;

  @media screen and (max-width: ${theme.breakpoints.MD}) {
    width: 82%;
  }
`,
);

export const B2BDisplayCostLayout: FC = () => {
  const { formatMessage } = useIntl();
  const {
    numOfPallets,
    handleNumberOfPalletsChange,
  } = useB2BDisplayCostLayout();

  return (
    <Stack direction="VERTICAL" gap="S5">
      <StyledNumberInput
        label={formatMessage(b2bDisplayCostMessages.pallets)}
        value={numOfPallets}
        onChange={handleNumberOfPalletsChange}
        type="number"
        min={1}
      />

      <B2BInboundingCost />
      <B2BStorageCost />
      <B2BOutboundingCost />
      <B2BTotalCost />
      <BoxReset marginBottom="S6">
        <Text appearance="INFO">
          {formatMessage(b2bDisplayCostMessages.d2cPricingFootNote, {
            a: (text) => (
              <StyledAnchor
                href={D2C_COST_CALCULATOR_URL}
                target="_blank noreferrer"
                key={D2C_COST_CALCULATOR_URL}
                appearance="INFO"
              >
                {text}
              </StyledAnchor>
            ),
          })}
        </Text>
      </BoxReset>
    </Stack>
  );
};
