import { useDispatch } from 'react-redux';
import { useGetFulfillmentDetail } from '../../domain/get-fulfillment-detail';
import { AmazonQuerySent } from '../product-entry/amazon-entry/amazon-query-sent';
import { ofType } from '../redux/actions-middleware/of-type';
import { amazonQuerySucceeded } from '../product-entry/amazon-entry/amazon-query-success';
import { useActions } from '../redux/actions-middleware/use-actions';
import { amazonQueryFailed } from '../product-entry/amazon-entry/amazon-query-failed';
import { GetDetailError } from '../../domain/get-detail-error';
import { logError } from '../datadog';

export const useSendAmazonQuery = () => {
  const getFulfillmentDetail = useGetFulfillmentDetail();
  const dispatch = useDispatch();
  const ctx = { fn: 'getFulfillmentDetail' };

  useActions<AmazonQuerySent>(
    async ({
      input: { surge, hasBattery, country, minimumPackagingType, ...input },
    }) => {
      try {
        const result = await getFulfillmentDetail({
          ...input,
          surge,
          hasBattery,
          country,
          minimumPackagingType,
        });
        dispatch(
          amazonQuerySucceeded(
            {
              ...input,
              surge,
              hasBattery,
              country,
              minimumPackagingType,
            },
            result,
          ),
        );
      } catch (error) {
        dispatch(amazonQueryFailed(error.message as GetDetailError));
        logError(error.message, ctx);
      }
    },
    ofType(AmazonQuerySent),
  );
};
