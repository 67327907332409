import { combineReducers } from 'redux';
import { ActiveQuery, activeQuery as active } from './active';
import { LastSentQuery, lastSentQuery as lastSent } from './last-sent';

export type Query = {
  active: ActiveQuery;
  lastSent: LastSentQuery;
};

export const query = combineReducers({
  active,
  lastSent,
});
