import { Reducer } from 'redux';
import { Amazon, EntryType } from '../../../domain/entry-type';
import { TabSelected } from '../../product-entry-type/change-tab';

export type ActiveEntryType = EntryType;

export const defaultActiveEntryType = Amazon;

export type ActiveEntryTypeAction = TabSelected;

export const activeEntryType: Reducer<
  ActiveEntryType,
  ActiveEntryTypeAction
> = (state = defaultActiveEntryType, action) => {
  switch (action.type) {
    case TabSelected:
      return action.tab;
    default:
      return state;
  }
};
