import stubTrue from 'lodash/fp/stubTrue';
import { useContext, useState, useEffect, useRef } from 'react';
import { ActionsContext } from './actions-middleware';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { doNothing } from '../../../helpers/do-nothing';

export const useActions = <A extends { type: string }>(
  handler: (action: A) => void = doNothing,
  actionFilter: (action: A) => boolean = stubTrue,
) => {
  const { actions } = useContext<ActionsContext>(ActionsContext);
  const subs = useRef(new Subscription());
  const [subscribed, setSubscribed] = useState(false);

  if (!subscribed) {
    subs.current.add(actions.pipe(filter(actionFilter)).subscribe(handler));
    setSubscribed(true);
  }

  useEffect(() => {
    subs.current.unsubscribe();
    subs.current = new Subscription();
    subs.current.add(actions.pipe(filter(actionFilter)).subscribe(handler));
  }, [actions, actionFilter, handler]);
};
