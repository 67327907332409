/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';
import { FC } from 'react';
import { FooterColumn } from './footer-column';
import { WithStyleProps } from '../style/helper';
import {
  fontPrimaryBold,
  fontSecondary,
  fontSecondaryBold,
} from '../style/text';
import { indigo, indigo_5, midnight_blue, white } from '../style/colors';
import { link, buttonLink } from '../style/defaults';

import tinycolor from 'tinycolor2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter,
  faLinkedin,
  faFacebookSquare,
} from '@fortawesome/free-brands-svg-icons';
import { useSelector } from 'react-redux';
import {
  linkToWebResource,
  linkToWebsite as linkToWebsiteCreator,
  Resources,
  SignUp,
} from '../common/web-resource';
import { desktop, notDesktop } from '../common/media/media-type';
import { useIntl } from 'react-intl';
import { footerMessages } from './footerMessages';
import { LanguageSelect } from './language-select';
import { getIsLanguageTypeChinese } from '../redux/selectors/get-is-language-type-chinese';

const footerStyle = css`
  color: #fff;
  background-color: ${midnight_blue};
`;

const headingStyle = css`
  ${fontSecondary}
  font-size: 28px;
  line-height: 1.5em;
  font-weight: ${fontSecondaryBold};
`;

const signUpStyle = css`
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;

  @media ${desktop} {
    padding-top: 60px;
    padding-bottom: 110px;
  }
`;

const subCtaStyle = css`
  color: ${white};
  letter-spacing: 0.31px;
  margin-top: 20px;
  opacity: 0.5;

  a {
    color: inherit;
  }
`;

const linkStyle = css`
  color: ${white};
  font-weight: ${fontPrimaryBold};

  &:hover {
    text-decoration: underline;
  }
`;

const iconLinkStyle = css`
  color: ${white};
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 1;
  }
`;

const signupButtonStyle = css`
  font-weight: bold;
  margin-top: 35px;
  height: 42px;
  padding: 0 32px;
  color: ${white};
  background-color: ${indigo};
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: ${indigo_5};
  }
`;

const legalStyle = css`
  color: ${tinycolor(white).setAlpha(0.5).toRgbString()};
`;

const legalLinkStyle = css`
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
  color: inherit;

  &:first-of-type {
    margin-left: 15px;
  }

  &:not(:last-of-type):after {
    position: absolute;
    right: -2px;
    content: '|';
  }
`;

const listColumnStyle = css`
  display: grid;
  grid-gap: 16px;
`;

const linksStyle = css`
  line-height: 1.3em;
  padding-bottom: 50px;

  @media ${desktop} {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 15% 15% 22.5% 22.5% auto;
  }
`;
export type FooterProps = WithStyleProps & WithStyleProps<'bodyStyle'>;

export const Footer: FC<FooterProps> = ({ className, bodyStyle = '' }) => {
  const isLanguageTypeChinese = useSelector(getIsLanguageTypeChinese);
  const { formatMessage } = useIntl();

  const linkToWebsite = linkToWebsiteCreator(isLanguageTypeChinese);

  return (
    <footer className={className} css={footerStyle}>
      <div
        css={[
          bodyStyle,
          css`
            padding: 44px 15px 34px;
          `,
        ]}
      >
        <div css={signUpStyle}>
          <div css={headingStyle}>{formatMessage(footerMessages.heading)}</div>
          <p>{formatMessage(footerMessages.subText)}</p>
          <a
            css={[buttonLink, signupButtonStyle]}
            href={linkToWebResource(SignUp)}
          >
            {formatMessage(footerMessages.getStarted)}
          </a>
          <p css={subCtaStyle}>
            {formatMessage(footerMessages.letsChat, {
              letsChat: (
                <a
                  href={linkToWebResource(
                    Resources,
                    isLanguageTypeChinese ? '/zh/call' : '/scheduleacall',
                  )}
                >
                  {isLanguageTypeChinese
                    ? '联系Flexport客户经理'
                    : "Let's chat!"}
                </a>
              ),
            })}
          </p>
        </div>
        <div css={linksStyle}>
          <FooterColumn heading={formatMessage(footerMessages.products)}>
            <div css={listColumnStyle}>
              <div>
                <a
                  css={[link, linkStyle]}
                  href={linkToWebsite('/products/d2c-fulfillment')}
                >
                  {formatMessage(footerMessages.d2c)}
                </a>
              </div>
              <div>
                <a
                  css={[link, linkStyle]}
                  href={linkToWebsite('/products/wholesale-fulfillment')}
                >
                  {formatMessage(footerMessages.wholesale)}
                </a>
              </div>
              <div>
                <a
                  css={[link, linkStyle]}
                  href={linkToWebsite('/products/parcel')}
                >
                  {formatMessage(footerMessages.parcel)}
                </a>
              </div>
              <div>
                <a
                  css={[link, linkStyle]}
                  href={linkToWebsite('/products/replenishment')}
                >
                  {formatMessage(footerMessages.replenishment)}
                </a>
              </div>
              <div>
                <a
                  css={[link, linkStyle]}
                  href={linkToWebsite('/products/prep')}
                >
                  {formatMessage(footerMessages.prep)}
                </a>
              </div>
            </div>
          </FooterColumn>
          <FooterColumn heading={formatMessage(footerMessages.company)}>
            <div css={listColumnStyle}>
              <div>
                <a
                  css={[link, linkStyle]}
                  href={linkToWebsite('/company/about-us')}
                >
                  {formatMessage(footerMessages.aboutUs)}
                </a>
              </div>
              <div>
                <a
                  css={[link, linkStyle]}
                  href={linkToWebsite('/company/careers')}
                >
                  {formatMessage(footerMessages.careers)}
                </a>
              </div>
              <div>
                <a css={[link, linkStyle]} href={linkToWebsite('/blog')}>
                  {formatMessage(footerMessages.blog)}
                </a>
              </div>
            </div>
          </FooterColumn>
          <FooterColumn heading={formatMessage(footerMessages.caseStudies)}>
            <div css={listColumnStyle}>
              <div>
                <a
                  css={[link, linkStyle]}
                  href={linkToWebsite('/customers/ritual-zero-proof')}
                >
                  {formatMessage(footerMessages.ritualZeroProof)}
                </a>
              </div>
              <div>
                <a
                  css={[link, linkStyle]}
                  href={linkToWebsite('/customers/kizik')}
                >
                  {formatMessage(footerMessages.kizik)}
                </a>
              </div>
            </div>
          </FooterColumn>
          <div />
          <div
            css={css`
              @media ${desktop} {
                text-align: right;
                justify-content: flex-end;
              }

              &:first-child {
                margin-right: 16px;
              }

              & * {
                margin-left: 8px;
              }

              @media ${notDesktop} {
                & * {
                  margin-left: 0;
                  margin-right: 8px;
                }
              }
            `}
          >
            <div
              css={css`
                margin-bottom: 8px;

                @media ${notDesktop} {
                }
              `}
            >
              {formatMessage(footerMessages.followUs)}
            </div>
            <div>
              <a
                css={[link, iconLinkStyle]}
                href="https://www.linkedin.com/company/flexport"
              >
                <FontAwesomeIcon size="lg" icon={faLinkedin} />
              </a>
              <a
                css={[link, iconLinkStyle]}
                href="https://www.facebook.com/flexport"
              >
                <FontAwesomeIcon size="lg" icon={faFacebookSquare} />
              </a>
              <a
                css={[link, iconLinkStyle]}
                href="https://twitter.com/flexport"
              >
                <FontAwesomeIcon size="lg" icon={faTwitter} />
              </a>
            </div>
            <LanguageSelect />
          </div>
        </div>
        <div css={legalStyle}>
          &copy; 2023 Flexport, Inc.{' '}
          <a css={[link, legalLinkStyle]} href={linkToWebsite('/privacy')}>
            {formatMessage(footerMessages.privacy)}
          </a>
        </div>
      </div>
    </footer>
  );
};
