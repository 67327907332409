import css from '@emotion/css/macro';

export const fontPrimary = css`
  font-family: gt-america, sans-serif;
`;

export const fontSecondary = css`
  font-family: gt-america-extended, sans-serif;
`;

export const fontPrimaryNormal = 400;

export const fontPrimaryBold = 700;

export const fontPrimaryBlack = 900;

export const fontSecondaryBold = 700;
