/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';
import { FC } from 'react';
import { heading, text } from '../style/defaults';
import { white } from '../style/colors';
import { WithStyleProps } from '../style/helper';
import { desktop } from '../common/media/media-type';
import { FormattedMessage } from 'react-intl';

export type HeroProps = { isLanguageTypeChinese: boolean } & WithStyleProps;

export const Hero: FC<HeroProps> = ({ className, isLanguageTypeChinese }) => (
  <div
    className={className}
    css={css`
      margin-top: 60px;
      padding-bottom: 130px;
      color: ${white};
      text-align: center;
    `}
  >
    <h1
      css={[
        text,
        heading,
        css`
          font-size: 36px;
          margin-bottom: 20px;
        `,
      ]}
    >
      <FormattedMessage
        id="banner.hero.costCalculator"
        defaultMessage="Cost Calculator"
      />
    </h1>
    <p
      css={[
        text,
        css`
          margin: 0 auto;
          font-size: 18px;

          @media ${desktop} {
            width: 77%;
          }
        `,
      ]}
    >
      <FormattedMessage
        id="banner.hero.headerTextOne"
        defaultMessage="Enter your product information and see what you would pay with Flexport Fulfillment."
        values={{
          boldHeaderText: (
            <span
              css={css`
                font-weight: bold;
              `}
            >
              Input your Amazon product link or ASIN or enter your product
              information,
            </span>
          ),
        }}
      />
    </p>
  </div>
);
