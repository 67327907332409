import { createProviderWithHook } from '../helpers/provider-with-hook';

export type ModelVersion = number;

const { provider, useHook } = createProviderWithHook<ModelVersion>();

export const ModelVersionProvider = provider;

export const useModelVersion = useHook;

export const ModelVersionKey = 'deliverrCostCalculator.modelVersion';

export const getStoredModelVersion = () =>
  JSON.parse(localStorage.getItem(ModelVersionKey));

export const storeModelVersion = (modelVersion: ModelVersion) =>
  localStorage.setItem(ModelVersionKey, JSON.stringify(modelVersion));

export const modelHasBreakingChange = (
  storedVersion: ModelVersion,
  currentVersion: ModelVersion,
) => storedVersion !== currentVersion;

export const initializeNewModel = (version: ModelVersion) => {
  localStorage.clear();
  storeModelVersion(version);
};
