/** @jsx jsx */
import { jsx } from '@emotion/core';
import { WithStyleProps } from '../style/helper';
import css from '@emotion/css/macro';
import { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

export type LoadingProps = {
  size?: string;
  icon?: JSX.Element;
} & WithStyleProps;

export const loadingContainerStyle = css`
  display: flex;
  flex-flow: column;
  align-items: center;
`;

export const iconStyle = (size: string) =>
  size === 'auto'
    ? ''
    : css`
        width: ${size} !important;
        height: ${size};
      `;

export const defaultLoadingIcon = (
  <FontAwesomeIcon css={iconStyle('auto')} icon={faCircleNotch} spin={true} />
);

export const Loading: FC<LoadingProps> = ({
  className,
  children,
  icon = defaultLoadingIcon,
}) => (
  <div className={className} css={loadingContainerStyle}>
    {icon}
    {children}
  </div>
);
