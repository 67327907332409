import React, { FC } from 'react';
import { Grid, useAboveExtraLarge } from '@deliverr/ui';
import { exampleProducts } from '../../data/exampleProductsData';
import { B2BProductTile } from './B2BProductTile';
import { useSampleProductsGrid } from './useSampleProductsGrid';
import styled from '@emotion/styled';

const StyledGrid = styled(Grid)`
  grid-auto-rows: min-content;
`;

export const SampleProductsGrid: FC = () => {
  const { selectedId, handleProductSelect } = useSampleProductsGrid();
  const isAboveXLarge = useAboveExtraLarge();
  const columns = isAboveXLarge ? '1fr 1fr 1fr' : '1fr 1fr';
  return (
    <StyledGrid columns={columns} gap={'S5'}>
      {exampleProducts.map((storageProduct) => (
        <B2BProductTile
          product={storageProduct}
          onClick={handleProductSelect(storageProduct)}
          key={storageProduct.id}
          isSelected={selectedId === storageProduct.id}
        />
      ))}
    </StyledGrid>
  );
};
