import { createSelector } from 'reselect';
import { isDetailQueryInFlight } from './is-detail-query-in-flight';
import { getLastSentQuery } from './get-last-sent-query';

export const arePricesUpdating = createSelector(
  isDetailQueryInFlight,
  getLastSentQuery,
  (detailQueryInFlight, lastSentQuery) =>
    (detailQueryInFlight && lastSentQuery?.meta?.update) || false,
);
