import React, { FC } from 'react';

export type StorageContainerIconsProps = {
  foreground: string;
  background: string;
};
export const StorageContainerIcon: FC<StorageContainerIconsProps> = ({
  foreground,
  background,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
  >
    <g fill="none">
      <rect width="28" height="28" fill={foreground} rx="2" opacity=".7" />
      <rect
        width="22.909"
        height="22.909"
        x="2.545"
        y="2.545"
        fill={background}
        rx="1"
      />
      <g fill={foreground}>
        <path
          d="M0 4.242H12.727V11.878H0z"
          transform="translate(7.636 8.485)"
        />
        <path
          d="M2.386 0L10.341 0 12.727 4.242 0 4.242z"
          opacity=".7"
          transform="translate(7.636 8.485)"
        />
      </g>
    </g>
  </svg>
);
