import { Box } from '@deliverr/ui';
import React, { FC } from 'react';
import { B2BDisplayCostLayout } from '../B2BDisplayCost/B2BDisplayCostLayout/B2BDisplayCostLayout';
import { ManualInputMobile } from './ManualInputMobile';

export const B2BCostCalculatorMobileLayout: FC = () => {
  return (
    <Box margin="S3">
      <ManualInputMobile />
      <Box marginTop="S2">
        <B2BDisplayCostLayout />
      </Box>
    </Box>
  );
};
