import React from 'react';
import { FormattedMessage } from 'react-intl';
import { EntryValidationError } from '../../domain/entry/entry-validation-error';
import { NegativeDimensionsError } from '../../domain/entry/manual-entry-validation-error';
import { ErrorMessage } from '../../domain/query-message';

export const errorMap: Record<EntryValidationError, ErrorMessage['display']> = {
  [NegativeDimensionsError]: (
    <FormattedMessage
      id="errorMessage.negativeDims"
      defaultMessage="Width, height, length or weight must be greater than 0."
    />
  ),
};

export const getEntryErrorMessage = (
  error: EntryValidationError,
): ErrorMessage['display'] => errorMap[error] ?? 'Input is invalid.';
