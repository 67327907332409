import { Button, ThemeProps } from '@deliverr/ui';
import styled from '@emotion/styled';

export const StyledPrimaryButton = styled(Button)<ThemeProps>(
  ({ theme }) => `
    background: black;
    border: ${theme.border.width.B1} ${theme.border.type} black;
    border-radius: 100px;
    color: white;
    border-color: black;
    :hover {
      background-color: white;
      color: black;
    }

    :disabled, &[disabled] {
      color: ${theme.colors.NEUTRAL['60']};
      background-color: grey;
      border-color: grey;

      > div {
        background-color: grey;
        border-radius: 100px;
      }
      > div > svg {
        color: white;
      }  
    }
  `,
);
