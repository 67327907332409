import { createSelector } from 'reselect';

import { getActiveQuery } from './get-active-query';
import { defaultWeightUnit } from '../../../domain/entry/manual-entry-form';

export const getWeightUnit = createSelector(
  getActiveQuery,
  (query) =>
    (query?.type === 'manual' && query.values.weight.unit) || defaultWeightUnit,
);
