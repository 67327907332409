import { GetDetailError } from '../../domain/get-detail-error';

export const VariableInputUpdateFailed = 'VARIABLE_INPUT_UPDATE_FAILED';

export type VariableInputUpdateFailed = ReturnType<
  typeof variableInputUpdateFailed
>;

export const variableInputUpdateFailed = (error: GetDetailError) =>
  ({
    type: VariableInputUpdateFailed,
    error,
  } as const);
