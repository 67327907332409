import React, { FC, Provider, Fragment } from 'react';
import { GetProductSamplesProvider } from '../domain/get-product-samples';
import { GetProductSampleByIdProvider } from '../domain/get-product-sample-by-id';
import { GetProductSampleByAsinProvider } from '../domain/get-product-sample-by-asin';
import { GetFulfillmentDetailProvider } from '../domain/get-fulfillment-detail';
import { getProductSamples } from './get-product-samples';
import { getProductSampleByAsin } from './get-product-sample-by-asin';
import { getProductSampleById } from './get-product-sample-by-id';
import { getFulfillmentDetail } from './get-fulfillment-detail';
import { GetManualDetailProvider } from '../domain/get-manual-detail';
import { getManualDetail } from './get-manual-detail';
import { GetUserIsLoggedInProvider } from '../domain/get-user-is-logged-in';
import { getUserIsLoggedIn } from './get-user-is-logged-in';
import { ModelVersionProvider } from '../domain/model-version';
import { modelVersion } from './model-version';

type ProviderWithComponent<T> = [Provider<T>, T];

const providers: Array<ProviderWithComponent<any>> = [
  [GetProductSamplesProvider, getProductSamples],
  [GetProductSampleByAsinProvider, getProductSampleByAsin],
  [GetProductSampleByIdProvider, getProductSampleById],
  [GetFulfillmentDetailProvider, getFulfillmentDetail],
  [GetManualDetailProvider, getManualDetail],
  [GetUserIsLoggedInProvider, getUserIsLoggedIn],
  [ModelVersionProvider, modelVersion],
];

export const Providers: FC = providers.reduce<any>(
  (Parent, [Provider, value]) => ({ children }) => (
    <Parent>
      <Provider value={value}>{children}</Provider>
    </Parent>
  ),
  Fragment,
);
