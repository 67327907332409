import { css } from '@emotion/core';
import { black, blue } from './colors';
import { fontSecondary, fontPrimary, fontSecondaryBold } from './text';

export const base = css`
  color: ${black};
`;

export const link = css`
  text-decoration: none;
`;

export const hoverTransition = (...props: string[]) => css`
  transition: ${props.map((prop) => `${prop} 0.3s ease-in-out`).join(', ')};
`;

export const textLink = css`
  color: ${blue};
  font-weight: bold;
`;

export const input = css`
  border-width: 0;
  ${fontPrimary}
  color: ${black};

  &:focus {
    outline: none;
  }
`;

export const button = css`
  cursor: pointer;
  ${fontPrimary}
`;

export const iconButton = css`
  background-color: transparent;
  border: 0;
  cursor: pointer;
  padding: 0;

  &:focus {
    outline: none;
  }
`;

export const buttonIcon = css`
  margin-left: 5px;
  font-weight: normal;
`;

export const heading = css`
  ${fontSecondary}
  font-weight: ${fontSecondaryBold};
`;

export const text = css`
  line-height: 1.5em;
`;

export const buttonLink = css`
  border-radius: 2px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
`;
