/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { FullProduct } from '../../domain/product';
import { productQuerySent } from '../main/product-query-sent';
import { white, grey_600, blue } from '../style/colors';
import { WithStyleProps } from '../style/helper';

const tileStyle = css`
  text-align: center;
`;

const imageLinkStyle = css`
  height: 154px;
  padding: 15px;
  background-color: ${white};
  box-shadow: 0 1px 4px -1px rgba(2, 23, 44, 0.2);
  border-radius: 4px;
  display: block;
  margin-bottom: 10px;
  cursor: pointer;
  border: 2px solid transparent;

  &:hover {
    border: 2px solid ${blue};
  }
`;

const imageStyle = css`
  width: 154px;
  height: 154px;
  object-fit: contain;
`;

const linkDisplayStyle = css`
  color: ${grey_600};
  margin-bottom: 15px;
  cursor: pointer;
`;

export type ProductSampleTileProps = {
  product: FullProduct;
} & WithStyleProps;

export const ProductSampleTile: FC<ProductSampleTileProps> = ({
  product: [name, { id, title, imageUrl }],
  className,
}) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  return (
    <div css={tileStyle} className={className}>
      <span
        onClick={() => {
          dispatch(productQuerySent({ productId: id }));
        }}
        css={imageLinkStyle}
      >
        <img src={imageUrl} css={imageStyle} alt={title} />
      </span>
      <span
        onClick={() => {
          dispatch(productQuerySent({ productId: id }));
        }}
        css={[linkDisplayStyle]}
      >
        {formatMessage(name)}
      </span>
    </div>
  );
};
