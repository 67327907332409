/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';
import { FC, ReactNode } from 'react';
import { notDesktop } from '../common/media/media-type';

export type FooterColumnProps = {
  heading: ReactNode;
};

export const FooterColumn: FC<FooterColumnProps> = ({ heading, children }) => (
  <div
    css={css`
      @media ${notDesktop} {
        margin-bottom: 30px;
      }
    `}
  >
    <div
      css={css`
        color: #808b95;
        margin-bottom: 10px;
        font-size: 12px;
        font-weight: 700;
        line-height: 1.3em;
        text-transform: uppercase;
      `}
    >
      {heading}
    </div>
    {children}
  </div>
);
