import { defineMessages } from 'react-intl';

export const b2bProductTileMessages = defineMessages({
  unitsPerCase: {
    id: 'b2b.rateCalculator.productTile.unitsPerCase',
    defaultMessage: 'Units per case: {unitsPerCase}',
  },
  casesPerPallet: {
    id: 'b2b.rateCalculator.productTile.casesPerPallet',
    defaultMessage: 'Cases per pallet: {casesPerPallet}',
  },
});
