/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';
import { FC, Fragment, useMemo, useCallback, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import differenceBy from 'lodash/fp/differenceBy';
import { useDispatch, useSelector } from 'react-redux';

import { ProductSampleTile } from '../common/product-sample-tile';
import { FullProduct } from '../../domain/product';
import { EntryType } from '../../domain/entry-type';
import { useGetProductSamples } from '../../domain/get-product-samples';
import { secondary } from '../style/colors';
import { button, buttonIcon, heading, text } from '../style/defaults';
import { fontPrimaryBold } from '../style/text';
import { productSamplesCollectionSize } from '../../provider/get-product-samples';
import { productSamplesReceived } from './product-samples-received';
import { getProductSamples as productSamplesSelector } from '../redux/selectors/get-product-samples';
import { moreProductSamplesReceived } from './more-product-samples-received';
import { useMediaTypes } from '../common/media/use-media-types';
import { notDesktop } from '../common/media/media-type';
import { FormattedMessage } from 'react-intl';

export type SampleProductsProps = {
  entryType: EntryType;
};

export const SampleProducts: FC<SampleProductsProps> = ({ entryType }) => {
  const getProductSamples = useGetProductSamples();
  const dispatch = useDispatch();
  const productSamples = useSelector(productSamplesSelector);
  const { isDesktop } = useMediaTypes();

  useEffect(() => {
    dispatch(productSamplesReceived(getProductSamples({ count: 5 })));
  }, [dispatch, entryType, getProductSamples]);

  const showMoreSamples = useMemo(
    () =>
      !(productSamples.length === productSamplesCollectionSize || !isDesktop),
    [productSamples, isDesktop],
  );

  const getMoreProductSamples = useCallback(() => {
    const restSamples = differenceBy(
      ([, { id }]: FullProduct) => id,
      getProductSamples(),
      productSamples,
    );
    dispatch(moreProductSamplesReceived(restSamples));
  }, [dispatch, productSamples, getProductSamples]);

  return (
    <Fragment>
      <h3
        css={[
          heading,
          text,
          css`
            font-size: 18px;
            font-weight: ${fontPrimaryBold};
            text-align: center;
          `,
        ]}
      >
        <FormattedMessage
          id="main.sample.title"
          defaultMessage="Check Out Some Sample Products"
        />
      </h3>
      <div
        css={css`
          display: grid;
          grid-template-columns: repeat(auto-fill, 184px);
          gap: 15px;
          justify-content: space-between;
          transition: height 0.6s ease-in-out;

          @media ${notDesktop} {
            display: flex;
            gap: 28px;
            overflow: auto;
            margin-right: -9%;
          }
        `}
      >
        {productSamples.map((fullProduct) => {
          const [, product] = fullProduct;
          return <ProductSampleTile key={product.id} product={fullProduct} />;
        })}
      </div>
      {showMoreSamples && (
        <div
          css={css`
            margin-top: 40px;
            text-align: center;
          `}
        >
          <button
            css={[
              button,
              css`
                border: 1px solid ${secondary};
                color: ${secondary};
                padding: 10px 20px;
                font-weight: 700;
                border-radius: 2px;
                background-color: transparent;
              `,
            ]}
            onClick={getMoreProductSamples}
          >
            <FormattedMessage
              id="main.sample.moreSamples"
              defaultMessage="More Samples"
            />
            <FontAwesomeIcon css={buttonIcon} icon={faChevronDown} />
          </button>
        </div>
      )}
    </Fragment>
  );
};
