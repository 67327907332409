import css, { Interpolation } from '@emotion/css/macro';

export type WithStyleProps<T extends string = 'className'> = Partial<
  Record<T, T extends 'className' ? string : Interpolation>
>;

export const superscript = css`
  font-size: 50%;
  vertical-align: super;
`;

export const unstyledButton = css`
  font-family: inherit;
  border: 0;
  padding: 0;
  background-color: transparent;
`;

export const showWhen = (condition: boolean, display: string = 'block') =>
  condition
    ? css`
        display: ${display};
      `
    : css`
        display: none;
      `;

export const hideWhen = (condition: boolean) =>
  condition &&
  css`
    display: none;
  `;
