import React, { FC } from 'react';
import { StorageProduct } from '../../types/StorageProduct';
import styled from '@emotion/styled';
import { Box, BoxReset, Text, ThemeProps } from '@deliverr/ui';
import { useIntl } from 'react-intl';
import { b2bProductTileMessages } from './b2bProductTileMessages';

export interface B2BProductTileProps {
  product: StorageProduct;
  onClick: () => void;
  isSelected?: boolean;
}

const StyledTile = styled.button<{ isSelected: boolean } & ThemeProps>(
  ({ theme, isSelected }) => `
  text-align: center;
  background-color: white;
  width: 12.5rem;
  height: 13.5rem;
  cursor: pointer;
  box-shadow: 0px 8px 48px #EEEEEE, 0px 4px 8px rgba(66, 71, 76, 0.06), 0px 0px 1px rgba(66, 71, 76, 0.48);
  border-radius: ${theme.border.radius.R5};
  border: ${theme.border.radius.R4} ${theme.border.type} ${
    isSelected ? `#27272A` : `white`
  };
    :active {
    border: ${theme.border.radius.R4} ${theme.border.type} #27272A;
  }
`,
);

const ImageContainer = styled.span<ThemeProps>(
  ({ theme }) => `
  height: 112px;
  background: #f4f4f5;
  border-radius: 8px;
  display: block;
  margin: ${theme.spacing.S2};
`,
);

const StyledImage = styled.img`
  height: 112px;
  max-width: 100%;
`;

export const B2BProductTile: FC<B2BProductTileProps> = ({
  product,
  onClick,
  isSelected,
}) => {
  const { formatMessage } = useIntl();
  return (
    <StyledTile onClick={onClick} isSelected={isSelected}>
      <ImageContainer>
        <StyledImage src={product.imageUrl} alt={product.name} />
      </ImageContainer>
      <Box paddingLeft={'S2'} paddingRight={'S2'} paddingBottom={'S2'}>
        <Text bold>{product.name}</Text>
        <BoxReset marginTop={'S2'} marginBottom={'S2'}>
          <Text as="div">
            {formatMessage(b2bProductTileMessages.unitsPerCase, {
              unitsPerCase: product.unitsPerCase,
            })}
          </Text>
        </BoxReset>
        <Text as="div">
          {formatMessage(b2bProductTileMessages.casesPerPallet, {
            casesPerPallet: product.casesPerPallet,
          })}
        </Text>
      </Box>
    </StyledTile>
  );
};
