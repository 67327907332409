import {
  getStoredModelVersion,
  initializeNewModel,
  modelHasBreakingChange,
} from '../../domain/model-version';
import { modelVersion } from '../../provider/model-version';
import { hasPreloadedState } from './has-preloaded-state';

export const clearPreloadedIfBreakingModelChange = () => {
  const storedModelVersion = getStoredModelVersion();
  const currentVersion = modelVersion;

  if (
    hasPreloadedState() &&
    modelHasBreakingChange(storedModelVersion, currentVersion)
  ) {
    initializeNewModel(currentVersion);
  }
};
