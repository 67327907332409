import React from 'react';
import { createSelector } from 'reselect';
import { isProductOversized } from '../../../domain/is-product-oversized';
import {
  normalizeSizeToInches,
  normalizeWeightToLb,
} from '../../../domain/normalize-units';
import { Warning, WarningMessage } from '../../../domain/query-message';
import { OversizedMessage } from '../../query-message/oversized-message';
import { getDimensions } from './get-dimensions';
import { getIsLanguageTypeChinese } from './get-is-language-type-chinese';

export const getQueryWarning = createSelector(
  getDimensions,
  getIsLanguageTypeChinese,
  ({ size, weight }, isLanguageTypeChinese) => {
    if (
      isProductOversized(
        normalizeSizeToInches(size),
        normalizeWeightToLb(weight),
      )
    ) {
      return {
        type: Warning,
        display: (
          <OversizedMessage isLanguageTypeChinese={isLanguageTypeChinese} />
        ),
      } as WarningMessage;
    } else {
      return null;
    }
  },
);
