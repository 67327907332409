import { composeWithDevTools } from 'redux-devtools-extension';
import { applyMiddleware, createStore } from 'redux';
import { actionsMiddleware } from './actions-middleware/actions-middleware';
import { createLogger } from 'redux-logger';
import { save } from 'redux-localstorage-simple';

export const createWithEnhancer = composeWithDevTools(
  applyMiddleware(
    actionsMiddleware(),
    save({ ignoreStates: ['detailQueryInFlightCount'] }),
    createLogger(),
  ),
)(createStore);
