/** @jsx jsx */
import { jsx } from '@emotion/core';
import { DOMAttributes, FC } from 'react';
import css from '@emotion/css/macro';
import tinycolor from 'tinycolor2';
import { WithStyleProps } from '../style/helper';
import { button } from '../style/defaults';
import { blue, grey_500, white } from '../style/colors';

export type ProductEntryButtonProps = {
  disabled: boolean;
  onClick: DOMAttributes<HTMLButtonElement>['onClick'];
} & WithStyleProps;

const darkBlue = tinycolor(blue).darken(10).toHexString();

export const ProductEntryButton: FC<ProductEntryButtonProps> = ({
  children,
  className,
  disabled,
  onClick,
}) => (
  <button
    className={className}
    css={[
      button,
      css`
        width: 150px;
        height: 56px;
        background-color: ${blue};
        border-radius: 4px;
        border-width: 0;
        box-shadow: 0 10px 24px -8px rgba(255, 73, 73, 0.5);
        color: ${white};
        font-size: 16px;

        &:disabled {
          background-color: ${grey_500};
          box-shadow: 0 10px 24px -8px rgba(162, 167, 177, 0.5);
          cursor: default;
        }

        &:focus {
          border: 1px solid ${darkBlue};
          outline: none;
        }
      `,
    ]}
    disabled={disabled}
    onClick={onClick}
  >
    {children}
  </button>
);
