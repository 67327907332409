import { createContext, useContext } from 'react';

export interface ProviderWithHook<T> {
  provider: React.Provider<T | undefined>;
  useHook: () => T;
}

export function createProviderWithHook<T>(
  initialValue?: T | undefined,
): ProviderWithHook<T> {
  const context = createContext<T | undefined>(initialValue);

  return {
    provider: context.Provider,
    useHook: () => {
      const contextInstance = useContext(context);

      if (contextInstance === undefined) {
        throw new Error('context must have a value!');
      } else {
        return contextInstance;
      }
    },
  };
}

export type Provides<T> = T extends ProviderWithHook<infer P> ? P : never;
