/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FC, ReactNode } from 'react';
import css from '@emotion/css/macro';
import { white, grey_500, grey_400 } from '../style/colors';
import { WithStyleProps } from '../style/helper';
import { useMediaTypes } from '../common/media/use-media-types';

export type ProductEntryProps = WithStyleProps & {
  calculate: ReactNode;
};

export const ProductEntry: FC<ProductEntryProps> = ({
  children,
  calculate,
  className,
}) => {
  const { isDesktop } = useMediaTypes();

  return isDesktop ? (
    <div
      className={className}
      css={css`
        display: flex;
        gap: 20px;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-grow: 1;
          height: 54px;
          background-color: ${white};
          border: 1px solid ${grey_400};
          border-radius: 4px;

          > :not(:last-of-type) {
            border-right: 1px solid ${grey_400};
          }
        `}
      >
        {children}
      </div>
      {calculate}
    </div>
  ) : (
    <div
      className={className}
      css={[
        css`
          align-items: center;
          background-color: ${white};
          display: flex;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;

          &::placeholder {
            color: ${grey_500};
            opacity: 1;
          }

          &:focus {
            outline: none;
          }
        `,
      ]}
    >
      {children}
      {calculate}
    </div>
  );
};
