import {
  defaultAmazonEntryForm,
  AmazonEntryForm,
} from '../../../../domain/entry/amazon-entry-form';
import { TabSelected } from '../../../product-entry-type/change-tab';
import { AmazonQuerySucceeded } from '../../../product-entry/amazon-entry/amazon-query-success';
import { ManualQuerySucceeded } from '../../../product-entry/manual-entry/manual-query-success';
import { ProductQuerySucceeded } from '../../../main/product-query-success';

export type AmazonEntry = AmazonEntryForm;

export const initialAmazonEntry: AmazonEntry = defaultAmazonEntryForm;

export type AmazonEntryAction =
  | AmazonQuerySucceeded
  | TabSelected
  | ManualQuerySucceeded
  | ProductQuerySucceeded;

export const amazonEntry = (
  state = initialAmazonEntry,
  action: AmazonEntryAction,
) => {
  switch (action.type) {
    case AmazonQuerySucceeded:
      // resetForm
      return { ...initialAmazonEntry };
    case ManualQuerySucceeded:
    case ProductQuerySucceeded:
    case TabSelected:
      return initialAmazonEntry;
    default:
      return state;
  }
};
