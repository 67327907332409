import {
  INBOUND_FLC_20_FT_HANDLING_FEE,
  INBOUND_FLC_40_FT_HANDLING_FEE,
  INBOUND_PALLET_HANDLING_FEE,
} from '../data/B2B_RATE_CARD';
import { CargoType } from '../types/CargoType';

export const getReserveStorageInboundingCost = (
  cargoType: CargoType,
  numOfPallets: number,
) => {
  switch (cargoType) {
    case CargoType.PALLETIZED:
      return numOfPallets * INBOUND_PALLET_HANDLING_FEE;
    case CargoType.FLOOR_LOADED_CONTAINER_20_FT:
      return INBOUND_FLC_20_FT_HANDLING_FEE;
    case CargoType.FLOOR_LOADED_CONTAINER_40_FT:
      return INBOUND_FLC_40_FT_HANDLING_FEE;
  }
};
