import { StorageProduct } from '../types/StorageProduct';
import kettle from '../assets/img/Kettle.png';
import Bedding from '../assets/img/Bedding.png';
import Shoes from '../assets/img/Shoes.png';
import EspressoMachine from '../assets/img/EspressoMachine.png';
import Mattress from '../assets/img/Mattress.png';
import ProteinPowder from '../assets/img/ProteinPowder.png';
import SportsDrink from '../assets/img/SportsDrink.png';
import Stationery from '../assets/img/Stationery.png';
import Toy from '../assets/img/Toy.png';

export const exampleProducts: StorageProduct[] = [
  {
    id: 1,
    name: 'Shoes',
    unitsPerCase: 12,
    casesPerPallet: 20,
    imageUrl: Shoes,
  },
  {
    id: 2,
    name: 'Whey Protein',
    unitsPerCase: 6,
    casesPerPallet: 30,
    imageUrl: ProteinPowder,
  },
  {
    id: 3,
    name: 'Sports Drink',
    unitsPerCase: 12,
    casesPerPallet: 12,
    imageUrl: SportsDrink,
  },
  {
    id: 4,
    name: 'Mattress',
    unitsPerCase: 1,
    casesPerPallet: 4,
    imageUrl: Mattress,
  },
  {
    id: 5,
    name: 'Bedding',
    unitsPerCase: 4,
    casesPerPallet: 10,
    imageUrl: Bedding,
  },
  {
    id: 6,
    name: 'Stationery',
    unitsPerCase: 40,
    casesPerPallet: 50,
    imageUrl: Stationery,
  },
  {
    id: 7,
    name: 'Kettle',
    unitsPerCase: 12,
    casesPerPallet: 16,
    imageUrl: kettle,
  },
  {
    id: 8,
    name: 'Espresso Machine',
    unitsPerCase: 1,
    casesPerPallet: 45,
    imageUrl: EspressoMachine,
  },
  {
    id: 9,
    name: 'Toy',
    unitsPerCase: 8,
    casesPerPallet: 16,
    imageUrl: Toy,
  },
];
