const sessionKey = 'deliverr-cost-calculator-session';

export const sessionHasExpired = () => {
  const sessionStarted = sessionStorage.getItem(sessionKey);

  if (!sessionStarted) {
    return false;
  } else {
    const sessionStartDate = new Date(sessionStarted);

    sessionStartDate.setMinutes(sessionStartDate.getMinutes() + 30);

    return sessionStartDate < new Date();
  }
};

export const refreshState = () => {
  sessionStorage.setItem(sessionKey, new Date().toString());
};

export const resetSessionIfExpired = () => {
  if (sessionHasExpired()) {
    localStorage.removeItem('redux_localstorage_simple');
  }

  refreshState();
};
