import React, { FC } from 'react';
import { RoundedNumberInput } from '../B2BDisplayCost/RoundedNumberInput';
import { useIntl } from 'react-intl';
import { manualInputMessages } from './manualInputMessages';
import { useB2BCostCalculatorContext } from '../../store/B2BCostCalculatorContext';
import { B2BCostCalculatorActionType } from '../../store/B2BCostCalculatorActionType';
import styled from '@emotion/styled';

const StyledNumberInput = styled(RoundedNumberInput)`
  width: 82%;
`;

export const ManualInputMobile: FC = () => {
  const { formatMessage } = useIntl();
  const {
    b2bCostCalculatorDispatch,
    casesPerPallet,
  } = useB2BCostCalculatorContext();

  const handleCasesPerPalletChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    b2bCostCalculatorDispatch({
      type: B2BCostCalculatorActionType.B2B_COST_CAL_UPDATE_CASES_PER_PALLET,
      payload: { casesPerPallet: Number(e.target.value ?? 0) },
    });
  };

  return (
    <StyledNumberInput
      placeholder={formatMessage(manualInputMessages.casesPerPallet)}
      label={formatMessage(manualInputMessages.casesPerPallet)}
      value={casesPerPallet}
      onChange={handleCasesPerPalletChange}
      min={1}
      type="number"
    />
  );
};
