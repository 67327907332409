/* eslint-disable no-template-curly-in-string */
import { MessageDescriptor, defineMessages } from 'react-intl';
import { B2BCostCalculatorTabType } from '../../types/B2BCostCalculatorTabType';

export const b2bCostCalculatorLayoutMessages: Record<
  B2BCostCalculatorTabType,
  MessageDescriptor
> = defineMessages({
  [B2BCostCalculatorTabType.SAMPLE_PRODUCTS_GRID]: {
    id: 'b2b.rateCalculator.tabs.sampleProductsGrid',
    defaultMessage: 'Choose one sample',
  },
  [B2BCostCalculatorTabType.MANUAL_INPUT]: {
    id: 'b2b.rateCalculator.tabs.manualInput',
    defaultMessage: 'Enter your own',
  },
});
