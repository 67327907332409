import { useGetManualDetail } from '../../domain/get-manual-detail';
import { useDispatch } from 'react-redux';
import { ProductQuerySent } from '../main/product-query-sent';
import { ofType } from '../redux/actions-middleware/of-type';
import { useGetProductSampleById } from '../../domain/get-product-sample-by-id';
import { productQuerySucceeded } from '../main/product-query-success';
import { useActions } from '../redux/actions-middleware/use-actions';
import { productQueryFailed } from '../main/product-query-failed';
import { logError } from '../datadog';
import {
  defaultSizeUnit,
  defaultWeightUnit,
} from '../../domain/entry/manual-entry-form';
import { ServiceDetail } from '../../domain/service-detail';

export const useSendProductQuery = () => {
  const getManualDetail = useGetManualDetail();
  const dispatch = useDispatch();
  const getProductSampleById = useGetProductSampleById();
  const ctx = { fn: 'getSampleProductFulfillmentDetail' };

  useActions<ProductQuerySent>(
    async ({
      input: { surge, hasBattery, country, minimumPackagingType, ...input },
    }) => {
      const { productId, unitsPerOrder, monthlyUnitsStored } = input;
      const fullProduct = getProductSampleById(productId);

      if (fullProduct !== undefined) {
        const [, sample] = fullProduct;

        try {
          const detail = await getManualDetail({
            size: {
              width: sample.width,
              height: sample.height,
              length: sample.length,
              unit: defaultSizeUnit,
            },
            weight: {
              value: sample.weight,
              unit: defaultWeightUnit,
            },
            unitsPerOrder,
            monthlyUnitsStored,
            surge,
            hasBattery,
            country,
            minimumPackagingType,
          });

          const result = {
            ...detail,
            id: sample.id,
            imageUrl: sample.imageUrl,
            title: sample.title,
          } as ServiceDetail;

          dispatch(
            productQuerySucceeded(
              { surge, hasBattery, country, minimumPackagingType, ...input },
              { asin: sample.asin },
              result,
            ),
          );
        } catch (error) {
          dispatch(productQueryFailed(error.message));
          logError(error.message, ctx);
        }
      }
    },
    ofType(ProductQuerySent),
  );
};
