import React from 'react';
import { render } from 'react-dom';

import { Providers } from './provider';
import { View } from './view';
import './index.css';
import { ErrorBoundary } from './error-boundary';
import { host } from './host';
import { loadGrowthAttribution } from './load-growth-attribution';
import { createStore } from './view/redux';
import { loadHeap } from './heap';
import { resetSessionIfExpired } from './view/preload/reset-session-if-expired';
import { clearPreloadedIfBreakingModelChange } from './view/preload/clear-preloaded-if-breaking-model-change';
import { initDataDog } from './view/datadog';

initDataDog();
resetSessionIfExpired();
clearPreloadedIfBreakingModelChange();

if (process.env.NODE_ENV === 'production') {
  loadGrowthAttribution();
  loadHeap(process.env.REACT_APP_HEAP_APP_ID);
}

if (host !== null && host.innerHTML.length === 0) {
  const store = createStore();

  render(
    <ErrorBoundary>
      <Providers>
        <View store={store} />
      </Providers>
    </ErrorBoundary>,
    host,
  );
}

// registerServiceWorker();
