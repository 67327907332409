import { LanguageType } from '../redux/state/language';

export const LanguageTypeChanged = 'LANGUAGE_TYPE_CHANGED';

export type LanguageTypeChanged = ReturnType<typeof languageTypeChanged>;

export const languageTypeChanged = (language: LanguageType) =>
  ({
    type: LanguageTypeChanged,
    language,
  } as const);
