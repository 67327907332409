/* eslint-disable no-template-curly-in-string */
import { MessageDescriptor, defineMessages } from 'react-intl';
import { CargoType } from '../../../types/CargoType';

export const b2bInboundingCostMessages: Record<
  CargoType,
  MessageDescriptor
> = defineMessages({
  [CargoType.PALLETIZED]: {
    id: 'b2b.rateCalculator.inboundingCost.cargoType.palletized',
    defaultMessage: 'Palletized',
  },
  [CargoType.FLOOR_LOADED_CONTAINER_20_FT]: {
    id: 'b2b.rateCalculator.inboundingCost.cargoType.flc20Ft',
    defaultMessage: "20' floor loaded container",
  },
  [CargoType.FLOOR_LOADED_CONTAINER_40_FT]: {
    id: 'b2b.rateCalculator.inboundingCost.cargoType.flc40ft',
    defaultMessage: "40' floor loaded container",
  },
});
