/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';
import { FC } from 'react';
import 'normalize.css';
import { IntlProvider } from 'react-intl';
import { black, original_bgcolor } from '../../view/style/colors';
import { useAll } from '../../view/useAll';
import { text } from '../../view/style/defaults';
import { B2BCostCalculatorLayout } from './components/B2BCostCalculatorLayout/B2BCostCalculatorLayout';
import { B2BCostCalculatorContextProvider } from './store/B2BCostCalculatorContext';
import { B2B_FONT_FAMILY } from './data/constants';

export const B2BApp: FC = () => {
  const { language } = useAll();

  return (
    <IntlProvider locale={language} key={language}>
      <div
        css={[
          css`
            -webkit-font-smoothing: antialiased;
            font-size: 14px;
            font-family: ${B2B_FONT_FAMILY};
            color: ${black};
            background-color: ${original_bgcolor};
          `,
          text,
        ]}
      >
        <main>
          <B2BCostCalculatorContextProvider>
            <B2BCostCalculatorLayout />
          </B2BCostCalculatorContextProvider>
        </main>
      </div>
    </IntlProvider>
  );
};
