import { ReactNode } from 'react';
import { EntryError } from '../view/redux/state/entry/error';
import { GetDetailError } from './get-detail-error';

export const ErrorType = 'error';

export const Warning = 'warning';

export const None = 'none';

export type ErrorMessage = {
  type: typeof ErrorType;
  error: GetDetailError | EntryError;
  display: ReactNode;
};

export type WarningMessage = {
  type: typeof Warning;
  display: ReactNode;
};

export type NoMessage = {
  type: typeof None;
};

export const NoMessage: NoMessage = { type: None };

export type QueryMessage = ErrorMessage | WarningMessage | NoMessage;

export const isErrorMessage = (
  message: QueryMessage,
): message is ErrorMessage => message.type === ErrorType;
