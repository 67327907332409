import { AmazonQueryInput } from './amazon-query-input';
import { VariableQueryInput } from '../../../domain/variable-query-input';
import { Optional } from 'utility-types';
import { defaultUnitsPerOrder } from '../../product-detail/units-per-order';
import { defaultMonthlyUnitsStored } from '../../product-detail/monthly-units-stored';
import { defaultSurge } from '../../product-detail/surge';
import { defaultHasBattery } from '../../product-detail/has-batteries';
import { defaultShippingCountry } from '../../product-detail/shipping-country';
import { defaultMinimumPackagingType } from '../../product-detail/minimum-packaging-type';

export const AmazonQuerySent = 'AMAZON_QUERY_SENT';

export type AmazonQuerySent = ReturnType<typeof amazonQuerySent>;

export const amazonQuerySent = (
  {
    asin,
    unitsPerOrder = defaultUnitsPerOrder,
    monthlyUnitsStored = defaultMonthlyUnitsStored,
    surge = defaultSurge,
    hasBattery = defaultHasBattery,
    country = defaultShippingCountry,
    minimumPackagingType = defaultMinimumPackagingType,
  }: Optional<AmazonQueryInput, keyof VariableQueryInput>,
  update = false,
) =>
  ({
    type: AmazonQuerySent,
    input: {
      asin,
      unitsPerOrder,
      monthlyUnitsStored,
      surge,
      hasBattery,
      country,
      minimumPackagingType,
    },
    meta: {
      update,
    },
  } as const);
