import { Brand } from 'utility-types';
import partial from 'lodash/partial';

export type WebResource = Brand<string, 'web-resource'>;

export const Website = process.env.REACT_APP_WEBSITE_URL as WebResource;

export const ChineseWebsite = process.env
  .REACT_APP_CHINESE_WEBSITE_URL as WebResource;

export const HelpCenter = process.env.REACT_APP_HELP_CENTER_URL as WebResource;

export const Login = process.env.REACT_APP_LOGIN_URL as WebResource;

export const SignUp = process.env.REACT_APP_SIGNUP_URL as WebResource;

export const Info = process.env.REACT_APP_INFO_URL as WebResource;

export const Resources = process.env.REACT_APP_RESOURCES_URL as WebResource;

export const SellerPortal = process.env
  .REACT_APP_SELLER_PORTAL_URL as WebResource;

export const FulfillmentUrl =
  'https://www.flexport.com/products/d2c-fulfillment/';

export const ParcelUrl = 'https://www.flexport.com/products/parcel/';

export const WholesaleUrl =
  'https://www.flexport.com/products/wholesale-fulfillment/';

export const linkToWebResource = (
  webResource: WebResource,
  path: string = '',
) => webResource + path;

export const linkToWebsite = (isLanguageTypeChinese: boolean = false) =>
  partial(linkToWebResource, isLanguageTypeChinese ? ChineseWebsite : Website);
