import { Button, ThemeProps } from '@deliverr/ui';
import styled from '@emotion/styled';

export const StyledSecondaryButton = styled(Button)<ThemeProps>(
  ({ theme }) => `
    background: white;
    border: ${theme.border.width.B1} ${theme.border.type} black;
    border-radius: 100px;
    color: black;
    border-color: black;
    
    :enabled {
      :active, :hover {
        outline: 0;
        box-shadow: ${theme.shadow.S1};
        border-color: ${theme.colors.NEUTRAL['60']};
        background-color: black;
        color: white;
      }
    }

    :hover {
      background-color: black;
      color: white;
    }

    :disabled, &[disabled] {
      color: grey;
      background-color: white;
      border-color: grey;

      > div {
        background-color: white;
        border-radius: 100px;
      }
      > div > svg {
        color: black;
      }  
    }

  `,
);
