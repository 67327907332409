import { NormalizedSize, NormalizedWeight } from './dimensions';

// ordered highest to lowest
export const sizeLimit = [18, 14, 8];

export const weightLimit: NormalizedWeight = { weight: 20 } as NormalizedWeight;

export const isProductOversized = (
  { length, width, height }: NormalizedSize,
  { weight }: NormalizedWeight,
) =>
  [length, width, height]
    .sort((a, b) => b - a)
    .some((dim, i) => dim >= sizeLimit[i]) || weight >= weightLimit.weight;
