import { stringify } from 'qs';

import {
  GetFulfillmentDetail,
  GetFulfillmentDetailError,
} from '../domain/get-fulfillment-detail';
import { ServiceDetail } from '../domain/service-detail';

export const getFulfillmentDetail: GetFulfillmentDetail = async ({
  asin,
  unitsPerOrder,
  monthlyUnitsStored,
  surge,
  hasBattery,
  country,
  minimumPackagingType,
}) => {
  const params = stringify({
    url: asin,
    unitsPerOrder,
    monthlyUnitsStored,
    surge,
    hasBattery,
    countryCode: country,
    minimumPackagingType,
  });
  
  const baseUrl = process.env.REACT_APP_COST_CALCULATOR_API;
  console.log(baseUrl);

  const response = await fetch(
    `${baseUrl}/v8/amazon?${params}`,
  );
  
  if (response.status === 400 || response.status === 404) {
    throw new Error((await response.text()) as GetFulfillmentDetailError);
  }

  return (await response.json()) as ServiceDetail;
};
