import { defineMessages } from 'react-intl';

export const b2bTotalCostMessages = defineMessages({
  totalCost: {
    id: 'b2b.rateCalculator.totalCost.title',
    defaultMessage: 'Total cost',
  },
  includedInPrice: {
    id: 'b2b.rateCalculator.totalCost.includedInPrice',
    defaultMessage: 'Included in this price',
  },
  fastShippingDesc: {
    id: 'b2b.rateCalculator.totalCost.fastShippingDesc',
    defaultMessage: 'Fast shipping within 3 days',
  },
  compliantDesc: {
    id: 'b2b.rateCalculator.totalCost.compliantDesc',
    defaultMessage: 'Error-free, compliant wholesale fulfillment',
  },
  d2cReplenishmentDesc: {
    id: 'b2b.rateCalculator.totalCost.d2cReplenishmentDesc',
    defaultMessage:
      'Free transportation for replenishment to our d2c fulfillment network',
  },
  noMinOrderDesc: {
    id: 'b2b.rateCalculator.totalCost.noMinOrderDesc',
    defaultMessage: 'No minimum order volume required',
  },
  getStartedButton: {
    id: 'b2b.rateCalculator.totalCost.getStartedButton',
    defaultMessage: 'Get started',
  },
  letsChat: {
    id: 'b2b.rateCalculator.totalCost.letsChat',
    defaultMessage: "Need more info? <a>Let's chat</a>",
  },
});
