/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';
import { FC } from 'react';

import ReactSelect, {
  OptionTypeBase,
  Props as ReactSelectProps,
} from 'react-select';
import { grey_600, red, red_50, red_25 } from '../style/colors';
import { WithStyleProps } from '../style/helper';
import { useMediaTypes } from './media/use-media-types';

const baseStyles = {
  menu: (provided) => ({
    ...provided,
    top: '38px',
    marginTop: 0,
  }),
  control: (provided, { isFocused }) => {
    return {
      ...provided,
      '&:hover': grey_600,
      borderColor: isFocused ? grey_600 : provided.borderColor,
      boxShadow: 'none',
    };
  },
  indicatorSeparator: (provided) => ({ display: 'none' }),
  dropdownIndicator: (provided) => {
    return {
      ...provided,
      color: 'inherit',
    };
  },
};

export type SelectProps = {
  colors?: Record<string, string>;
} & ReactSelectProps<OptionTypeBase> &
  WithStyleProps;

export const Select: FC<SelectProps> = ({
  options,
  className,
  colors = {
    primary: red,
    primary25: red_25,
    primary50: red_50,
    primary75: red_50,
  },
  isSearchable = false,
  styles = {},
  ...props
}) => {
  const { isDesktop } = useMediaTypes();

  return (
    <ReactSelect
      {...props}
      options={options}
      className={className}
      css={css`
        & svg {
          color: hsl(0, 0%, 80%);
        }

        &:hover svg {
          color: hsl(0, 0%, 60%);
        }
      `}
      styles={{ ...baseStyles, ...styles }}
      defaultValue={options[0]}
      isSearchable={isSearchable}
      theme={(theme) => ({
        ...theme,
        borderRadius: isDesktop ? 2 : 4,
        colors: {
          ...theme.colors,
          ...colors,
        },
      })}
    />
  );
};
