/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';
import { ThemeProvider } from '@emotion/react';
import { FC, Fragment, useMemo, useState } from 'react';
import { link, iconButton, hoverTransition } from '../style/defaults';
import { indigo, midnight_blue, white } from '../style/colors';
import { WithStyleProps, showWhen } from '../style/helper';
import { Logo } from '../common/logo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NavItem } from '../common/nav/nav-item';
import { navTheme } from './nav-theme';
import { hoverOnOff } from '../style/hover-on-off';
import { useGetUserIsLoggedIn } from '../../domain/get-user-is-logged-in';
import {
  FulfillmentUrl,
  linkToWebResource,
  Login,
  SellerPortal,
  SignUp,
} from '../common/web-resource';
import { desktop, mobile, notDesktop } from '../common/media/media-type';
import { FormattedMessage } from 'react-intl';

export const headerStyle = [
  css`
    padding-top: max(30px, 3vw);
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${desktop} {
      margin-bottom: 42px;
      padding-top: 40px;
    }
  `,
];

export const burgerIconStyle = css`
  top: 3vw;
  right: 3vw;
  position: absolute;
  z-index: 20;
  font-size: 32px;
  color: ${white};

  @media ${mobile} {
    right: 8%;
    top: 22px;
  }

  @media ${desktop} {
    display: none;
  }
`;

export const linksStyle = css`
  background-color: ${midnight_blue};
  width: 200px;
  margin-top: -3vw;
  padding: 12vw 25px 0 25px;
  display: none;
  flex-direction: column;

  @media ${notDesktop} {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }

  @media ${desktop} {
    background-color: inherit;
    width: auto;
    padding-top: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
  }
`;

export type NavProps = WithStyleProps;

export const Nav: FC<NavProps> = ({ className }) => {
  const [expanded, setExpanded] = useState(false);
  const userIsLoggedIn = useMemo(useGetUserIsLoggedIn(), []);

  return (
    <nav className={className} css={headerStyle}>
      <a href={FulfillmentUrl}>
        <Logo />
      </a>
      <button
        onClick={() => setExpanded(!expanded)}
        css={[link, iconButton, burgerIconStyle]}
      >
        {expanded ? (
          <FontAwesomeIcon icon={faTimes} />
        ) : (
          <FontAwesomeIcon icon={faBars} />
        )}
      </button>
      <ThemeProvider theme={navTheme}>
        <div css={[linksStyle, showWhen(expanded, 'flex')]}>
          {userIsLoggedIn ? (
            <NavItem
              css={[
                css`
                  border: 1px solid ${white};

                  &:hover {
                    color: ${indigo};
                    border-color: ${indigo};
                  }

                  @media ${notDesktop} {
                    height: 70px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }

                  @media ${desktop} {
                    margin-left: 20px;
                    border-radius: 2px;
                  }
                `,
                hoverOnOff(hoverTransition('border-color')),
              ]}
              link={linkToWebResource(SellerPortal)}
            >
              <FormattedMessage
                id="banner.nav.myAccount"
                defaultMessage="My Account"
              />
            </NavItem>
          ) : (
            <Fragment>
              <NavItem link={linkToWebResource(Login)}>
                <FormattedMessage
                  id="banner.nav.logIn"
                  defaultMessage="Log In"
                />
              </NavItem>
              <NavItem
                css={[
                  css`
                    border: 1px solid ${white};

                    &:hover {
                      color: ${indigo};
                      border-color: ${indigo};
                    }

                    @media ${notDesktop} {
                      height: 70px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    }

                    @media ${desktop} {
                      margin-left: 20px;
                      border-radius: 2px;
                    }
                  `,
                  hoverOnOff(hoverTransition('border-color')),
                ]}
                link={linkToWebResource(SignUp)}
              >
                <FormattedMessage
                  id="banner.nav.signUp"
                  defaultMessage="Sign Up"
                />
              </NavItem>
            </Fragment>
          )}
        </div>
      </ThemeProvider>
    </nav>
  );
};
