import { B2BCostCalculatorAction } from './B2BCostCalculatorAction';
import { B2BCostCalculatorActionType } from './B2BCostCalculatorActionType';
import { B2BCostCalculatorState } from './B2BCostCalculatorContext';

export const B2BCostCalculatorReducer = (
  state: Partial<B2BCostCalculatorState>,
  action: B2BCostCalculatorAction,
): Partial<B2BCostCalculatorState> => {
  const { type, payload } = action;
  switch (type) {
    case B2BCostCalculatorActionType.B2B_COST_CAL_UPDATE_NUM_OF_PALLETS:
      return {
        ...state,
        numOfPallets: payload.numOfPallets,
        numOfCases: payload.numOfPallets * state.casesPerPallet,
      };
    case B2BCostCalculatorActionType.B2B_COST_CAL_UPDATE_NUM_OF_CASES:
      return {
        ...state,
        numOfCases: payload.numOfCases,
        numOfPallets: Math.ceil(payload.numOfCases / state.casesPerPallet),
      };
    case B2BCostCalculatorActionType.B2B_COST_CAL_UPDATE_STORAGE_PRODUCT:
      return {
        ...state,
        casesPerPallet: payload.casesPerPallet,
        unitsPerCase: payload.unitsPerCase,
        numOfPallets: payload.numOfPallets,
        numOfCases: payload.numOfCases,
      };
    case B2BCostCalculatorActionType.B2B_COST_CAL_UPDATE_CASES_PER_PALLET:
      return {
        ...state,
        casesPerPallet: payload.casesPerPallet,
        numOfCases: payload.casesPerPallet * state.numOfPallets,
      };
    default:
      return state;
  }
};
