import { createSelector } from 'reselect';

import { getActiveQuery } from './get-active-query';
import { defaultSizeUnit } from '../../../domain/entry/manual-entry-form';

export const getSizeUnit = createSelector(
  getActiveQuery,
  (query) =>
    (query?.type === 'manual' && query.values.size.unit) || defaultSizeUnit,
);
