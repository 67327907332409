import { GetDetailError } from '../../domain/get-detail-error';

export const ProductQueryFailed = 'PRODUCT_QUERY_FAILED';
export type ProductQueryFailed = ReturnType<typeof productQueryFailed>;

export const productQueryFailed = (error: GetDetailError) =>
  ({
    type: ProductQueryFailed,
    error,
  } as const);
