import { CountryCode } from '@deliverr/commons-objects';

export const ShippingCountryChanged = 'SHIPPING_COUNTRY_CHANGED';

export type ShippingCountryChanged = ReturnType<typeof shippingCountryChanged>;

export const shippingCountryChanged = (country: CountryCode) =>
  ({
    type: ShippingCountryChanged,
    country,
  } as const);
