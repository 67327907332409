import { useState } from 'react';
import { useMount } from 'react-use';
import { exampleProducts } from '../../data/exampleProductsData';
import { StorageProduct } from '../../types/StorageProduct';
import { useB2BCostCalculatorContext } from '../../store/B2BCostCalculatorContext';
import { B2BCostCalculatorActionType } from '../../store/B2BCostCalculatorActionType';

export const useSampleProductsGrid = () => {
  const [selectedId, setSelectedId] = useState(0);
  const { b2bCostCalculatorDispatch } = useB2BCostCalculatorContext();
  const handleProductSelect = (product: StorageProduct) => () => {
    setSelectedId(product.id);
    b2bCostCalculatorDispatch({
      type: B2BCostCalculatorActionType.B2B_COST_CAL_UPDATE_STORAGE_PRODUCT,
      payload: {
        unitsPerCase: product.unitsPerCase,
        casesPerPallet: product.casesPerPallet,
        numOfPallets: 1,
        numOfCases: product.casesPerPallet,
      },
    });
  };
  useMount(() => {
    handleProductSelect(exampleProducts?.[0])();
  });

  return {
    selectedId,
    handleProductSelect,
  };
};
