import {
  OUTBOUND_CARTON_HANDLING_FEE,
  OUTBOUND_LABEL_FEE,
  OUTBOUND_PALLET_HANDLING_FEE,
} from '../data/B2B_RATE_CARD';
import { OutboundShipmentType } from '../types/OutboundShipmentType';

export const getB2BOutboundingCost = (
  shippingMethod: OutboundShipmentType,
  numOfPallets: number,
  numOfCases: number,
) => {
  const outboundHandlingCost =
    shippingMethod === OutboundShipmentType.FREIGHT_PALLETS
      ? numOfPallets * OUTBOUND_PALLET_HANDLING_FEE
      : numOfCases * OUTBOUND_CARTON_HANDLING_FEE;
  const outboundLabellingCost = numOfCases * OUTBOUND_LABEL_FEE;
  return { outboundHandlingCost, outboundLabellingCost };
};
