/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { linkToWebResource, Website } from '../common/web-resource';
import { link, textLink } from '../style/defaults';

interface OversizedMessageProps {
  isLanguageTypeChinese: boolean;
}

export const OversizedMessage: FC<OversizedMessageProps> = ({
  isLanguageTypeChinese,
}) => (
  <FormattedMessage
    id="errorMessage.oversized"
    defaultMessage={
      '{icon} Item must be prepared ready-to-ship with no additional boxing if the SKU weighs more than 20 lbs or does not fit in an 18"x14"x8" box. Need help packaging? {prep}'
    }
    values={{
      icon: (
        <FontAwesomeIcon
          icon={faExclamationCircle}
          css={css`
            margin-right: 5px;
          `}
        />
      ),
      prep: (
        <a
          css={[link, textLink]}
          href={linkToWebResource(Website, '/products/prep')}
        >
          {isLanguageTypeChinese
            ? 'Flexport包装服务'
            : 'See our prep services.'}
        </a>
      ),
    }}
  />
);
