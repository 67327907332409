import css from '@emotion/css/macro';
import { desktop, notDesktop } from '../common/media/media-type';

import { indigo, white } from '../style/colors';
import { hoverTransition, link } from '../style/defaults';
import { hoverOnOff } from '../style/hover-on-off';
import { fontPrimaryBlack, fontPrimaryBold } from '../style/text';

export const navTheme = {
  nav: {
    item: css`
      @media ${desktop} {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    `,
    subNav: css`
      font-weight: 600;
      border-radius: 8px;
      box-shadow: 0 16px 32px 0 rgba(2, 23, 44, 0.2);
      flex-wrap: wrap;
      display: flex;

      @media ${notDesktop} {
        gap: 10px;
      }

      @media ${desktop} {
        padding: 10px;
        background-color: ${white};
      }
    `,
    subNavWrapper: ({ expanded }: SubNavWrapperProps) => css`
      margin-top: 3em;
      display: ${expanded ? 'initial' : 'none'};

      @media ${notDesktop} {
        width: 255px;
      }

      @media ${desktop} {
        position: absolute;
        z-index: 10;
      }
    `,
    itemLink: [
      link,
      css`
        color: ${white};
        font-weight: ${fontPrimaryBold};
        white-space: nowrap;

        @media ${notDesktop} {
          display: flex;
          height: 50px;
          align-items: center;
        }

        @media ${desktop} {
          display: flex;
          align-items: center;
          font-weight: ${fontPrimaryBlack};
          height: 44px;
        }
      `,
    ],
    itemLinkWrapper: [
      hoverOnOff(hoverTransition('color')),
      css`
        color: ${white};
        display: flex;
        align-items: center;
        justify-content: flex-start;

        & a {
          ${hoverTransition('color')}
        }

        &:hover {
          color: ${indigo};

          a {
            color: ${indigo};
            ${hoverTransition('color')}
          }
        }

        @media ${notDesktop} {
          flex-wrap: wrap;
        }

        @media ${desktop} {
          margin: 0 20px;
        }
      `,
    ],
    itemLinkToggle: css`
      margin-top: 4px;

      @media ${notDesktop} {
        margin-left: 10px;

        &:hover {
          cursor: pointer;
        }
      }

      @media ${desktop} {
        height: 10px;
        margin-left: 5px;
      }
    `,
  },
};

export type SubNavWrapperProps = { expanded: boolean };
export type NavTheme = typeof navTheme;
