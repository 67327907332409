import { useB2BCostCalculatorContext } from '../../../store/B2BCostCalculatorContext';
import { B2BCostCalculatorActionType } from '../../../store/B2BCostCalculatorActionType';

export const useB2BDisplayCostLayout = () => {
  const {
    numOfPallets,
    b2bCostCalculatorDispatch,
  } = useB2BCostCalculatorContext();

  const handleNumberOfPalletsChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const inputValue = Number(e.target.value ?? 0);
    if (inputValue >= 0) {
      b2bCostCalculatorDispatch({
        type: B2BCostCalculatorActionType.B2B_COST_CAL_UPDATE_NUM_OF_PALLETS,
        payload: { numOfPallets: inputValue },
      });
    }
  };

  return {
    numOfPallets,
    handleNumberOfPalletsChange,
  };
};
