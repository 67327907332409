/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FC, useState, useMemo, useRef, useEffect } from 'react';
import css from '@emotion/css/macro';

import { red, grey_600, black } from '../../style/colors';
import { input } from '../../style/defaults';
import { isValidSizeDimension } from '../../../domain/size';
import { WithStyleProps } from '../../style/helper';
import { desktop, notDesktop } from '../../common/media/media-type';

export type ManualEntryDimensionProps = {
  defaultValue?: number;
  onChange: (value: number) => void;
} & WithStyleProps;

export const ManualEntryDimension: FC<ManualEntryDimensionProps> = ({
  children,
  defaultValue = 0,
  onChange,
}) => {
  const [value, setValue] = useState(defaultValue);
  const isValid = useMemo(() => isValidSizeDimension(value), [value]);
  const [focused, setFocused] = useState(false);
  const inputElement = useRef<HTMLInputElement>();

  useEffect(() => {
    if (defaultValue !== value) {
      setValue(defaultValue);
    }

    if (inputElement.current) {
      inputElement.current.value = String(defaultValue);
    }
  }, [defaultValue, value]);

  return (
    <div
      css={[
        css`
          color: ${black};

          @media ${desktop} {
            display: flex;
            align-items: center;
            margin-left: 24px;
          }

          @media ${notDesktop} {
            display: grid;
            grid-template-columns: 34% 66%;
            align-items: center;
            margin-bottom: 16px;
          }
        `,
        !isValid &&
          css`
            color: ${red};
          `,
      ]}
    >
      <span
        css={[
          focused &&
            css`
              color: ${grey_600};
              transition: color 0.1s ease-in;
            `,
        ]}
      >
        {children}
      </span>
      <input
        ref={inputElement}
        type="number"
        css={[
          input,
          css`
            color: ${grey_600};
            width: 32px;
            margin-left: 24px;
            font-size: 16px;
            -moz-appearance: textfield;
            box-sizing: border-box;

            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            &:invalid {
              color: ${red};
            }

            @media ${notDesktop} {
              color: ${black};
              width: auto;
              padding: 9px 12px 9px 12px;
              border-radius: 4px;
              border: solid 1px #d7dce6;
            }
          `,
        ]}
        min={0}
        onBlur={({ currentTarget }) => {
          setFocused(false);
          if (currentTarget.value === '') {
            currentTarget.value = String(defaultValue);
          }
        }}
        step={0.000001}
        onClick={({ currentTarget }) => {
          setFocused(true);
          if (currentTarget.value === String(defaultValue)) {
            currentTarget.select();
          }
        }}
        onChange={({ currentTarget }) => {
          const value = Number(currentTarget.value);
          setValue(value);
          onChange(value);
        }}
      />
    </div>
  );
};
