import React from 'react';
import { useIntl } from 'react-intl';
import { BoxReset, Text } from '@deliverr/ui';
import { CurrencyFormat } from '../../../../common/components/Currency';
import {
  GRADE_B_PALLET_FEE,
  INBOUND_PALLET_HANDLING_FEE,
} from '../../../data/B2B_RATE_CARD';
import { useB2BInboundingCost } from './useB2BInboundingCost';
import { CargoType } from '../../../types/CargoType';
import { B2BCostCard } from '../B2BCostCard';
import { HalfWidthDiv } from '../HalfWidthDiv';
import { RoundedSelect } from '../RoundedSelect';
import { b2bDisplayCostMessages } from '../b2bDisplayCostMessages';
import { BoldCurrency } from '../BoldCurrency';
import { B2BCostStack } from '../B2BCostStack';

export const B2BInboundingCost = () => {
  const { formatMessage } = useIntl();
  const {
    cargoTypeOptions,
    inboundCargoType,
    setInboundCargoType,
    inboundingCost,
  } = useB2BInboundingCost();
  return (
    <B2BCostCard>
      <B2BCostStack direction="HORIZONTAL">
        <HalfWidthDiv>
          <BoxReset marginTop={null}>
            <Text bold size="bodyLarge">
              {formatMessage(b2bDisplayCostMessages.inboundingCostTitle)}
            </Text>
          </BoxReset>
          <BoldCurrency currency={CurrencyFormat.USD} value={inboundingCost} />
          <Text>
            {inboundCargoType === CargoType.PALLETIZED
              ? formatMessage(
                  b2bDisplayCostMessages.inboundPalletizedCostDesc,
                  {
                    inboundPalletHandlingFee: INBOUND_PALLET_HANDLING_FEE,
                  },
                )
              : formatMessage(b2bDisplayCostMessages.inboundContainerCostDesc, {
                  gradeBPalletFee: GRADE_B_PALLET_FEE,
                  br: <br />,
                })}
          </Text>
        </HalfWidthDiv>
        <HalfWidthDiv>
          <RoundedSelect
            label={formatMessage(b2bDisplayCostMessages.cargoType)}
            onChange={(option) => setInboundCargoType(option.value)}
            options={cargoTypeOptions}
            value={cargoTypeOptions.find(
              (option) => option.value === inboundCargoType,
            )}
          />
        </HalfWidthDiv>
      </B2BCostStack>
    </B2BCostCard>
  );
};
