import { GetDetailError } from '../../../domain/get-detail-error';

export const ManualQueryFailed = 'MANUAL_QUERY_FAILED';

export type ManualQueryFailed = ReturnType<typeof manualQueryFailed>;

export const manualQueryFailed = (error: GetDetailError) =>
  ({
    type: ManualQueryFailed,
    error,
  } as const);
