/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl';

export const manualInputMessages = defineMessages({
  casesPerPallet: {
    id: 'b2b.rateCalculator.tabs.manualInput.casesPerPallet',
    defaultMessage: 'Cases per pallet',
  },
  calculateCosts: {
    id: 'b2b.rateCalculator.tabs.manualInput.calculateCostsButton',
    defaultMessage: 'Calculate costs',
  },
});
