import tinycolor from 'tinycolor2';

export const white = '#ffffff';

export const black = '#02172c';

export const original_bgcolor = '#f6f6f9';

export const grey_100 = '#f7f9fb';

export const grey_200 = '#f6f6f9';

export const grey_300 = '#edeff3';

export const grey_400 = '#d7dce6';

export const grey_500 = '#a2a7b1';

export const grey_600 = '#515969';

export const primary = '#ff4949';

export const secondary = '#2d8ced';

export const green = '#00c89a';

export const green_25 = tinycolor(green)
  .lighten(10)
  .desaturate(30)
  .setAlpha(0.3)
  .toRgbString();

export const green_50 = tinycolor(green)
  .lighten(20)
  .desaturate(30)
  .setAlpha(0.3)
  .toRgbString();
export const green_75 = tinycolor(green)
  .lighten(30)
  .desaturate(30)
  .setAlpha(0.3)
  .toRgbString();

export const blue = '#648bff';

export const blue_25 = tinycolor(blue)
  .lighten(10)
  .desaturate(30)
  .setAlpha(0.3)
  .toRgbString();

export const blue_50 = tinycolor(blue)
  .lighten(20)
  .desaturate(30)
  .setAlpha(0.3)
  .toRgbString();

export const blue_75 = tinycolor(blue)
  .lighten(30)
  .desaturate(30)
  .setAlpha(0.3)
  .toRgbString();

export const red = '#ff4949';

export const red_25 = tinycolor(red)
  .lighten(10)
  .desaturate(30)
  .setAlpha(0.3)
  .toRgbString();

export const red_50 = tinycolor(red)
  .lighten(20)
  .desaturate(30)
  .setAlpha(0.3)
  .toRgbString();

export const red_75 = tinycolor(red)
  .lighten(30)
  .desaturate(30)
  .setAlpha(0.3)
  .toRgbString();

// Flexport colors

export const indigo = '#566ae5';

export const indigo_5 = '#4555b7';

export const midnight_blue = '#052439';

export const teal = '#015f74';
