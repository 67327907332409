import { FullProduct } from '../domain/product';

export const PRODUCT_SAMPLES: FullProduct[] = [
  [
    { id: 'productSamples.supplements', defaultMessage: 'Supplements' },
    {
      id: 'swanson-turmeric-curcumin-antioxidant',
      title:
        'Swanson Turmeric Curcumin Antioxidant Joint Health Cardiovascular Liver Detox Mood Memory Support Supplement (Curcuma longa) 720 mg per Cap (1.44 grams per 2 Cap Serving Size) 240 Capsules',
      imageUrl:
        'https://m.media-amazon.com/images/I/618kD6i63aS._AC_SX679_.jpg',
      asin: 'B0017OFR5Q',
      width: 3,
      length: 5.3,
      height: 2.9,
      weight: 0.6,
    },
  ],

  [
    {
      id: 'productSamples.espressoMachine',
      defaultMessage: 'Espresso Machine',
    },
    {
      id: 'delonghi-ec155m-manual-espresso',
      title: 'DeLonghi EC155M Manual Espresso Machine, Cappuccino Maker',
      imageUrl:
        'https://m.media-amazon.com/images/P/B072X2HHQ3._PE30_PI_SCMZZZZZZZ_.jpg',
      asin: 'B072X2HHQ3',
      width: 10,
      length: 14.5,
      height: 10,
      weight: 9.2,
    },
  ],

  [
    { id: 'productSamples.climbingGear', defaultMessage: 'Climbing Gear' },
    {
      id: 'black-diamond-rocklock-screwgate',
      title: 'Black Diamond RockLock Screwgate Carabiner-Dark Gray/Blue',
      imageUrl:
        'https://m.media-amazon.com/images/P/B019FZZ8Y8._PE30_PI_SCMZZZZZZZ_.jpg',
      asin: 'B019FZZ8Y8',
      width: 3.1,
      length: 4.6,
      height: 0.7,
      weight: 0.2,
    },
  ],

  [
    { id: 'productSamples.iphoneCase', defaultMessage: 'iPhone Case' },
    {
      id: 'iphone-7-case-iphone',
      title: 'iPhone 7 Case, iPhone 8 Case, TORRAS Slim',
      imageUrl: 'https://m.media-amazon.com/images/I/31jwW7aXCcL._SL450_.jpg',
      asin: 'B01M7X05SS',
      width: 3.6,
      length: 6.4,
      height: 0.8,
      weight: 0.04,
    },
  ],

  [
    { id: 'productSamples.androidCharger', defaultMessage: 'Android Charger' },
    {
      id: 'micro-usb-cable-android',
      title: 'Micro USB Cable Android Charger',
      imageUrl: 'https://m.media-amazon.com/images/I/41pj3Pji8FL._SL450_.jpg',
      asin: 'B01GFB2E9M',
      width: 3.7,
      length: 7.2,
      height: 0.7,
      weight: 0.31,
    },
  ],
  [
    { id: 'productSamples.beautyKit', defaultMessage: 'Beauty Kit' },
    {
      id: 'br-deluxe-makeup-palette',
      title: 'BR deluxe makeup palette',
      imageUrl: 'https://m.media-amazon.com/images/I/51sWIst0p4L._SL450_.jpg',
      asin: 'B001QKX4I8',
      width: 5.3,
      length: 5.4,
      height: 2,
      weight: 0.8,
    },
  ],
  [
    { id: 'productSamples.babyToys', defaultMessage: 'Baby Toys' },
    {
      id: 'rollerpillar-activity-balls-toy',
      title: 'Rollerpillar Activity Balls Toy',
      imageUrl:
        'https://m.media-amazon.com/images/P/B004G8QSXU._PE30_PI_SCMZZZZZZZ_.jpg',
      asin: 'B004G8QSXU',
      width: 4.6,
      length: 9.6,
      height: 2.4,
      weight: 0.53,
    },
  ],
  [
    { id: 'productSamples.proteinPowder', defaultMessage: 'Protein Powder' },
    {
      id: 'optimum-nutrition-gold-standard',
      title: 'Optimum Nutrition Gold Standard',
      imageUrl:
        'https://m.media-amazon.com/images/P/B000QSNYGI._PE30_PI_SCMZZZZZZZ_.jpg',
      asin: 'B000QSNYGI',
      width: 8,
      length: 11.2,
      height: 7.9,
      weight: 5.6,
    },
  ],

  [
    { id: 'productSamples.petSupplies', defaultMessage: 'Pet Supplies' },
    {
      id: 'muimoi-reflective-collar',
      title: 'MuiMoi Reflective Dog Collar',
      imageUrl:
        'https://m.media-amazon.com/images/I/71Wr8z5heeS._AC_SX679_.jpg',
      asin: 'B093325KC3',
      width: 2.5,
      length: 8.7,
      height: 1,
      weight: 0.2,
    },
  ],

  [
    { id: 'productSamples.officeSupplies', defaultMessage: 'Office Supplies' },
    {
      id: 'dymo-authentic-standard-d1',
      title: 'DYMO Authentic Standard D1 53713 Labeling',
      imageUrl:
        'https://m.media-amazon.com/images/P/B00006B8FK._PE30_PI_SCMZZZZZZZ_.jpg',

      asin: 'B00006B8FK',
      width: 2.5,
      length: 3.5,
      height: 1.5,
      weight: 0.1,
    },
  ],
];
