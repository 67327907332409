import { SerializedStyles } from '@emotion/core';
import css from '@emotion/css/macro';

export const hoverOnOff = (styles: SerializedStyles) => css`
  ${styles}

  &:hover {
    ${styles}
  }
`;
