import { Reducer } from 'redux';
import { LanguageTypeChanged } from '../../footer/language-changed';

export type LanguageType = 'en' | 'zh-CN';

export const defaultLanguageType: LanguageType = 'en';

export type LanguageActions = LanguageTypeChanged;

export const language: Reducer<LanguageType, LanguageActions> = (
  state = defaultLanguageType,
  action,
) => {
  switch (action.type) {
    case LanguageTypeChanged:
      return action.language;
    default:
      return state;
  }
};
