import { ServiceDetail } from './service-detail';
import { createProviderWithHook } from '../helpers/provider-with-hook';
import { Size } from './size';
import { Weight } from './weight';
import { CountryCode, PackagingType } from '@deliverr/commons-objects';

export type GetManualDetail = (
  input: GetManualDetailInput,
) => Promise<ServiceDetail>;

export type GetManualDetailInput = {
  size: Size;
  weight: Weight;
  unitsPerOrder: number;
  monthlyUnitsStored: number;
  surge: boolean;
  hasBattery: boolean;
  country: CountryCode;
  minimumPackagingType: PackagingType;
};

const { provider, useHook } = createProviderWithHook<GetManualDetail>();

export const GetManualDetailProvider = provider;

export const useGetManualDetail = useHook;

export const BadDimsError = 'BAD_DIMS';

export type GetManualDetailError = typeof BadDimsError;
