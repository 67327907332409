import { useState } from 'react';
import { B2BCostCalculatorTabType } from '../../types/B2BCostCalculatorTabType';
import { useIntl } from 'react-intl';
import { b2bCostCalculatorLayoutMessages } from './b2bCostCalculatorLayoutMessages';

export const useB2BCostCalculatorLayout = () => {
  const { formatMessage } = useIntl();
  const [currentTab, setCurrentTab] = useState<B2BCostCalculatorTabType>(
    B2BCostCalculatorTabType.SAMPLE_PRODUCTS_GRID,
  );

  const tabOptions = Object.values(B2BCostCalculatorTabType).map(
    (tabValue) => ({
      label: formatMessage(b2bCostCalculatorLayoutMessages[tabValue]),
      value: tabValue,
    }),
  );

  const handleTabChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setCurrentTab(value as B2BCostCalculatorTabType);
  };

  return {
    tabOptions,
    currentTab,
    handleTabChange,
  };
};
