import { Size, SizeUnit } from '../size';
import { Weight, WeightUnit } from '../weight';

export type ManualEntryForm = {
  size: Size;
  weight: Weight;
};

export const defaultWidth = 0;
export const defaultHeight = 0;
export const defaultLength = 0;
export const defaultWeight = 0;
export const defaultSizeUnit: SizeUnit = 'in';
export const defaultWeightUnit: WeightUnit = 'lb';

export const defaultManualEntryForm: ManualEntryForm = {
  size: {
    width: defaultWidth,
    height: defaultHeight,
    length: defaultLength,
    unit: defaultSizeUnit,
  },
  weight: {
    value: defaultWeight,
    unit: defaultWeightUnit,
  },
};
