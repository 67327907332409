import { useIntl } from 'react-intl';
import { useB2BCostCalculatorContext } from '../../../store/B2BCostCalculatorContext';
import { CargoType } from '../../../types/CargoType';
import { b2bInboundingCostMessages } from './b2bInboundingCostMessages';
import { B2BCostCalculatorActionType } from '../../../store/B2BCostCalculatorActionType';

export const useB2BInboundingCost = () => {
  const {
    inboundCargoType,
    setInboundCargoType,
    numOfCases,
    numOfPallets,
    b2bCostCalculatorDispatch,
    inboundingCost,
  } = useB2BCostCalculatorContext();
  const { formatMessage } = useIntl();

  const cargoTypeOptions = Object.values(CargoType).map((value) => ({
    value,
    label: formatMessage(b2bInboundingCostMessages[value]),
  }));

  const handleNumberOfPalletsChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    b2bCostCalculatorDispatch({
      type: B2BCostCalculatorActionType.B2B_COST_CAL_UPDATE_NUM_OF_PALLETS,
      payload: { numOfPallets: Number(e.target.value ?? 0) },
    });
  };

  const handleNumberOfCasesChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    b2bCostCalculatorDispatch({
      type: B2BCostCalculatorActionType.B2B_COST_CAL_UPDATE_NUM_OF_CASES,
      payload: { numOfCases: Number(e.target.value ?? 0) },
    });
  };

  return {
    cargoTypeOptions,
    inboundCargoType,
    numOfCases,
    numOfPallets,
    setInboundCargoType,
    handleNumberOfPalletsChange,
    handleNumberOfCasesChange,
    inboundingCost,
  };
};
