import { defineMessages } from 'react-intl';

export const productDetailMessages = defineMessages({
  fulfillmentCost: {
    id: 'productDetail.costDetail.fulfillmentCost',
    defaultMessage: 'Fulfillment Cost',
  },
  peakSurchargesToggle: {
    id: 'productDetail.costDetail.peakSurchargesToggle',
    defaultMessage: '2022 Peak Surcharges',
  },
  country: {
    id: 'productDetail.settings.country',
    defaultMessage: 'Shipping to',
  },
  unitsPerOrder: {
    id: 'productDetail.costDetail.unitsPerOrder',
    defaultMessage: 'Units per order',
  },
  prices: {
    id: 'productDetail.costDetail.prices',
    defaultMessage: 'Pricing is inclusive of all {fastTags}',
  },
  pricesFastTags: {
    id: 'productDetail.costDetail.pricesFastTags',
    defaultMessage: 'Fast Tags',
  },
  peakSurcharges: {
    id: 'productDetail.costDetail.peakSurcharges',
    defaultMessage:
      'Between 10/21 and 01/15, fulfillment will be charged a peak surcharge. {learnMore}.',
  },
  peakSurchargesLearnMore: {
    id: 'productDetail.costDetail.peakSurchargesLearnMore',
    defaultMessage: 'Learn More',
  },
  rates: {
    id: 'productDetail.costDetail.rates',
    defaultMessage: 'Doing 1000+ orders a day? {rates}',
  },
  storageTitle: {
    id: 'productDetail.storage.title',
    defaultMessage: 'Storage  Cost (monthly)',
  },
  monthlyUnits: {
    id: 'productDetail.storage.monthlyUnits',
    defaultMessage: 'Average monthly units {br} stored at Flexport',
  },
  totalCharge: {
    id: 'productDetail.storage.totalCharge',
    defaultMessage:
      'Cost shown is the total charge for storing {monthlyUnitsStored} units of this SKU in Flexport.',
  },
  longTermStorage: {
    id: 'productDetail.storage.longTermStorage',
    defaultMessage:
      'Long term storage rates apply for items sitting longer than 1 year.',
  },
  currentMonth: {
    id: 'productDetail.storage.currentMonth',
    defaultMessage: 'Rates shown are for the current month.',
  },
  peakPeriod: {
    id: 'productDetail.storage.peakPeriod',
    defaultMessage:
      'Rates differ between January - September and October - December.',
  },
  relatedProducts: {
    id: 'productDetail.productDetail.relatedProducts',
    defaultMessage: 'Related Products',
  },
  leadCapture: {
    id: 'productDetail.productDetail.leadCapture',
    defaultMessage: 'Transparent, predictable, and easy.',
  },
  leadCaptureSubText: {
    id: 'productDetail.productDetail.leadCapture.subText',
    defaultMessage: 'Get expert, responsive support every step of the way.',
  },
  leadCaptureContinue: {
    id: 'productDetail.productDetail.leadCapture.continue',
    defaultMessage: 'Talk to an expert',
  },
  dimensions: {
    id: 'productDetail.productDetail.productView.dimensions',
    defaultMessage: 'Dimensions:',
  },
  itemWeight: {
    id: 'productDetail.productDetail.productView.itemWeight',
    defaultMessage: 'Item weight:',
  },
  in: {
    id: 'productDetail.productDetail.productView.in',
    defaultMessage: 'in',
  },
  cm: {
    id: 'productDetail.productDetail.productView.cm',
    defaultMessage: 'cm',
  },
  lb: {
    id: 'productDetail.productDetail.productView.lb',
    defaultMessage: 'lb',
  },
  kg: {
    id: 'productDetail.productDetail.productView.kg',
    defaultMessage: 'kg',
  },
  salesChannelMore: {
    id: 'productDetail.productDetail.salesChannel.more',
    defaultMessage: '+ more',
  },
  salesChannelShopifyIcon: {
    id: 'productDetail.productDetail.salesChannel.shopifyIcon',
    defaultMessage: 'Shopify Icon',
  },
  salesChannelGoogleIcon: {
    id: 'productDetail.productDetail.salesChannel.googleIcon',
    defaultMessage: 'Google Icon',
  },
  salesChannelEbayIcon: {
    id: 'productDetail.productDetail.salesChannel.ebayIcon',
    defaultMessage: 'Ebay Icon',
  },
  salesChannelWalmartIcon: {
    id: 'productDetail.productDetail.salesChannel.walmartIcon',
    defaultMessage: 'Walmart Icon',
  },
  salesChannelFacebookIcon: {
    id: 'productDetail.productDetail.salesChannel.facebookIcon',
    defaultMessage: 'Facebook Icon',
  },

});
