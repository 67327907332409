import { Input, ThemeProps } from '@deliverr/ui';
import styled from '@emotion/styled';
import { B2B_FONT_FAMILY } from '../../data/constants';

export const RoundedNumberInput = styled(Input)<ThemeProps>(
  ({ theme }) => `
    height: 2.5rem;
    padding-left: ${theme.spacing.S4};
    -moz-appearance: textfield;
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 28px;
    border-color: ${theme.border.width.B1} ${theme.border.type} #D4D4D8;
    font-family: ${B2B_FONT_FAMILY};
  `,
);
