import { ActiveEntryType, activeEntryType } from './active-entry-type';
import { combineReducers } from 'redux';
import { ProductSamples, productSamples } from './product-samples';
import { ServiceDetail, serviceDetail } from './service-detail';
import {
  DetailQueryInFlightCount,
  detailQueryInFlightCount,
} from './detail-query-in-flight-count';
import { Query, query } from './query/query';
import { Entry, entry } from './entry/entry';
import { LanguageType, language } from './language';

export type Root = {
  activeEntryType: ActiveEntryType;
  productSamples: ProductSamples;
  serviceDetail: ServiceDetail;
  detailQueryInFlightCount: DetailQueryInFlightCount;
  query: Query;
  entry: Entry;
  language: LanguageType;
};

export const root = combineReducers({
  activeEntryType,
  detailQueryInFlightCount,
  productSamples,
  serviceDetail,
  query,
  entry,
  language,
});
