/* eslint-disable no-template-curly-in-string */
import { defineMessages } from 'react-intl';

export const b2bDisplayCostMessages = defineMessages({
  inboundingCostTitle: {
    id: 'b2b.rateCalculator.inboundingCost.title',
    defaultMessage: 'Inbounding cost',
  },
  outboundingCostTitle: {
    id: 'b2b.rateCalculator.outboundingCost.title',
    defaultMessage: 'Outbounding cost',
  },
  storageCostTitle: {
    id: 'b2b.rateCalculator.storageCost.title',
    defaultMessage: 'Storage cost',
  },
  inboundPalletizedCostDesc: {
    id: 'b2b.rateCalculator.inboundingCost.palletized',
    defaultMessage: 'Handling (${inboundPalletHandlingFee} per pallet)',
  },
  inboundContainerCostDesc: {
    id: 'b2b.rateCalculator.inboundingCost.palletized',
    defaultMessage:
      'Per container{br}Plus ${gradeBPalletFee} per supplied pallet',
  },
  cargoType: {
    id: 'b2b.rateCalculator.inboundingCost.cargoType',
    defaultMessage: 'Cargo type',
  },
  pallets: {
    id: 'b2b.rateCalculator.inboundingCost.pallets',
    defaultMessage: 'Pallets',
  },
  cases: {
    id: 'b2b.rateCalculator.inboundingCost.cases',
    defaultMessage: 'Cases',
  },
  monthsOfStorage: {
    id: 'b2b.rateCalculator.storageCost.monthsOfStorage',
    defaultMessage: 'Months of storage',
  },
  firstMonthStorageCostDesc: {
    id: 'b2b.rateCalculator.storageCost.firstMonth',
    defaultMessage: '${reserveFirstMonthStorageFee} per pallet for first month',
  },
  longTermStorageCostDesc: {
    id: 'b2b.rateCalculator.storageCost.firstMonth',
    defaultMessage: '${reserveLongTermStorageFee} per pallet for 12+ months',
  },
  recurringStorageCostDesc: {
    id: 'b2b.rateCalculator.storageCost.firstMonth',
    defaultMessage: '${reserveRecurringStorageFee} per pallet for 2-12 months',
  },
  outboundPalletHandlingCostDesc: {
    id: 'b2b.rateCalculator.outboundingCost.palletHandling',
    defaultMessage: 'Handling (${outboundPalletHandlingFee} per pallet)',
  },
  outboundCaseHandlingCostDesc: {
    id: 'b2b.rateCalculator.inboundingCost.caseHandling',
    defaultMessage: 'Handling ({outboundCaseHandlingFee} per case)',
  },
  outboundLabelCostDesc: {
    id: 'b2b.rateCalculator.inboundingCost.labelCost',
    defaultMessage: 'Labels ({outboundLabelFee} per label)',
  },
  shippingMethod: {
    id: 'b2b.rateCalculator.outboundingCost.shippingMethod',
    defaultMessage: 'Shipping method',
  },
  d2cPricingFootNote: {
    id: 'b2b.rateCalculator.d2cPricingFootNote',
    defaultMessage:
      'Looking for pricing for our d2c fulfillment services? <a>Learn more</a>',
  },
});
