import { stringify } from 'qs';

import { ServiceDetail } from '../domain/service-detail';
import {
  GetManualDetail,
  GetManualDetailError,
} from '../domain/get-manual-detail';
import {
  normalizeSizeToInches,
  normalizeWeightToLb,
} from '../domain/normalize-units';
import {
  defaultSizeUnit,
  defaultWeightUnit,
} from '../domain/entry/manual-entry-form';

type EndpointParams = {
  width: number;
  length: number;
  height: number;
  weight: number;
  unitsPerOrder: number;
  monthlyUnitsStored: number;
};

export const getManualDetail: GetManualDetail = async ({
  size,
  weight,
  unitsPerOrder,
  monthlyUnitsStored,
  surge,
  hasBattery,
  country,
  minimumPackagingType,
}) => {
  const { width, length, height } = normalizeSizeToInches(size);
  const { weight: weightValue } = normalizeWeightToLb(weight);

  const params: EndpointParams = stringify({
    width,
    length,
    height,
    weight: weightValue,
    unitsPerOrder,
    monthlyUnitsStored,
    surge,
    hasBattery,
    countryCode: country,
    minimumPackagingType,
  });

  const response = await fetch(
    `${process.env.REACT_APP_COST_CALCULATOR_API}/v8/manual?${params}`,
  );

  if (response.status === 400) {
    throw new Error((await response.text()) as GetManualDetailError);
  }

  const detail = (await response.json()) as ServiceDetail;

  if (size.unit !== defaultSizeUnit) {
    detail.width = size.width;
    detail.height = size.height;
    detail.length = size.length;
  }

  if (weight.unit !== defaultWeightUnit) {
    detail.weight = weight.value;
  }
  return detail;
};
