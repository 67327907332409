/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FC } from 'react';
import css from '@emotion/css/macro';
import { useDispatch } from 'react-redux';

import { black, grey_600, grey_400, white, grey_300 } from '../style/colors';
import { button } from '../style/defaults';
import { WithStyleProps, unstyledButton } from '../style/helper';
import { Tab } from './tab';
import { tabSelected } from './change-tab';
import { desktop } from '../common/media/media-type';

export type ProductEntryTypeTabProps = {
  active: boolean;
  tab: Tab;
} & WithStyleProps;

export const ProductEntryTypeTab: FC<ProductEntryTypeTabProps> = ({
  active,
  children,
  className,
  tab,
}) => {
  const dispatch = useDispatch();
  return (
    <div
      css={[
        css`
          height: 35px;
          display: flex;
          flex-grow: 1;
          justify-content: stretch;
          align-items: stretch;

          @media ${desktop} {
            max-width: 155px;
          }
        `,
        active
          ? css`
              background-color: ${white};
            `
          : css`
              background-color: ${grey_400};
            `,
      ]}
      className={className}
    >
      <button
        onClick={({ currentTarget }) => {
          currentTarget.blur();
          dispatch(tabSelected(tab));
        }}
        css={[
          unstyledButton,
          button,
          css`
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            text-align: center;
            text-transform: uppercase;
            font-size: 12px;
            letter-spacing: 1px;
            flex-grow: 1;

            &:focus {
              outline: none;
              background-color: ${grey_300};
            }
          `,
          active
            ? css`
                color: ${black};
              `
            : css`
                color: ${grey_600};
              `,
        ]}
      >
        {children}
      </button>
    </div>
  );
};
