import { FullProduct } from '../../domain/product';

export const ProductSamplesReceived = 'PRODUCT_SAMPLES_RECEIVED';

export type ProductSamplesReceived = ReturnType<typeof productSamplesReceived>;

export const productSamplesReceived = (samples: FullProduct[]) =>
  ({
    type: ProductSamplesReceived,
    samples,
  } as const);
