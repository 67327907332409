import { createSelector } from 'reselect';
import {
  ErrorType,
  ErrorMessage,
  NoMessage,
  QueryMessage,
} from '../../../domain/query-message';
import { getDetailErrorMessage } from '../../query-message/get-detail-error-message';
import { getEntryErrorMessage } from '../../query-message/get-entry-error-message';
import { getEntryError } from './get-entry-error';
import { getQueryError } from './get-query-error';
import { getQueryWarning } from './get-query-warning';

export const getQueryMessage = createSelector(
  getEntryError,
  getQueryError,
  getQueryWarning,
  (entryError, queryError, queryWarning): QueryMessage => {
    if (entryError !== null) {
      return {
        type: ErrorType,
        error: entryError,
        display: getEntryErrorMessage(entryError),
      } as ErrorMessage;
    }

    if (queryError !== null) {
      return {
        type: ErrorType,
        error: queryError,
        display: getDetailErrorMessage(queryError),
      } as ErrorMessage;
    }

    if (queryWarning !== null) {
      return queryWarning;
    }

    return NoMessage;
  },
);
