import { createSelector } from 'reselect';
import { getServiceDetail } from './get-service-detail';
import { getSizeUnit } from './get-size-unit';
import { getWeightUnit } from './get-weight-unit';
import { getActiveQuery } from './get-active-query';
import {
  ManualEntryForm,
  defaultManualEntryForm,
} from '../../../domain/entry/manual-entry-form';
import { initialServiceDetail } from '../state/service-detail';

export const getDimensions = createSelector(
  getActiveQuery,
  getServiceDetail,
  getSizeUnit,
  getWeightUnit,
  (activeQuery, detail, sizeUnit, weightUnit): ManualEntryForm => {
    if (activeQuery?.type === 'manual') {
      return {
        size: activeQuery.values.size,
        weight: activeQuery.values.weight,
      };
    } else if (detail !== initialServiceDetail) {
      return {
        size: {
          length: detail.length,
          width: detail.width,
          height: detail.height,
          unit: sizeUnit,
        },
        weight: {
          value: detail.weight,
          unit: weightUnit,
        },
      };
    } else {
      return defaultManualEntryForm;
    }
  },
);
