/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { OptionTypeBase } from 'react-select';
import { EntryType, Amazon, Manual } from '../../domain/entry-type';
import { FormattedMessage } from 'react-intl';
import { tabSelected } from './change-tab';
import { ProductEntrySelect } from '../product-entry/product-entry-select';
import { productEntryTypesMessages } from './product-entry-types-messages';

export type ProductEntryTypesSelectProps = {
  activeEntryType: EntryType;
};

const options = [
  {
    value: Amazon,
    label: <FormattedMessage {...productEntryTypesMessages.amazonEntry} />,
  },
  {
    value: Manual,
    label: <FormattedMessage {...productEntryTypesMessages.manualEntry} />,
  },
];

export const ProductEntryTypesSelect: FC<ProductEntryTypesSelectProps> = ({
  activeEntryType,
}) => {
  const dispatch = useDispatch();

  return (
    <ProductEntrySelect
      width="185px"
      position="start"
      options={options}
      onChange={({ value }: OptionTypeBase) => {
        dispatch(tabSelected(value));
      }}
      value={options.find(({ value }) => value === activeEntryType)}
    />
  );
};
