/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';
import { FC, useState, useEffect } from 'react';

import { ProductSampleTile } from '../../common/product-sample-tile';
import { black, midnight_blue, white } from '../../style/colors';
import {
  fontPrimaryBold,
  fontSecondary,
  fontSecondaryBold,
} from '../../style/text';
import { link, text } from '../../style/defaults';
import { ServiceDetail } from '../../../domain/service-detail';
import { useGetProductSamples } from '../../../domain/get-product-samples';
import { useSelector } from 'react-redux';
import { arePricesUpdating } from '../../redux/selectors/are-prices-updating';
import { getIsLanguageTypeChinese } from '../../redux/selectors/get-is-language-type-chinese';
import { getSizeUnit } from '../../redux/selectors/get-size-unit';
import { getWeightUnit } from '../../redux/selectors/get-weight-unit';
import { isDetailQueryInFlight } from '../../redux/selectors/is-detail-query-in-flight';
import { hideWhen } from '../../style/helper';
import { createSelector } from 'reselect';
import { linkToWebResource, Resources } from '../../common/web-resource';
import { QueryOverlay } from './query-overlay';
import {
  desktop,
  mobile,
  notDesktop,
  notMobile,
} from '../../common/media/media-type';
import { ProductView } from './product-view';
import { CostDetail } from './cost-detail';
import { useIntl } from 'react-intl';
import { productDetailMessages } from './product-detail-messages';

export type ProductDetailProps = {
  productId?: string;
  detail: ServiceDetail;
};

export const ProductDetail: FC<ProductDetailProps> = ({
  detail,
  productId = '',
}) => {
  const showOverlay = useSelector(
    createSelector(
      arePricesUpdating,
      isDetailQueryInFlight,
      (updating, inFlight) => !updating && inFlight,
    ),
  );
  const getProductSamples = useGetProductSamples();
  const [productSamples, setProductSamples] = useState(
    getProductSamples({ exclude: productId, count: 9 }),
  );
  const sizeUnit = useSelector(getSizeUnit);
  const weightUnit = useSelector(getWeightUnit);

  useEffect(() => {
    setProductSamples(getProductSamples({ exclude: productId, count: 9 }));
  }, [productId, detail.id, getProductSamples]);

  const isLanguageTypeChinese = useSelector(getIsLanguageTypeChinese);
  const { formatMessage } = useIntl();

  return (
    <div
      css={css`
        display: grid;
        position: relative;

        @media ${desktop} {
          grid-template-areas:
            'product-view cost-detail'
            'lead-capture-cta cost-detail'
            'related-products cost-detail'
            '. cost-detail';
          grid-column-gap: 40px;
          grid-template-columns: 600px 400px;
          grid-row-gap: 20px;
        }

        @media ${notDesktop} {
          grid-template-areas:
            'product-view'
            'cost-detail'
            'lead-capture-cta'
            'related-products';
          grid-row-gap: 40px;
        }
      `}
    >
      <QueryOverlay css={hideWhen(!showOverlay)} />

      <ProductView
        detail={detail}
        sizeUnit={sizeUnit}
        weightUnit={weightUnit}
        formatMessage={formatMessage}
        css={css`
          grid-area: product-view;
        `}
      />

      <section
        css={css`
          grid-area: lead-capture-cta;
          background-color: ${midnight_blue};
          border-radius: 4px;
          color: ${white};
          line-height: 24px;
          margin-top: 20px;
          padding: 30px;

          @media ${notMobile} {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        `}
      >
        <div>
          <header
            css={[
              text,
              css`
                ${fontSecondary}
                font-size: 16px;
                font-weight: ${fontSecondaryBold};

                @media ${mobile} {
                  font-size: 20px;
                  text-align: center;
                }
              `,
            ]}
          >
            {formatMessage(productDetailMessages.leadCapture)}
          </header>
          <p
            css={css`
              margin: 0 10px 0 0;

              @media ${mobile} {
                display: none;
              }
            `}
          >
            {formatMessage(productDetailMessages.leadCaptureSubText)}
          </p>
        </div>
        <div>
          <a
            css={[
              link,
              css`
                background-color: ${white};
                border-radius: 4px;
                color: ${black};
                display: block;
                font-weight: ${fontPrimaryBold};
                padding: 10px 16px;
                text-align: center;
                border: 1px solid ${white};

                &:hover {
                  background-color: transparent;
                  color: ${white};
                }

                @media ${mobile} {
                  display: block;
                  margin-top: 20px;
                }
              `,
            ]}
            href={linkToWebResource(
              Resources,
              isLanguageTypeChinese
                ? '/zh/call-0'
                : '/scheduleacall?unbundled_services_interest=Fulfillment',
            )}
          >
            {formatMessage(productDetailMessages.leadCaptureContinue)}
          </a>
        </div>
      </section>

      <section
        css={css`
          grid-area: related-products;
          margin-top: 30px;

          @media ${notDesktop} {
            overflow: auto;
            margin-right: -9%;
          }
        `}
      >
        <header
          css={css`
            ${fontSecondary}
            font-size: 18px;
            font-weight: ${fontSecondaryBold};
          `}
        >
          {formatMessage(productDetailMessages.relatedProducts)}
        </header>
        <div
          css={css`
            display: grid;
            grid-gap: 20px 24px;
            grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));

            @media ${notDesktop} {
              display: flex;
              gap: 28px;
              overflow: auto;
            }
          `}
        >
          {productSamples.map((fullProduct) => {
            const [, product] = fullProduct;

            return (
              <ProductSampleTile
                css={css`
                  margin-top: 20px;
                `}
                key={product.id}
                product={fullProduct}
              />
            );
          })}
        </div>
      </section>

      <CostDetail
        css={css`
          grid-area: cost-detail;
        `}
      />
    </div>
  );
};
