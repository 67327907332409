import { useMediaQueries } from '@react-hook/media-query';
import { desktop, mobile } from './media-type';

export const useMediaTypes = () => {
  const { matchesAll: isDesktop } = useMediaQueries({
    screen: 'screen',
    width: desktop,
  });
  const { matchesAll: isMobile } = useMediaQueries({
    screen: 'screen',
    width: mobile,
  });

  return {
    isDesktop,
    isMobile,
  };
};
