import { defineMessages } from 'react-intl';

export const b2bSectionHeaderMessages = defineMessages({
  title: {
    id: 'b2b.rateCalculator.title',
    defaultMessage:
      'Calculate your replenishment and wholesale fulfillment costs',
  },
  subtitle: {
    id: 'b2b.rateCalculator.subtitle',
    defaultMessage:
      'Estimate your inbound, storage, and outbound costs with our calculator. No hidden fees.',
  },
});
