import { useDispatch } from 'react-redux';

import { ofType } from '../redux/actions-middleware/of-type';
import { useActions } from '../redux/actions-middleware/use-actions';
import { useGetManualDetail } from '../../domain/get-manual-detail';
import { manualQuerySucceeded } from '../product-entry/manual-entry/manual-query-success';
import { ManualQuerySent } from '../product-entry/manual-entry/manual-query-sent';
import { manualQueryFailed } from '../product-entry/manual-entry/manual-query-failed';
import { GetDetailError } from '../../domain/get-detail-error';
import manualProductImage from './manual-product.png';
import { logError } from '../datadog';

export const useSendManualQuery = () => {
  const getManualDetail = useGetManualDetail();
  const dispatch = useDispatch();
  const ctx = { fn: 'getManualDetail' };

  useActions<ManualQuerySent>(
    async ({ input: { surge, hasBattery, ...input } }) => {
      try {
        const result = await getManualDetail({
          ...input,
          surge,
          hasBattery,
        });

        result.title = 'Manually Entered Product';
        result.imageUrl = manualProductImage;

        dispatch(manualQuerySucceeded({ ...input, hasBattery, surge }, result));
      } catch (error) {
        dispatch(manualQueryFailed(error.message as GetDetailError));
        logError(error.message, ctx);
      }
    },
    ofType(ManualQuerySent),
  );
};
