import React, {
  createContext,
  FC,
  useContext,
  useReducer,
  useState,
} from 'react';
import { CargoType } from '../types/CargoType';
import { OutboundShipmentType } from '../types/OutboundShipmentType';
import { noop } from 'lodash';
import { getReserveStorageInboundingCost } from '../utils/getReserveStorageInboundingCost';
import { getB2BStorageCost } from '../utils/getB2BStorageCost';
import { getB2BOutboundingCost } from '../utils/getB2BOutboundingCost';
import { B2BCostCalculatorReducer } from './B2BCostCalculatorReducer';
import { B2BCostCalculatorAction } from './B2BCostCalculatorAction';

export interface B2BCostCalculatorState {
  unitsPerCase: number;
  casesPerPallet: number;
  inboundCargoType: CargoType;
  numOfCases: number;
  numOfPallets: number;
  monthsOfStorage: number;
  outboundShipmentType: OutboundShipmentType;
}

export interface B2BCostCalculatorContextProps extends B2BCostCalculatorState {
  setMonthsOfStorage: (monthsOfStorage: number) => void;
  setInboundCargoType: (cargoType: CargoType) => void;
  setOutboundShipmentType: (outboundShipmentType: OutboundShipmentType) => void;
  b2bCostCalculatorDispatch: React.Dispatch<B2BCostCalculatorAction>;
  inboundingCost: number;
  outboundHandlingCost: number;
  outboundLabellingCost: number;
  storageCost: number;
  totalCost: number;
}

export const B2BCostCalculatorContext = createContext<
  B2BCostCalculatorContextProps
>({
  unitsPerCase: 0,
  casesPerPallet: 0,
  inboundCargoType: CargoType.PALLETIZED,
  numOfCases: 1,
  numOfPallets: 1,
  monthsOfStorage: 1,
  outboundShipmentType: OutboundShipmentType.FREIGHT_PALLETS,
  b2bCostCalculatorDispatch: noop,
  setMonthsOfStorage: noop,
  setInboundCargoType: noop,
  setOutboundShipmentType: noop,
  inboundingCost: 0,
  outboundHandlingCost: 0,
  storageCost: 0,
  totalCost: 0,
  outboundLabellingCost: 0,
});

export const B2BCostCalculatorContextProvider: FC = ({ children }) => {
  const [
    { casesPerPallet, numOfCases, numOfPallets, unitsPerCase },
    b2bCostCalculatorDispatch,
  ] = useReducer(B2BCostCalculatorReducer, {
    unitsPerCase: 1,
    casesPerPallet: 1,
    numOfCases: 1,
    numOfPallets: 1,
  });
  const [monthsOfStorage, setMonthsOfStorage] = useState(1);
  const [inboundCargoType, setInboundCargoType] = useState<CargoType>(
    CargoType.PALLETIZED,
  );
  const [outboundShipmentType, setOutboundShipmentType] = useState<
    OutboundShipmentType
  >(OutboundShipmentType.FREIGHT_PALLETS);
  const inboundingCost = getReserveStorageInboundingCost(
    inboundCargoType,
    numOfPallets,
  );
  const storageCost = getB2BStorageCost(monthsOfStorage, numOfPallets);
  const { outboundHandlingCost, outboundLabellingCost } = getB2BOutboundingCost(
    outboundShipmentType,
    numOfPallets,
    numOfCases,
  );
  const totalCost =
    inboundingCost + outboundHandlingCost + outboundLabellingCost + storageCost;
  const value = {
    unitsPerCase,
    casesPerPallet,
    numOfCases,
    numOfPallets,
    monthsOfStorage,
    b2bCostCalculatorDispatch,
    setMonthsOfStorage,
    outboundShipmentType,
    setOutboundShipmentType,
    inboundCargoType,
    setInboundCargoType,
    inboundingCost,
    outboundHandlingCost,
    storageCost,
    totalCost,
    outboundLabellingCost,
  };

  return (
    <B2BCostCalculatorContext.Provider value={value}>
      {children}
    </B2BCostCalculatorContext.Provider>
  );
};

export const useB2BCostCalculatorContext = () =>
  useContext(B2BCostCalculatorContext);
