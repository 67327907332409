import { useState } from 'react';
import { useB2BCostCalculatorContext } from '../../store/B2BCostCalculatorContext';
import { B2BCostCalculatorActionType } from '../../store/B2BCostCalculatorActionType';

export const useManualInput = () => {
  const { b2bCostCalculatorDispatch } = useB2BCostCalculatorContext();
  const [casesPerPallet, setCasesPerPallet] = useState<number>();

  const handleCasesPerPalletChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const inputValue = Number(e.target.value ?? 0);
    if (inputValue >= 0) {
      setCasesPerPallet(inputValue);
    }
  };

  const onCalculateCosts = () => {
    b2bCostCalculatorDispatch({
      type: B2BCostCalculatorActionType.B2B_COST_CAL_UPDATE_CASES_PER_PALLET,
      payload: { casesPerPallet },
    });
  };
  const isButtonDisabled = !casesPerPallet;

  return {
    casesPerPallet,
    onCalculateCosts,
    isButtonDisabled,
    handleCasesPerPalletChange,
  };
};
