import { ProductQueryInput } from '../../../main/product-query-input';
import {
  ProductQuerySucceeded,
  ProductQueryCache,
} from '../../../main/product-query-success';
import { AmazonQueryInput } from '../../../product-entry/amazon-entry/amazon-query-input';
import { AmazonQuerySucceeded } from '../../../product-entry/amazon-entry/amazon-query-success';
import { ManualQuerySucceeded } from '../../../product-entry/manual-entry/manual-query-success';
import { ManualQueryInput } from '../../../product-entry/manual-entry/manual-query-input';

import { VariableInputUpdateSucceeded } from '../../../effects/variable-input-update-succeeded';
import { asVariableQueryInput } from '../../../../domain/variable-query-input';
import { TabSelected } from '../../../product-entry-type/change-tab';
import { PackagingTypeOverride } from '../../../product-detail/packaging-type-override';
import { PackagingType } from '@deliverr/commons-objects';

export type ActiveQuery =
  | {
      type: 'amazon';
      values: AmazonQueryInput;
    }
  | {
      type: 'product-id';
      values: ProductQueryInput;
      cache: ProductQueryCache;
    }
  | {
      type: 'manual';
      values: ManualQueryInput;
    }
  | null;

export const initialActiveQuery = null;

export type ActiveQueryAction =
  | AmazonQuerySucceeded
  | ProductQuerySucceeded
  | ManualQuerySucceeded
  | VariableInputUpdateSucceeded
  | PackagingTypeOverride
  | TabSelected;

export const activeQuery = (
  state = initialActiveQuery,
  action: ActiveQueryAction,
): ActiveQuery => {
  switch (action.type) {
    case AmazonQuerySucceeded:
      return {
        type: 'amazon',
        values: action.input,
      };
    case ProductQuerySucceeded:
      return {
        type: 'product-id',
        values: action.input,
        cache: action.cache,
      };
    case ManualQuerySucceeded:
      return {
        type: 'manual',
        values: action.input,
      };
    case VariableInputUpdateSucceeded:
      return {
        type: state.type,
        values: {
          ...state.values,
          ...asVariableQueryInput(action.input),
        },
      };
    case PackagingTypeOverride:
      return {
        type: state.type,
        values: {
          ...state.values,
          minimumPackagingType: PackagingType.SHIPS_IN_OWN_CONTAINER,
        },
      };
    case TabSelected:
      return initialActiveQuery;
    default:
      return state;
  }
};
