/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import Switch from 'react-switch';

import { blue, white } from '../../style/colors';
import { fontPrimaryBold } from '../../style/text';

export type ToggleProps = {
  moreInfoLink?: string;
  onChange: () => void;
  enabled: boolean;
  justifyItems?: string;
};

export const Toggle: FC<ToggleProps> = ({
  children,
  moreInfoLink,
  onChange,
  enabled,
  justifyItems = 'center',
}) => {
  return (
    <div
      css={css`
        background-color: ${white};
        padding: 1.25rem 2.5rem 0.625rem 2.5rem;
        display: flex;
        align-items: center;
      `}
    >
      <label
        css={css`
          display: grid;
          align-items: center;
          justify-items: ${justifyItems};
          grid-template-columns: 11fr 89fr;
          gap: 0.75rem;
          margin-right: 0.4rem;
          font-weight: ${fontPrimaryBold};
          cursor: pointer;
        `}
      >
        <Switch
          width={30}
          height={16}
          uncheckedIcon={false}
          checkedIcon={false}
          onColor={blue}
          onChange={onChange}
          checked={enabled}
        />
        <span
          css={css`
            white-space: nowrap;
          `}
        >
          {children}
        </span>
      </label>

      {moreInfoLink && (
        <a
          target="_blank"
          css={css`
            color: inherit;
            opacity: 0.5;

            &:hover {
              opacity: 1;
            }
          `}
          rel="noopener noreferrer"
          href={moreInfoLink}
        >
          <FontAwesomeIcon icon={faQuestionCircle} />
        </a>
      )}
    </div>
  );
};
