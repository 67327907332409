import sampleSize from 'lodash/fp/sampleSize';

import { PRODUCT_SAMPLES } from './product-samples';
import { GetProductSamples } from '../domain/get-product-samples';

export const productSamplesCollectionSize = PRODUCT_SAMPLES.length;

export const getProductSamples: GetProductSamples = ({
  exclude = '',
  count = productSamplesCollectionSize,
} = {}) =>
  sampleSize(
    count,
    PRODUCT_SAMPLES.filter(([, { id }]) => id !== exclude),
  );
