/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FC, useState, useEffect, useMemo, useCallback, Fragment } from 'react';
import css from '@emotion/css/macro';

import { WithStyleProps } from '../../style/helper';
import { grey_400 } from '../../style/colors';
import { ManualEntryDimension } from './manual-entry-dimension';
import { OptionTypeBase } from 'react-select';
import { SizeUnit } from '../../../domain/size';
import { WeightUnit } from '../../../domain/weight';
import {
  ManualEntryForm,
  defaultWidth,
  defaultHeight,
  defaultLength,
} from '../../../domain/entry/manual-entry-form';
import { ProductEntry } from '../product-entry';
import { manualQuerySent } from './manual-query-sent';
import { useDispatch, useSelector } from 'react-redux';
import { validateManualEntryForm } from '../../../domain/entry/validate-manual-entry-form';
import { isManualEntryValidationError } from '../../../domain/entry/manual-entry-validation-error';
import { Manual } from '../../../domain/entry-type';
import { manualEntryValidationFailed } from './manual-entry-validation-failed';
import { isDetailQueryInFlight } from '../../redux/selectors/is-detail-query-in-flight';
import { Dimensions } from '../../../domain/dimensions';
import { notDesktop } from '../../common/media/media-type';
import { ProductEntryTypesSelect } from '../../product-entry-type/product-entry-types-select';
import { ProductEntryTypes } from '../../product-entry-type/product-entry-types';
import { ProductEntryButton } from '../product-entry-button';
import { getServiceDetail } from '../../redux/selectors/get-service-detail';
import { Loading } from '../../common/loading';
import { useMediaTypes } from '../../common/media/use-media-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { ProductEntrySelect } from '../product-entry-select';
import { manualEntryMessages } from './manual-entry-messages';

export type ManualEntryProps = {
  value: ManualEntryForm;
} & WithStyleProps;

const mobileUnitOptions = [
  {
    value: { sizeUnit: 'in', weightUnit: 'lb' },
    label: (
      <FormattedMessage
        id="productEntry.manualEntry.imperial"
        defaultMessage="Imperial (in / lbs)"
      />
    ),
  },
  {
    value: { sizeUnit: 'cm', weightUnit: 'kg' },
    label: (
      <FormattedMessage
        id="productEntry.manualEntry.metric"
        defaultMessage="Metric (cm / kgs)"
      />
    ),
  },
];

const desktopUnitOptions = [
  {
    value: { sizeUnit: 'in', weightUnit: 'lb' },
    label: (
      <FormattedMessage
        id="productEntry.manualEntry.inLb"
        defaultMessage="in/lb"
      />
    ),
  },
  {
    value: { sizeUnit: 'cm', weightUnit: 'kg' },
    label: (
      <FormattedMessage
        id="productEntry.manualEntry.cmKg"
        defaultMessage="cm/kg"
      />
    ),
  },
];

export const ManualEntry: FC<ManualEntryProps> = ({ value }) => {
  const dispatch = useDispatch();
  const requestInFlight = useSelector(isDetailQueryInFlight);
  const serviceDetail = useSelector(getServiceDetail);
  const { formatMessage } = useIntl();

  const [width, setWidth] = useState(value.size.width);
  const [height, setHeight] = useState(value.size.height);
  const [length, setLength] = useState(value.size.length);
  const [sizeUnit, setSizeUnit] = useState<SizeUnit>(value.size.unit);
  const [weight, setWeight] = useState(value.weight.value);
  const [weightUnit, setWeightUnit] = useState<WeightUnit>(value.weight.unit);
  const { isDesktop } = useMediaTypes();

  const formValue = useMemo<Dimensions>(
    () => ({
      size: {
        width,
        height,
        length,
        unit: sizeUnit,
      },
      weight: {
        value: weight,
        unit: weightUnit,
      },
    }),
    [width, height, length, sizeUnit, weight, weightUnit],
  );

  const formIsDefault = useMemo(
    () =>
      width === defaultWidth ||
      height === defaultHeight ||
      length === defaultLength ||
      weight === defaultLength,
    [width, height, length, weight],
  );

  useEffect(() => {
    setWidth(value.size.width);
    setHeight(value.size.height);
    setLength(value.size.length);
    setSizeUnit(value.size.unit);
    setWeight(value.weight.value);
    setWeightUnit(value.weight.unit);
  }, [value]);

  const calculateCost = useCallback(() => {
    const result = validateManualEntryForm(formValue);

    if (isManualEntryValidationError(result)) {
      dispatch(manualEntryValidationFailed(result));
    } else {
      dispatch(manualQuerySent(formValue));
    }
  }, [dispatch, formValue]);

  const desktopUnitSelect = (
    <ProductEntrySelect
      width="135px"
      position="end"
      options={desktopUnitOptions}
      onChange={({ value }: OptionTypeBase) => {
        setWeightUnit(value.weightUnit);
        setSizeUnit(value.sizeUnit);
      }}
      value={desktopUnitOptions.find(
        ({ value }) => value.weightUnit === weightUnit,
      )}
    />
  );

  const mobileUnitSelect = (
    <ProductEntrySelect
      options={mobileUnitOptions}
      onChange={({ value }: OptionTypeBase) => {
        setWeightUnit(value.weightUnit);
        setSizeUnit(value.sizeUnit);
      }}
      value={mobileUnitOptions.find(
        ({ value }) => value.weightUnit === weightUnit,
      )}
      css={css`
        margin-bottom: 16px;
      `}
    />
  );

  const dimensions = (
    <Fragment>
      <ManualEntryDimension defaultValue={length} onChange={setLength}>
        {formatMessage(manualEntryMessages.length)}
      </ManualEntryDimension>
      <ManualEntryDimension defaultValue={width} onChange={setWidth}>
        {formatMessage(manualEntryMessages.width)}
      </ManualEntryDimension>
      <ManualEntryDimension defaultValue={height} onChange={setHeight}>
        {formatMessage(manualEntryMessages.height)}
      </ManualEntryDimension>
      <ManualEntryDimension
        defaultValue={weight}
        onChange={(value) => {
          setWeight(value);
        }}
      >
        {formatMessage(manualEntryMessages.weight)}
      </ManualEntryDimension>
    </Fragment>
  );

  const dimensionsContainer = (
    <div
      css={css`
        flex-grow: 1;
        display: grid;
        grid-auto-columns: minmax(0, 1fr);
        grid-auto-flow: column;

        > :not(:last-of-type) {
          border-right: 1px solid ${grey_400};
        }
      `}
    >
      {dimensions}
    </div>
  );

  const calculateButton = (
    <ProductEntryButton
      css={css`
        @media ${notDesktop} {
          width: 100%;
          margin-top: 4px;
        }
      `}
      disabled={formIsDefault || requestInFlight}
      onClick={({ currentTarget }) => {
        currentTarget.blur();
        calculateCost();
      }}
    >
      {requestInFlight && serviceDetail ? (
        <Loading size="auto" />
      ) : (
        <Fragment>{formatMessage(manualEntryMessages.calculateCost)}</Fragment>
      )}
    </ProductEntryButton>
  );

  return isDesktop ? (
    <ProductEntry calculate={calculateButton}>
      <ProductEntryTypesSelect activeEntryType={Manual} />
      {dimensionsContainer}
      {desktopUnitSelect}
    </ProductEntry>
  ) : (
    <Fragment>
      <ProductEntryTypes activeEntryType={Manual} />
      <ProductEntry
        css={css`
          padding: 20px;
          display: block;
        `}
        calculate={calculateButton}
      >
        {mobileUnitSelect}
        {dimensions}
      </ProductEntry>
    </Fragment>
  );
};
