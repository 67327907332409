import { Box, Stack, useAboveExtraLarge, useAboveSmall } from '@deliverr/ui';
import styled from '@emotion/styled';
import React, { FC } from 'react';
import { B2BDisplayCostLayout } from '../B2BDisplayCost/B2BDisplayCostLayout/B2BDisplayCostLayout';
import { SampleProductsGrid } from '../B2BProduct/SampleProductsGrid';
import { B2BSectionHeader } from '../B2BSectionHeader/B2BSectionHeader';
import { Tabs } from '../../../common/components/Tabs/Tabs';
import { useB2BCostCalculatorLayout } from './useB2BCostCalculatorLayout';
import { B2BCostCalculatorTabType } from '../../types/B2BCostCalculatorTabType';
import { ManualInput } from './ManualInput';
import { useMount } from 'react-use';
import { useIntl } from 'react-intl';
import { B2BCostCalculatorMobileLayout } from './B2BCostCalculatorMobileLayout';
import { WholesaleUrl } from '../../../../view/common/web-resource';
import { B2BNavBar } from '../B2BNavBar/B2BNavBar';

const StyledStack = styled(Stack)`
  margin-top: 4rem;
`;

export const B2BCostCalculatorLayout: FC = () => {
  const { formatMessage } = useIntl();
  const {
    tabOptions,
    currentTab,
    handleTabChange,
  } = useB2BCostCalculatorLayout();
  const isNotMobile = useAboveSmall();
  const isAboveXLarge = useAboveExtraLarge();

  useMount(() => {
    document.title = formatMessage({
      id: 'b2b.rateCalculator.document.title',
      defaultMessage: 'B2B & Replenishment Cost Calculator',
    });
  });

  return (
    <>
      <B2BNavBar logoHref={WholesaleUrl} />
      <B2BSectionHeader />
      {!isNotMobile ? (
        <B2BCostCalculatorMobileLayout />
      ) : (
        <StyledStack direction="HORIZONTAL" gap={null} justifyContent="center">
          <Box marginRight="S7">
            <Tabs
              currentTab={currentTab}
              tabOptions={tabOptions}
              onChange={handleTabChange}
            />
            {currentTab === B2BCostCalculatorTabType.SAMPLE_PRODUCTS_GRID ? (
              <SampleProductsGrid />
            ) : (
              <ManualInput />
            )}
          </Box>
          <Box marginLeft={isAboveXLarge ? 'S7' : null}>
            <B2BDisplayCostLayout />
          </Box>
        </StyledStack>
      )}
    </>
  );
};
