import React, { FC } from 'react';
import styled from '@emotion/styled';
import { ThemeProps } from '@deliverr/ui';

const Container = styled.div<ThemeProps>(
  ({ theme }) => `
  position: relative;
  margin-bottom: ${theme.spacing.S7};

`,
);

const TabList = styled.fieldset`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IndicatorHeight = '4px';

const Tab = styled.label<ThemeProps>(
  ({ theme }) => `
  margin: 0;
  :hover {
    cursor: pointer;
    .filter_label {
      border-bottom-color: ${theme.colors.NEUTRAL[80]};
      color: ${theme.colors.NEUTRAL[500]};
    }
  }
`,
);

const LabelContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.NEUTRAL[300]};
  padding-left: ${theme.spacing.S3};
  padding-right: ${theme.spacing.S3};
  font-size: ${theme.font.size.F5};
  font-weight ${theme.font.weight.BOLD}
`,
);

const TabInput = styled.input<ThemeProps>(
  ({ theme }) => `
  appearance: none;
  opacity: 0;
  position: absolute;
  :checked + div > .filter_label {
    border-bottom-color: black;
    color: ${theme.colors.NEUTRAL[500]};
  }
`,
);

const TabLabel = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  align-items: center;
  height: 2rem;
  gap: ${theme.spacing.S1};
  border-bottom: ${IndicatorHeight} solid transparent;
`,
);

interface TabOption {
  label: string | JSX.Element;
  value: string;
}

interface TabsProps {
  currentTab: string;
  tabOptions: TabOption[];
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Tabs: FC<TabsProps> = ({ currentTab, onChange, tabOptions }) => (
  <Container>
    <TabList>
      {tabOptions.map((tab) => (
        <Tab key={tab.value}>
          <TabInput
            type="radio"
            name="tab"
            onChange={onChange}
            value={tab.value}
            checked={tab.value === currentTab}
          />
          <LabelContainer>
            <TabLabel className="filter_label">{tab.label}</TabLabel>
          </LabelContainer>
        </Tab>
      ))}
    </TabList>
  </Container>
);
