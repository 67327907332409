/** @jsx jsx */
import { WeightUnit } from '@deliverr/commons-objects';
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';
import { truncate } from 'lodash/fp';
import { FC } from 'react';
import { IntlFormatters } from 'react-intl';
import { ServiceDetail } from '../../../domain/service-detail';
import { SizeUnit } from '../../../domain/size';
import { desktop, notDesktop } from '../../common/media/media-type';
import { green, white } from '../../style/colors';
import { WithStyleProps } from '../../style/helper';
import { fontPrimaryBold } from '../../style/text';
import { productDetailMessages } from './product-detail-messages';

const displayTitle = truncate({ length: 90, separator: ' ' });

export type ProductViewProps = {
  detail: ServiceDetail;
  sizeUnit: SizeUnit;
  weightUnit: WeightUnit;
  formatMessage: IntlFormatters['formatMessage'];
} & WithStyleProps;

export const ProductView: FC<ProductViewProps> = ({
  detail,
  sizeUnit,
  weightUnit,
  formatMessage,
}) => (
  <section
    css={css`
      display: flex;
      padding: 30px;
      background-color: ${white};
      border: 2px solid ${green};
      border-radius: 4px;
      box-shadow: 0 4px 16px -4px rgba(100, 139, 255, 0.2);

      @media ${notDesktop} {
        flex-direction: column;
      }

      @media ${desktop} {
        height: 150px;
      }
    `}
  >
    <div
      css={css`
        text-align: center;
        flex-shrink: 0;
        align-self: center;

        @media ${desktop} {
          width: 47%;
          height: 100%;
          margin-right: 40px;
        }
      `}
    >
      <img
        css={css`
          width: 100%;
          height: 100%;
          object-fit: contain;
        `}
        src={detail.imageUrl}
        alt={detail.title}
      />
    </div>
    <div
      css={css`
        & > * {
          margin-bottom: 10px;
        }
      `}
    >
      <header
        css={css`
          font-size: 14px;
          font-weight: ${fontPrimaryBold};
          line-height: 1.71em;
        `}
      >
        {displayTitle(detail.title)}
      </header>
      <div>
        {formatMessage(productDetailMessages.dimensions)} {detail.length}{' '}
        {formatMessage(productDetailMessages[sizeUnit])} x {detail.width}{' '}
        {formatMessage(productDetailMessages[sizeUnit])} x {detail.height}{' '}
        {formatMessage(productDetailMessages[sizeUnit])}
      </div>
      <div>
        {formatMessage(productDetailMessages.itemWeight)} {detail.weight}{' '}
        {formatMessage(productDetailMessages[weightUnit])}
      </div>
    </div>
  </section>
);
