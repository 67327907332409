/** All the FEES are in USD */
export const INBOUND_PALLET_HANDLING_FEE = 13;
export const INBOUND_FLC_40_FT_HANDLING_FEE = 650;
export const INBOUND_FLC_20_FT_HANDLING_FEE = 325;
export const FIRST_MONTH_STORAGE_FEE = 14;
export const RECURRING_MONTHLY_STORAGE_FEE = 25;
export const LONG_TERM_STORAGE_FEE = 60;
export const OUTBOUND_CARTON_HANDLING_FEE = 2.3;
export const OUTBOUND_PALLET_HANDLING_FEE = 14;
export const OUTBOUND_LABEL_FEE = 0.5;
export const GRADE_B_PALLET_FEE = 16;
