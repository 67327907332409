import { VariableQueryInput } from '../../domain/variable-query-input';
import { ServiceDetail } from '../../domain/service-detail';

export const VariableInputUpdateSucceeded = 'VARIABLE_INPUT_UPDATE_SUCCEEDED';

export type VariableInputUpdateSucceeded = ReturnType<
  typeof variableInputUpdateSucceeded
>;

export const variableInputUpdateSucceeded = (
  input: VariableQueryInput,
  detail: ServiceDetail,
) =>
  ({
    type: VariableInputUpdateSucceeded,
    input,
    detail,
  } as const);
