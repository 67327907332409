import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { languageTypeChanged } from './footer/language-changed';
import { getActiveEntryType } from './redux/selectors/get-active-entry-type';
import { getAmazonEntryValue } from './redux/selectors/get-amazon-entry-value';
import { getIsLanguageTypeChinese } from './redux/selectors/get-is-language-type-chinese';
import { getLanguageType } from './redux/selectors/get-language-type';
import { getManualEntryValue } from './redux/selectors/get-manual-entry-value';
import { getQueryMessage } from './redux/selectors/get-query-message';

export const useAll = () => {
  const activeEntryType = useSelector(getActiveEntryType);
  const amazonEntryValue = useSelector(getAmazonEntryValue);
  const manualEntryValue = useSelector(getManualEntryValue);
  const queryMessage = useSelector(getQueryMessage);
  const language = useSelector(getLanguageType);
  const isLanguageTypeChinese = useSelector(getIsLanguageTypeChinese);

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/cn') {
      dispatch(languageTypeChanged('zh-CN'));
      return;
    }

    dispatch(languageTypeChanged('en'));
  }, [location, dispatch]);

  return {
    activeEntryType,
    amazonEntryValue,
    manualEntryValue,
    queryMessage,
    isLanguageTypeChinese,
    language,
  };
};
