export const messages = {
  'zh-CN': {
    'banner.hero.costCalculator': '成本计算器',
    'banner.hero.headerTextOne':
      '输入商品信息，估算您整个产品目录的费用。或您也可以参考我们的{profitCalculator}, 了解Flexport对您业务利润的提升',
    'banner.nav.logIn': '登陆',
    'banner.nav.signUp': '注册账号',
    'productSamples.supplements': '保健品',
    'productSamples.espressoMachine': '意式浓缩咖啡机',
    'productSamples.climbingGear': '攀岩装备',
    'productSamples.iphoneCase': 'iPhone手机壳',
    'productSamples.androidCharger': '安卓充电器',
    'productSamples.beautyKit': '美妆用品',
    'productSamples.babyToys': '婴儿玩具',
    'productSamples.proteinPowder': '蛋白粉',
    'productSamples.officeSupplies': '办公用品',
    'productSamples.petSupplies': '宠物用品',
    'main.sample.title': '参考更多的产品案例',
    'main.sample.moreSamples': '查看更多产品案例',
    'productEntryType.tab.amazonEntry': '亚马逊链接',
    'productEntryType.tab.manualEntry': '手动输入产品信息 ',
    'productEntry.amazonEntry.placeholder': '输入亚马逊产品链接或ASIN',
    'productEntry.amazonEntry.calculateCost': '计算费用',
    'productEntry.manualEntry.length': '长',
    'productEntry.manualEntry.width': '宽',
    'productEntry.manualEntry.height': '高',
    'productEntry.manualEntry.weight': '重量',
    'productEntry.manualEntry.inLb': '英寸/磅',
    'productEntry.manualEntry.cmKg': '厘米/公斤',
    'productEntry.manualEntry.calculateCost': '计算费用',
    'productDetail.costDetail.fulfillmentCost': '履约费用',
    'productDetail.costDetail.peakSurchargesToggle': '2022年高峰期附加费',
    'productDetail.settings.country':
      '如您需将产品从美国运到其他国家，请选择目的地国家',
    'productDetail.settings.unitsPerOrder': '每个配送订单的产品数量',
    'productDetail.settings.packagingType.bubble.label': 'Flexport推荐包装',
    'productDetail.settings.packagingType.bubble.content':
      '可使用Flexport提供塑料袋或气泡信封袋发货',
    'productDetail.settings.packagingType.box.label': '易碎产品包装',
    'productDetail.settings.packagingType.box.content':
      '针对易碎物品，推荐使用装箱发货',
    'productDetail.settings.packagingType.shipInOwn.label':
      '随时发货 (Ready to Ship) 包装',
    'productDetail.settings.packagingType.shipInOwn.content':
      '无需额外包装，使用自带包装发货',
    'productDetail.costDetail.unitsPerOrder': '每个配送订单的产品数量',
    'productDetail.costDetail.internationalShippingLearnMore':
      '若要查看国际运费, 请进入{inventoryPage}, 选择"全球运输"和目的国家',
    'productDetail.costDetail.prices': '定價包括所有 {fastTags}',
    'productDetail.costDetail.pricesFastTags': '快速徽章',
    'productDetail.costDetail.rates':
      '每天接收1000+订单？{rates}可拿到更优惠报价',
    'productDetail.costDetail.peakSurcharges':
      '在10/21到1/15之间，履约将收取额外的高峰期附加费。{learnMore}。',
    'productDetail.costDetail.peakSurchargesLearnMore': '点击此处了解更多',
    'productDetail.productDetail.salesChannel.more': '+ 更多',
    'productDetail.productDetail.salesChannel.shopifyIcon': 'Shopify 圖標',
    'productDetail.productDetail.salesChannel.googleIcon': 'Google 圖標',
    'productDetail.productDetail.salesChannel.ebayIcon': 'Ebay 圖標',
    'productDetail.productDetail.salesChannel.walmartIcon': 'Walmart 圖標',
    'productDetail.productDetail.salesChannel.facebookIcon': 'Facebook 圖標',
    'productDetail.storage.title': '仓储费用（每月)',
    'productDetail.storage.monthlyUnits':
      '所显示费用为该SKU在Flexport{br}存储XX个单价产品数量的总费用',
    'productDetail.storage.totalCharge':
      '所显示费用为该SKU在Flexport存储{monthlyUnitsStored}个单价商品数量的总费用。',
    'productDetail.storage.longTermStorage': '显示费用基于当月费率。',
    'productDetail.storage.currentMonth': '显示费用基于当月费率。',
    'productDetail.storage.peakPeriod':
      '费率在1月至9月和10月至12月之间会有所不同。',
    'productDetail.productDetail.leadCapture': '透明、清晰的报价方案',
    'productDetail.productDetail.leadCapture.subText':
      '我们的客户经理会在24h内与您取得联系',
    'productDetail.productDetail.leadCapture.continue': '联系Flexport客户经理',
    'productDetail.productDetail.productView.dimensions': '尺寸:',
    'productDetail.productDetail.productView.itemWeight': '商品重量:',
    'productDetail.productDetail.relatedProducts': '相关产品',
    'productDetail.productDetail.productView.in': '英寸',
    'productDetail.productDetail.productView.cm': '厘米',
    'productDetail.productDetail.productView.lb': '磅',
    'productDetail.productDetail.productView.kg': '公斤',
    'footer.header': '全球卖家的专业物流服务',
    'footer.header.subText':
      '免费注册Flexport卖家平台, 轻松集成您的各大电商平台订单, 实现不同销售平台的库存共享。',
    'footer.header.getStarted': '即刻体验Flexport卖家平台',
    'footer.header.letsChat': '有更多问题? {letsChat}',
    'footer.column.header.company': '公司介绍',
    'footer.column.company.aboutUs': '关于我们',
    'footer.column.company.careers': '加入Flexport',
    'footer.column.company.blog': '博客',
    'footer.column.header.caseStudies': '客户案例',
    'footer.language': 'Language ({language})',
    'footer.termsOfService': 'Terms of Service',
    'footer.privacy': 'Privacy',
    'footer.followUs': '关注我们',
    'errorMessage.notAnAsin':
      '您输入的ASIN或亚马逊商品链接错误, 请输入正确的ASIN或亚马逊产品链接',
    'errorMessage.productNotFound':
      '找不到此产品, 请检查您的ASIN或亚马逊产品链接。',
    'errorMessage.oversized':
      '{icon}若SKU重量超出20磅, 或体积超出18英寸x14英寸x8英寸, 则需要您提前打包后发出。如果您要额外打包, 请了解{prep}',
    'errorMessage.negativeDims': '宽度、高度、长度或重量的数值必须大于 0。',
  },
};
