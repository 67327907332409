import { Optional } from 'utility-types';
import { ManualQueryInput } from './manual-query-input';
import { VariableQueryInput } from '../../../domain/variable-query-input';
import { defaultUnitsPerOrder } from '../../product-detail/units-per-order';
import { defaultMonthlyUnitsStored } from '../../product-detail/monthly-units-stored';
import { QueryMetadata } from '../../main/query-metadata';
import { defaultSurge } from '../../product-detail/surge';
import { defaultHasBattery } from '../../product-detail/has-batteries';
import { defaultShippingCountry } from '../../product-detail/shipping-country';
import { defaultMinimumPackagingType } from '../../product-detail/minimum-packaging-type';

export const ManualQuerySent = 'MANUAL_QUERY_SENT';

export type ManualQuerySent = ReturnType<typeof manualQuerySent>;

export const manualQuerySent = ({
  unitsPerOrder = defaultUnitsPerOrder,
  monthlyUnitsStored = defaultMonthlyUnitsStored,
  surge = defaultSurge,
  hasBattery = defaultHasBattery,
  country = defaultShippingCountry,
  meta = {} as QueryMetadata,
  minimumPackagingType = defaultMinimumPackagingType,
  ...input
}: Optional<ManualQueryInput, keyof VariableQueryInput> &
  Partial<{ meta: QueryMetadata }>) =>
  ({
    type: ManualQuerySent,
    input: {
      ...input,
      unitsPerOrder,
      monthlyUnitsStored,
      surge,
      hasBattery,
      country,
      minimumPackagingType,
    },
    meta,
  } as const);
