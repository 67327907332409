import { MonthlyUnitsStored } from './monthly-units-stored';

export const MonthlyUnitsStoredChanged = 'MONTHLY_UNITS_STORED_CHANGED';

export type MonthlyUnitsStoredChanged = ReturnType<
  typeof monthlyUnitsStoredChanged
>;

export const monthlyUnitsStoredChanged = (
  monthlyUnitsStored: MonthlyUnitsStored,
) =>
  ({
    type: MonthlyUnitsStoredChanged,
    monthlyUnitsStored,
  } as const);
