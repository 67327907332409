import { FullProduct } from '../../../domain/product';
import { ProductSamplesReceived } from '../../main/product-samples-received';
import { MoreProductSamplesReceived } from '../../main/more-product-samples-received';

export type ProductSamples = FullProduct[];

export type ProductSamplesAction =
  | ProductSamplesReceived
  | MoreProductSamplesReceived;

export const initialProductSamples: ProductSamples = [];

export const productSamples = (
  state = initialProductSamples,
  { type, samples }: ProductSamplesAction,
) => {
  switch (type) {
    case ProductSamplesReceived:
      return samples;
    case MoreProductSamplesReceived:
      return [...state, ...samples];
    default:
      return state;
  }
};
