import { ServiceDetail } from './service-detail';
import { createProviderWithHook } from '../helpers/provider-with-hook';
import { GetDetailError } from './get-detail-error';
import { CountryCode, PackagingType } from '@deliverr/commons-objects';

export type GetFulfillmentDetail = (
  input: GetFulfillmentDetailInput,
) => Promise<ServiceDetail>;

export type GetFulfillmentDetailInput = {
  asin: string;
  unitsPerOrder: number;
  monthlyUnitsStored: number;
  surge: boolean;
  hasBattery: boolean;
  country: CountryCode;
  minimumPackagingType: PackagingType;
};

const { provider, useHook } = createProviderWithHook<GetFulfillmentDetail>();

export const GetFulfillmentDetailProvider = provider;

export const useGetFulfillmentDetail = useHook;

export type GetFulfillmentDetailError = GetDetailError;
