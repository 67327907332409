import { createSelector } from 'reselect';
import { getActiveQuery } from './get-active-query';

export const getActiveProductId = createSelector(
  getActiveQuery,
  (activeQuery) => {
    return (
      (activeQuery?.type === 'product-id' && activeQuery.values.productId) || ''
    );
  },
);
