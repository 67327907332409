import { useSelector, useDispatch } from 'react-redux';

import { UnitsPerOrderChanged } from '../product-detail/unit-per-order-changed';
import { getActiveQuery } from '../redux/selectors/get-active-query';
import { useCallback } from 'react';
import { ofType } from '../redux/actions-middleware/of-type';
import { useActions } from '../redux/actions-middleware/use-actions';
import {
  asVariableQueryInput,
  VariableQueryInput,
} from '../../domain/variable-query-input';
import { MonthlyUnitsStoredChanged } from '../product-detail/monthly-units-stored-changed';
import { variableInputUpdateSent } from './variable-input-update-sent';
import { ActiveQuery } from '../redux/state/query/active';
import { ShippingCountryChanged } from '../product-detail/shipping-country-changed';
import { SurgeChanged } from '../product-detail/surge/surge-changed';

export type VariableInputUpdate =
  | SurgeChanged
  | UnitsPerOrderChanged
  | MonthlyUnitsStoredChanged
  | ShippingCountryChanged;

const getInput = (query: ActiveQuery, action: VariableInputUpdate) => {
  switch (action.type) {
    case SurgeChanged:
      return asVariableQueryInput(query.values, {
        surge: action.surge,
      });
    case UnitsPerOrderChanged:
      return asVariableQueryInput(query.values, {
        unitsPerOrder: action.unitsPerOrder,
      });
    case MonthlyUnitsStoredChanged:
      return asVariableQueryInput(query.values, {
        monthlyUnitsStored: action.monthlyUnitsStored,
      });
    case ShippingCountryChanged:
      return asVariableQueryInput(query.values, {
        country: action.country,
      });
  }
};

export const useVariableInputUpdate = () => {
  const queryInput = useSelector(getActiveQuery);
  const dispatch = useDispatch();

  const handler = useCallback(
    async (input: VariableQueryInput) => {
      dispatch(variableInputUpdateSent(input, queryInput));
    },
    [dispatch, queryInput],
  );

  useActions<VariableInputUpdate>((action) => {
    handler(getInput(queryInput, action));
  }, ofType(SurgeChanged, UnitsPerOrderChanged, MonthlyUnitsStoredChanged, ShippingCountryChanged));
};
