/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';
import { ThemeProvider } from '@emotion/react';
import { FC, Fragment, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import { useGetUserIsLoggedIn } from '../../../../domain/get-user-is-logged-in';
import { desktop, notDesktop } from '../../../../view/common/media/media-type';
import { NavItem } from '../../../../view/common/nav/nav-item';
import {
  linkToWebResource,
  SellerPortal,
  Login,
  SignUp,
} from '../../../../view/common/web-resource';
import { white, black, midnight_blue } from '../../../../view/style/colors';
import { link, iconButton } from '../../../../view/style/defaults';
import { WithStyleProps, showWhen } from '../../../../view/style/helper';
import { LogoBlack } from '../../../common/components/LogoBlack';
import { b2bNavTheme } from './b2bNavTheme';

export const headerStyle = [
  css`
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2.5rem;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    position: sticky;
    z-index: 10000;
    top: 0;

    @media ${notDesktop} {
      padding: 1rem;
    }
  `,
];

export const burgerIconStyle = css`
  right: 3vw;
  position: absolute;
  z-index: 20;
  font-size: 32px;
  color: ${black};

  @media ${desktop} {
    display: none;
  }
`;

export const linksStyle = css`
  background-color: ${midnight_blue};
  width: 200px;
  margin-top: -3vw;
  padding: 12vw 25px 0 25px;
  display: none;
  flex-direction: column;

  @media ${notDesktop} {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }

  @media ${desktop} {
    background-color: inherit;
    width: auto;
    padding-top: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
  }
`;

export type NavProps = WithStyleProps & {
  logoHref: string;
};

export const B2BNavBar: FC<NavProps> = ({ className, logoHref }) => {
  const [expanded, setExpanded] = useState(false);
  const userIsLoggedIn = useMemo(useGetUserIsLoggedIn(), []);

  return (
    <nav className={className} css={headerStyle}>
      <a
        css={[
          css`
            display: flex;
          `,
        ]}
        href={logoHref}
      >
        <LogoBlack />
      </a>
      <button
        onClick={() => setExpanded(!expanded)}
        css={[link, iconButton, burgerIconStyle]}
      >
        {expanded ? (
          <FontAwesomeIcon
            css={[
              css`
                color: white;
              `,
            ]}
            icon={faTimes}
          />
        ) : (
          <FontAwesomeIcon icon={faBars} />
        )}
      </button>
      <ThemeProvider theme={b2bNavTheme}>
        <div css={[linksStyle, showWhen(expanded, 'flex')]}>
          {userIsLoggedIn ? (
            <NavItem
              css={[
                css`
                  border: 1px solid ${white};

                  &:hover {
                    color: black;
                    background-color: white;
                  }
                `,
              ]}
              link={linkToWebResource(SellerPortal)}
            >
              <FormattedMessage
                id="banner.nav.myAccount"
                defaultMessage="My Account"
              />
            </NavItem>
          ) : (
            <Fragment>
              <NavItem link={linkToWebResource(Login)}>
                <FormattedMessage
                  id="banner.nav.logIn"
                  defaultMessage="Log In"
                />
              </NavItem>
              <NavItem
                css={[
                  css`
                    background-color: black;
                    color: white;
                    border-radius: 100px;
                    border-color: black;
                    border: 2px solid ${white};

                    & a {
                      color: white;
                    }
                    &:hover {
                      color: black;
                      background-color: white;
                      border-color: black;

                      a {
                        color: black;
                      }
                    }
                  `,
                ]}
                link={linkToWebResource(SignUp)}
              >
                <FormattedMessage
                  id="banner.nav.signUp"
                  defaultMessage="Sign Up"
                />
              </NavItem>
            </Fragment>
          )}
        </div>
      </ThemeProvider>
    </nav>
  );
};
