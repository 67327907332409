import { FullProduct } from '../../domain/product';

export const MoreProductSamplesReceived = 'MORE_PRODUCT_SAMPLES_RECEIVED';

export type MoreProductSamplesReceived = ReturnType<
  typeof moreProductSamplesReceived
>;

export const moreProductSamplesReceived = (samples: FullProduct[]) =>
  ({
    type: MoreProductSamplesReceived,
    samples,
  } as const);
