/** @jsx jsx */
import { jsx } from '@emotion/core';
import { FC } from 'react';
import { OptionTypeBase, Props as ReactSelectProps } from 'react-select';
import { useMediaTypes } from '../common/media/use-media-types';
import { Select, SelectProps } from '../common/select';
import { blue, blue_25, blue_50 } from '../style/colors';

const borderRadius = {
  start: '4px 0 0 4px',
  middle: 0,
  end: '0 4px 4px 0',
};

export type ProductEntrySelectProps = SelectProps &
  ReactSelectProps<OptionTypeBase> & {
    width?: string;
    position?: 'start' | 'middle' | 'end';
  };

export const ProductEntrySelect: FC<ProductEntrySelectProps> = ({
  width,
  position = 'middle',
  ...props
}) => {
  const { isDesktop } = useMediaTypes();

  return (
    <Select
      colors={{
        primary: blue,
        primary25: blue_25,
        primary50: blue_50,
        primary75: blue_50,
      }}
      styles={{
        control: (provided) => ({
          ...provided,
          border: isDesktop ? 0 : provided.border,
          borderRadius: borderRadius[position],
          height: '54px',
          width: width ?? provided.width,
        }),
        valueContainer: (provided) => ({
          ...provided,
          paddingLeft: '24px',
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          paddingRight: '12px',
        }),
        menu: (provided) => ({
          ...provided,
          marginTop: '4px',
        }),
        option: (provided) => ({
          ...provided,
          paddingLeft: '24px',
        }),
      }}
      {...props}
    />
  );
};
