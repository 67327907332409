import { createProviderWithHook } from '../helpers/provider-with-hook';
import { FullProduct } from './product';

export type GetProductSamples = (
  input?: GetProductSamplesInput,
) => FullProduct[];

export type GetProductSamplesInput = {
  exclude?: string;
  count?: number;
};

const { provider, useHook } = createProviderWithHook<GetProductSamples>();

export const GetProductSamplesProvider = provider;

export const useGetProductSamples = useHook;
