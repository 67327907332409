import { ManualQueryInput } from './manual-query-input';
import { ServiceDetail } from '../../../domain/service-detail';

export const ManualQuerySucceeded = 'MANUAL_QUERY_SUCCEEDED';

export type ManualQuerySucceeded = ReturnType<typeof manualQuerySucceeded>;

export const manualQuerySucceeded = (
  input: ManualQueryInput,
  detail: ServiceDetail,
) =>
  ({
    type: ManualQuerySucceeded,
    input,
    detail,
  } as const);
