/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';
import {
  FC,
  useMemo,
  useRef,
  useState,
  useCallback,
  useEffect,
  Fragment,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ProductEntry } from '../product-entry';
import { grey_400 } from '../../style/colors';
import { input } from '../../style/defaults';
import { WithStyleProps } from '../../style/helper';
import { defaultAsin } from '../../../domain/entry/asin';
import { Amazon } from '../../../domain/entry-type';
import { amazonQuerySent } from './amazon-query-sent';
import { AmazonEntryForm } from '../../../domain/entry/amazon-entry-form';
import { isDetailQueryInFlight } from '../../redux/selectors/is-detail-query-in-flight';
import { useMediaTypes } from '../../common/media/use-media-types';
import { ProductEntryTypesSelect } from '../../product-entry-type/product-entry-types-select';
import { ProductEntryTypes } from '../../product-entry-type/product-entry-types';
import { ProductEntryButton } from '../product-entry-button';
import { getServiceDetail } from '../../redux/selectors/get-service-detail';
import { Loading } from '../../common/loading';
import { desktop, notDesktop } from '../../common/media/media-type';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { defineMessages, useIntl } from 'react-intl';

export type AmazonEntryProps = {
  value: AmazonEntryForm;
} & WithStyleProps;

const amazonEntryMessages = defineMessages({
  mobilePlaceholder: {
    id: 'productEntry.amazonEntry.mobilePlaceholder',
    defaultMessage: 'ASIN or Link',
  },
  placeholder: {
    id: 'productEntry.amazonEntry.placeholder',
    defaultMessage: 'Enter an Amazon Product Link or ASIN',
  },
  calculateCost: {
    id: 'productEntry.amazonEntry.calculateCost',
    defaultMessage: 'Calculate cost',
  },
});

export const AmazonEntry: FC<AmazonEntryProps> = ({ value }) => {
  const { isDesktop, isMobile } = useMediaTypes();
  const requestInFlight = useSelector(isDetailQueryInFlight);
  const serviceDetail = useSelector(getServiceDetail);
  const inputElement = useRef<HTMLInputElement>();
  const [asin, setAsin] = useState(value.asin);
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const calculateCost = useCallback(() => dispatch(amazonQuerySent({ asin })), [
    dispatch,
    asin,
  ]);
  const asinIsDefault = useMemo(() => asin === defaultAsin, [asin]);

  useEffect(() => {
    setAsin(value.asin);

    if (inputElement.current) {
      inputElement.current.value = value.asin;
    }
  }, [value]);

  const asinInput = (
    <input
      css={[
        input,
        css`
          width: 100%;
          padding: 26px 30px;
          font-size: 16px;

          @media ${notDesktop} {
            padding-left: 20px;
            padding-right: 20px;
          }

          @media ${desktop} {
            border-left: 1px solid ${grey_400};
          }
        `,
      ]}
      defaultValue={asin}
      ref={inputElement}
      onChange={({ currentTarget }) => {
        setAsin(currentTarget.value);
      }}
      placeholder={
        isMobile
          ? formatMessage(amazonEntryMessages.mobilePlaceholder)
          : formatMessage(amazonEntryMessages.placeholder)
      }
    />
  );

  const calculateButton = (
    <ProductEntryButton
      css={css`
        flex-shrink: 0;

        @media ${notDesktop} {
          margin-right: 16px;
          width: 48px;
        }
      `}
      disabled={asinIsDefault || requestInFlight}
      onClick={({ currentTarget }) => {
        currentTarget.blur();
        calculateCost();
      }}
    >
      {requestInFlight && serviceDetail ? (
        <Loading size="auto" />
      ) : (
        <Fragment>
          {isDesktop ? (
            <Fragment>
              {formatMessage(amazonEntryMessages.calculateCost)}
            </Fragment>
          ) : (
            <FontAwesomeIcon icon={faSearch} />
          )}
        </Fragment>
      )}
    </ProductEntryButton>
  );

  return isDesktop ? (
    <ProductEntry calculate={calculateButton}>
      <ProductEntryTypesSelect activeEntryType={Amazon} />
      {asinInput}
    </ProductEntry>
  ) : (
    <Fragment>
      <ProductEntryTypes activeEntryType={Amazon} />
      <ProductEntry calculate={calculateButton}>{asinInput}</ProductEntry>
    </Fragment>
  );
};
