import { Stack, Text } from '@deliverr/ui';
import styled from '@emotion/styled';
import React, { FC } from 'react';
import { RoundedNumberInput } from '../B2BDisplayCost/RoundedNumberInput';
import { StyledPrimaryButton } from '../../../common/components/StyledPrimaryButton';
import { useIntl } from 'react-intl';
import { manualInputMessages } from './manualInputMessages';
import { useManualInput } from './useManualInput';
import { B2B_FONT_FAMILY } from '../../data/constants';

const StyledNumberInput = styled(RoundedNumberInput)`
  width: 90%;
`;

const StyledButton = styled(StyledPrimaryButton)`
  width: fit-content;
  font-family: ${B2B_FONT_FAMILY};
`;

export const ManualInput: FC = () => {
  const { formatMessage } = useIntl();
  const {
    casesPerPallet,
    onCalculateCosts,
    handleCasesPerPalletChange,
    isButtonDisabled,
  } = useManualInput();
  return (
    <Stack direction="VERTICAL" gap="S6">
      <StyledNumberInput
        placeholder={formatMessage(manualInputMessages.casesPerPallet)}
        label={formatMessage(manualInputMessages.casesPerPallet)}
        value={casesPerPallet}
        onChange={handleCasesPerPalletChange}
        min={1}
        type="number"
      />
      <StyledButton onClick={onCalculateCosts} disabled={isButtonDisabled}>
        <Text as="div">
          {formatMessage(manualInputMessages.calculateCosts)}
        </Text>
      </StyledButton>
    </Stack>
  );
};
