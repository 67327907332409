export enum CurrencyFormat {
  AED = "AED",
  CAD = "CAD",
  CHF = "CHF",
  CNY = "CNY",
  EUR = "EUR",
  GBP = "GBP",
  INR = "INR",
  JPY = "JPY",
  KRW = "KRW",
  MXN = "MXN",
  RUB = "RUB",
  USD = "USD",
}

export const DEFAULT_CURRENCY = CurrencyFormat.USD;
