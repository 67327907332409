/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { EntryType } from '../../domain/entry-type';
import { SampleProducts } from './sample-products';
import { getServiceDetail } from '../redux/selectors/get-service-detail';
import { ProductDetail } from '../product-detail/product-detail/product-detail';
import { isDetailQueryInFlight } from '../redux/selectors/is-detail-query-in-flight';
import { Loading } from '../common/loading';
import { getActiveProductId } from '../redux/selectors/get-active-product-id';
import { desktop, notDesktop } from '../common/media/media-type';

export type MainProps = {
  activeEntryType: EntryType;
};

export const Body: FC<MainProps> = ({ activeEntryType }) => {
  const serviceDetail = useSelector(getServiceDetail);
  const detailQueryInFlight = useSelector(isDetailQueryInFlight);
  const productId = useSelector(getActiveProductId);

  return (
    <div
      css={css`
        @media ${desktop} {
          padding: 50px 0;
        }

        @media ${notDesktop} {
          padding: 40px 0;
        }
      `}
    >
      {serviceDetail !== null && (
        <ProductDetail detail={serviceDetail} productId={productId} />
      )}
      {serviceDetail === null && detailQueryInFlight && (
        <Loading
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              css={css`
                width: 96px;
                height: 96px;
                animation: spin 5s infinite linear;
                fill: #bec8d1;

                @keyframes spin {
                  0% {
                    transform: rotate(0deg);
                  }
                  100% {
                    transform: rotate(360deg);
                  }
                }
              `}
            >
              <path d="M6.804 15a1 1 0 0 0-1.366-.366l-1.732 1a1 1 0 0 0 1 1.732l1.732-1A1 1 0 0 0 6.804 15ZM3.706 8.366l1.732 1a1 1 0 1 0 1-1.732l-1.732-1a1 1 0 0 0-1 1.732ZM6 12a1 1 0 0 0-1-1H3a1 1 0 0 0 0 2h2a1 1 0 0 0 1-1Zm11.196-3a1 1 0 0 0 1.366.366l1.732-1a1 1 0 1 0-1-1.732l-1.732 1A1 1 0 0 0 17.196 9ZM15 6.804a1 1 0 0 0 1.366-.366l1-1.732a1 1 0 1 0-1.732-1l-1 1.732A1 1 0 0 0 15 6.804Zm5.294 8.83-1.732-1a1 1 0 1 0-1 1.732l1.732 1a1 1 0 0 0 1-1.732Zm-3.928 1.928a1 1 0 1 0-1.732 1l1 1.732a1 1 0 1 0 1.732-1ZM21 11h-2a1 1 0 0 0 0 2h2a1 1 0 0 0 0-2Zm-9 7a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-2a1 1 0 0 0-1-1Zm-3-.804a1 1 0 0 0-1.366.366l-1 1.732a1 1 0 0 0 1.732 1l1-1.732A1 1 0 0 0 9 17.196ZM12 2a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0V3a1 1 0 0 0-1-1Z" />
            </svg>
          }
        ></Loading>
      )}
      {serviceDetail === null && !detailQueryInFlight && (
        <SampleProducts entryType={activeEntryType} />
      )}
    </div>
  );
};
