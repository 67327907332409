/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';
import { FC } from 'react';
import { white } from '../../style/colors';
import { WithStyleProps } from '../../style/helper';

export type QueryOverlayProps = {} & WithStyleProps;

export const QueryOverlay: FC<QueryOverlayProps> = ({ className }) => (
  <div
    css={[
      css`
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: ${white};
        z-index: 100;
      `,
      css`
        opacity: 0.5;
      `,
    ]}
    className={className}
  ></div>
);
