import { combineReducers } from 'redux';
import { EntryError, entryError as error } from './error';
import { AmazonEntry, amazonEntry as amazon } from './amazon';
import { ManualEntry, manualEntry as manual } from './manual';

export type Entry = {
  amazon: AmazonEntry;
  manual: ManualEntry;
  error: EntryError;
};

export const entry = combineReducers({
  amazon,
  manual,
  error,
});
