import { LengthUnit } from '@deliverr/commons-objects';

export const PRODUCT_LONGEST_SIDE_LIMIT = 40;

export const PRODUCT_MEDIAN_SIDE_LIMIT = 30;

export const PRODUCT_SHORTEST_SIDE_LIMIT = 15;

export const PRODUCT_WEIGHT_LIMIT = 75;

export const PRODUCT_DIMENSIONAL_REQUIREMENTS = [
  PRODUCT_LONGEST_SIDE_LIMIT,
  PRODUCT_MEDIAN_SIDE_LIMIT,
  PRODUCT_SHORTEST_SIDE_LIMIT,
  PRODUCT_WEIGHT_LIMIT,
];

export const isValidSizeDimension = (size: number) => size >= 0;

export type SizeUnit = LengthUnit;

export type Size = {
  width: number;
  height: number;
  length: number;
  unit: SizeUnit;
};
