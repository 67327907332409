import React, { FC } from 'react';
import { Box, ThemeProps, Title, Text } from '@deliverr/ui';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { b2bSectionHeaderMessages } from './b2bSectionHeaderMessages';
import { B2B_FONT_FAMILY } from '../../data/constants';

const SectionHeader = styled.section<ThemeProps>(
  ({ theme }) => `
  background: black;
  margin-block-start: 0px;
  padding: 80px 90px;

  @media screen and (max-width: ${theme.breakpoints.MD}) {
    padding: 3rem 1.5rem;
  }
`,
);

const StyledTitle = styled(Title)<ThemeProps>(
  ({ theme }) => `
  font-weight: ${theme.font.weight.BOLD};
  font-family: ${B2B_FONT_FAMILY};
  font-size: 60px;
  line-height: 66px;
  letter-spacing: -0.02rem;  
  color: #FFFFFF;
  margin: 0px;
  max-width: 65%;

  @media screen and (max-width: ${theme.breakpoints.MD}) {
    font-size: ${theme.font.size.F7};
    line-height: ${theme.font.lineHeight.LH7};
    max-width: 100%;
  }
`,
);

const StyledSubtitle = styled(Text)<ThemeProps>(
  ({ theme }) => `
  color: #FFFFFF;
  font-family: ${B2B_FONT_FAMILY};
  margin-top: ${theme.spacing.S4};
  max-width: 50%;
  font-size: ${theme.font.size.F5};
  line-height: ${theme.font.lineHeight.LH5};

  @media screen and (max-width: ${theme.breakpoints.MD}) {
    max-width: 100%;
  }
`,
);

export const B2BSectionHeader: FC = () => {
  const { formatMessage } = useIntl();
  return (
    <SectionHeader>
      <Box>
        <StyledTitle>
          {formatMessage(b2bSectionHeaderMessages.title)}
        </StyledTitle>
        <StyledSubtitle size="h2">
          {formatMessage(b2bSectionHeaderMessages.subtitle)}
        </StyledSubtitle>
      </Box>
    </SectionHeader>
  );
};
