import css from '@emotion/css/macro';
import { SubNavWrapperProps } from '../../../../view/banner/nav-theme';
import { desktop, notDesktop } from '../../../../view/common/media/media-type';
import { white, black } from '../../../../view/style/colors';
import { link } from '../../../../view/style/defaults';
import { fontPrimaryBold, fontPrimaryBlack } from '../../../../view/style/text';

export const b2bNavTheme = {
  nav: {
    item: css`
      @media ${desktop} {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    `,
    subNav: css`
      font-weight: 600;
      border-radius: 8px;
      box-shadow: 0 16px 32px 0 rgba(2, 23, 44, 0.2);
      flex-wrap: wrap;
      display: flex;

      @media ${notDesktop} {
        gap: 10px;
      }

      @media ${desktop} {
        padding: 10px;
        background-color: ${white};
      }
    `,
    subNavWrapper: ({ expanded }: SubNavWrapperProps) => css`
      margin-top: 3em;
      display: ${expanded ? 'initial' : 'none'};

      @media ${notDesktop} {
        width: 255px;
      }

      @media ${desktop} {
        position: absolute;
        z-index: 10;
      }
    `,
    itemLink: [
      link,
      css`
        color: ${black};
        font-weight: ${fontPrimaryBold};
        white-space: nowrap;

        @media ${notDesktop} {
          display: flex;
          height: 50px;
          align-items: center;
          color: ${white};
        }

        @media ${desktop} {
          display: flex;
          align-items: center;
          font-weight: ${fontPrimaryBlack};
          height: 44px;
        }
      `,
    ],
    itemLinkWrapper: [
      css`
        color: ${black};
        display: flex;
        align-items: center;
        justify-content: center;

        @media ${notDesktop} {
          flex-wrap: wrap;
        }

        @media ${desktop} {
          margin: 0 20px;
        }
      `,
    ],
    itemLinkToggle: css`
      margin-top: 4px;

      @media ${notDesktop} {
        margin-left: 10px;

        &:hover {
          cursor: pointer;
        }
      }

      @media ${desktop} {
        height: 10px;
        margin-left: 5px;
      }
    `,
  },
};
