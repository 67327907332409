import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';
import defaultTo from 'lodash/fp/defaultTo';

export const startBugsnag = () => {
  Bugsnag.start({
    apiKey: '6d25adae77a7ba8220f872ef60af618d',
    appVersion: defaultTo(
      'unknown',
      process.env.COMMIT_REF || process.env.REACT_APP_BUILD_ID,
    ),
    appType: 'client',
    plugins: [new BugsnagPluginReact()],
  });

  const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

  return {
    ErrorBoundary,
  };
};
