import { createProviderWithHook } from '../helpers/provider-with-hook';
import { FullProduct } from './product';

export type GetProductSampleByAsin = (asin: string) => FullProduct | undefined;

const { provider, useHook } = createProviderWithHook<GetProductSampleByAsin>();

export const GetProductSampleByAsinProvider = provider;

export const useGetProductSampleByAsin = useHook;
