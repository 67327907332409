import { ServiceDetail } from '../../../domain/service-detail';
import { AmazonQueryInput } from './amazon-query-input';

export const AmazonQuerySucceeded = 'AMAZON_QUERY_SUCCEEDED';

export type AmazonQuerySucceeded = ReturnType<typeof amazonQuerySucceeded>;

export const amazonQuerySucceeded = (
  input: AmazonQueryInput,
  detail: ServiceDetail,
) =>
  ({
    type: AmazonQuerySucceeded,
    input,
    detail,
  } as const);
