import {
  FIRST_MONTH_STORAGE_FEE,
  LONG_TERM_STORAGE_FEE,
  RECURRING_MONTHLY_STORAGE_FEE,
} from '../data/B2B_RATE_CARD';

export const getB2BStorageCost = (
  monthsOfStorage: number,
  numOfPallets: number,
) => {
  if (monthsOfStorage) {
    return (
      numOfPallets *
      (FIRST_MONTH_STORAGE_FEE +
        Math.min(monthsOfStorage - 1, 11) * RECURRING_MONTHLY_STORAGE_FEE +
        Math.max(monthsOfStorage - 12, 0) * LONG_TERM_STORAGE_FEE)
    );
  }
  return 0;
};
