import styled from '@emotion/styled';

export const UnstyledButton = styled.button`
  appearance: none;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  text-align: left;
  white-space: nowrap;
`;
