import { Brand } from 'utility-types';

export type EntryTypeBrand = 'entry-type';

export type Amazon = Brand<'Amazon', EntryTypeBrand>;

export const Amazon = 'Amazon' as Amazon;

export type Manual = Brand<'Manual', EntryTypeBrand>;

export const Manual = 'Manual' as Manual;

export type EntryType = Amazon | Manual;
