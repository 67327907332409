import { Tab } from './tab';

export const TabSelected = 'PRODUCT_ENTRY_TAB_SELECTED';

export type TabSelected = ReturnType<typeof tabSelected>;

export const tabSelected = (tab: Tab) =>
  ({
    type: TabSelected,
    tab,
  } as const);
