import { CountryCode, PackagingType } from '@deliverr/commons-objects';

export type VariableQueryInput = {
  unitsPerOrder: number;
  monthlyUnitsStored: number;
  surge: boolean;
  hasBattery: boolean;
  country: CountryCode;
  minimumPackagingType: PackagingType;
};

export const asVariableQueryInput = (
  {
    unitsPerOrder,
    monthlyUnitsStored,
    surge,
    hasBattery,
    country,
    minimumPackagingType,
  }: VariableQueryInput,
  overrides: Partial<VariableQueryInput> = {},
): VariableQueryInput => ({
  unitsPerOrder,
  monthlyUnitsStored,
  surge,
  hasBattery,
  country,
  minimumPackagingType,
  ...overrides,
});
