import React from 'react';
import { FormattedNumber } from 'react-intl';
import { CurrencyFormat, DEFAULT_CURRENCY } from './CurrencyTypes';

export interface CurrencyProps {
  currency: CurrencyFormat; // USD, EUR, etc
  maximumFractionDigits?: number;
  value?: number;
}

export const Currency = ({
  currency,
  maximumFractionDigits,
  value,
}: CurrencyProps) => {
  if (value === undefined) {
    return <span />;
  }
  return (
    <span>
      <FormattedNumber
        style="currency"
        currency={(currency || DEFAULT_CURRENCY).toUpperCase()}
        currencyDisplay="symbol"
        maximumFractionDigits={maximumFractionDigits}
        value={value}
      />
    </span>
  );
};
