import { ManualEntryValidationError } from '../../../domain/entry/manual-entry-validation-error';

export const ManualEntryValidationFailed = 'MANUAL_ENTRY_VALIDATION_FAILED';

export type ManualEntryValidationFailed = ReturnType<
  typeof manualEntryValidationFailed
>;

export const manualEntryValidationFailed = (
  error: ManualEntryValidationError,
) =>
  ({
    type: ManualEntryValidationFailed,
    error,
  } as const);
