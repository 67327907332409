import { CountryCode } from '@deliverr/commons-objects';
import { useDispatch, useSelector } from 'react-redux';
import { OptionTypeBase } from 'react-select';
import { InternationalRateCountryList } from '../../provider/country-list';
import { getShippingCountry } from '../redux/selectors/get-shipping-country';
import { shippingCountryChanged } from './shipping-country-changed';

export const useShippingSelect = () => {
  const dispatch = useDispatch();
  const shippingCountry = useSelector(getShippingCountry);

  const countryOptions = Object.entries(
    InternationalRateCountryList,
  ).map(([value, label]: [CountryCode, string]) => ({ value, label }));

  const onCountryChange = ({ value }: OptionTypeBase) =>
    dispatch(shippingCountryChanged(value));

  const countrySelectValue = countryOptions.find(
    ({ value }) => value === shippingCountry,
  );

  return {
    shippingCountry,
    countryOptions,
    countrySelectValue,
    onCountryChange,
  };
};
