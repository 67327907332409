import { Size } from './size';
import { convertSizeDimension, convertWeight } from '../helpers/conversions';
import { Weight } from './weight';
import { NormalizedSize, NormalizedWeight } from './dimensions';

export const normalizeSizeToInches = ({
  width,
  height,
  length,
  unit,
}: Size): NormalizedSize =>
  (unit === 'in'
    ? {
        width,
        height,
        length,
      }
    : {
        width: convertSizeDimension(unit, 'in', width),
        height: convertSizeDimension(unit, 'in', height),
        length: convertSizeDimension(unit, 'in', length),
      }) as NormalizedSize;

export const normalizeWeightToLb = ({
  value,
  unit,
}: Weight): NormalizedWeight =>
  (unit === 'lb'
    ? { weight: value }
    : { weight: convertWeight(unit, 'lb', value) }) as NormalizedWeight;
