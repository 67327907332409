import { UnitsPerOrder } from './units-per-order';

export const UnitsPerOrderChanged = 'UNITS_PER_ORDER_CHANGED';

export type UnitsPerOrderChanged = ReturnType<typeof unitsPerOrderChanged>;

export const unitsPerOrderChanged = (unitsPerOrder: UnitsPerOrder) =>
  ({
    type: UnitsPerOrderChanged,
    unitsPerOrder,
  } as const);
