import { ProductQueryInput } from './product-query-input';
import { defaultUnitsPerOrder } from '../product-detail/units-per-order';
import { defaultMonthlyUnitsStored } from '../product-detail/monthly-units-stored';
import { VariableQueryInput } from '../../domain/variable-query-input';
import { Optional } from 'utility-types';
import { defaultSurge } from '../product-detail/surge';
import { defaultHasBattery } from '../product-detail/has-batteries';
import { defaultShippingCountry } from '../product-detail/shipping-country';
import { defaultMinimumPackagingType } from '../product-detail/minimum-packaging-type';
export const ProductQuerySent = 'PRODUCT_QUERY_SENT';

export type ProductQuerySent = ReturnType<typeof productQuerySent>;

export const productQuerySent = (
  {
    productId,
    unitsPerOrder = defaultUnitsPerOrder,
    monthlyUnitsStored = defaultMonthlyUnitsStored,
    surge = defaultSurge,
    hasBattery = defaultHasBattery,
    country = defaultShippingCountry,
    minimumPackagingType = defaultMinimumPackagingType,
  }: Optional<ProductQueryInput, keyof VariableQueryInput>,
  update = false,
) =>
  ({
    type: ProductQuerySent,
    input: {
      productId,
      unitsPerOrder,
      monthlyUnitsStored,
      surge,
      hasBattery,
      country,
      minimumPackagingType,
    },
    meta: {
      update,
    },
  } as const);
