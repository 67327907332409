import { SizeUnit } from '../domain/size';
import { WeightUnit } from '../domain/weight';

export const lbToKg = (value: number) => value * 0.45359237;

export const cmToInches = (value: number) => value * 0.39370078740157;

export const weightFromTo = {
  lb: {
    kg: 0.45359237,
  },
  kg: {
    lb: 2.20462262185,
  },
};

export const sizeFromTo = {
  cm: {
    in: 0.39370078740157,
  },
  in: {
    cm: 2.54,
  },
};

export const convertSizeDimension = (
  fromUnit: SizeUnit,
  toUnit: SizeUnit,
  value: number,
) => (fromUnit === toUnit ? value : value * sizeFromTo[fromUnit][toUnit]);

export const convertWeight = (
  fromUnit: WeightUnit,
  toUnit: WeightUnit,
  value: number,
) => (fromUnit === toUnit ? value : value * weightFromTo[fromUnit][toUnit]);
