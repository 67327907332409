import { ServiceDetail } from '../../domain/service-detail';
import { ProductQueryInput } from './product-query-input';

export const ProductQuerySucceeded = 'PRODUCT_QUERY_SUCCEEDED';

export type ProductQuerySucceeded = ReturnType<typeof productQuerySucceeded>;

export type ProductQueryCache = { asin: string };

export const productQuerySucceeded = (
  input: ProductQueryInput,
  cache: ProductQueryCache,
  detail: ServiceDetail,
) =>
  ({
    type: ProductQuerySucceeded,
    input,
    cache,
    detail,
  } as const);
