import { BoxReset, Text } from '@deliverr/ui';
import React, { FC } from 'react';
import { Currency, CurrencyProps } from '../../../common/components/Currency';

export const BoldCurrency: FC<CurrencyProps> = (currencyProps) => {
  const maximumFractionDigits = currencyProps.value % 1 === 0 ? 0 : 2;
  return (
    <BoxReset marginTop={'S4'}>
      <Text as="div" size="h2" bold>
        <Currency
          {...currencyProps}
          maximumFractionDigits={maximumFractionDigits}
        />
      </Text>
    </BoxReset>
  );
};
