import { defineMessages } from 'react-intl';

export const manualEntryMessages = defineMessages({
  length: {
    id: 'productEntry.manualEntry.length',
    defaultMessage: 'Length',
  },
  width: {
    id: 'productEntry.manualEntry.width',
    defaultMessage: 'Width',
  },
  height: {
    id: 'productEntry.manualEntry.height',
    defaultMessage: 'Height',
  },
  weight: {
    id: 'productEntry.manualEntry.weight',
    defaultMessage: 'Weight',
  },
  calculateCost: {
    id: 'productEntry.manualEntry.calculateCost',
    defaultMessage: 'Calculate cost',
  },
});
