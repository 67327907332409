/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Dispatch, FC, ReactNode, SetStateAction, useState } from 'react';
import { useTheme } from '@emotion/react';
import { NavTheme } from '../../banner/nav-theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { WithStyleProps } from '../../style/helper';
import { Brand } from 'utility-types';

export type NavItemLinkIconProps = {
  expandsOn: ExpandMethod;
  expanded: boolean;
  setExpanded: Dispatch<SetStateAction<boolean>>;
};

const NavItemLinkToggle: FC<NavItemLinkIconProps> = ({
  children,
  expandsOn,
  expanded,
  setExpanded,
}) => {
  const {
    nav: { itemLinkToggle },
  } = useTheme() as NavTheme;

  return children ? (
    expanded ? (
      <FontAwesomeIcon
        onClick={() => {
          if (expandsOn === ToggleClick) {
            setExpanded(false);
          }
        }}
        icon={faChevronUp}
        css={itemLinkToggle}
      />
    ) : (
      <FontAwesomeIcon
        onClick={() => {
          if (expandsOn === ToggleClick) {
            setExpanded(true);
          }
        }}
        icon={faChevronDown}
        css={itemLinkToggle}
      />
    )
  ) : null;
};

export type NavItemProps = {
  link: string;
  hideChildren?: boolean;
  subNav?: SubNav;
} & WithStyleProps;

export const Hover = 'hover' as Brand<'hover', 'expands-on'>;

export const ToggleClick = 'toggle-click' as Brand<
  'toggle-click',
  'expands-on'
>;

export type ExpandMethod = typeof Hover | typeof ToggleClick;

export type SubNav = {
  expandsOn: ExpandMethod;
  body: ReactNode;
};

export const NavItem: FC<NavItemProps> = ({
  children,
  link,
  subNav = null,
  className,
}) => {
  const {
    nav: { item, itemLink, itemLinkWrapper, subNavWrapper },
  } = useTheme() as NavTheme;

  const [expanded, setExpanded] = useState(false);

  return (
    <div
      className={className}
      css={item}
      onMouseOver={() => {
        if (subNav?.expandsOn === Hover) {
          setExpanded(true);
        }
      }}
      onMouseOut={() => {
        if (subNav?.expandsOn === Hover) {
          setExpanded(false);
        }
      }}
    >
      <div css={itemLinkWrapper}>
        <a css={itemLink} href={link}>
          {children}
        </a>{' '}
        {subNav && (
          <NavItemLinkToggle
            expandsOn={subNav.expandsOn}
            expanded={expanded}
            setExpanded={setExpanded}
          >
            {subNav}
          </NavItemLinkToggle>
        )}
      </div>
      <div css={subNavWrapper({ expanded })}>{subNav?.body}</div>
    </div>
  );
};
