import { defineMessages } from 'react-intl';

export const productEntryTypesMessages = defineMessages({
  amazonEntry: {
    id: 'productEntryType.tab.amazonEntry',
    defaultMessage: 'Amazon Link',
  },
  manualEntry: {
    id: 'productEntryType.tab.manualEntry',
    defaultMessage: 'Manual Entry',
  },
});
