import React, { FC } from 'react';

export type FulfillmentArrowIconProps = {
  foreground: string;
  background: string;
};

export const FulfillmentArrowIcon: FC<FulfillmentArrowIconProps> = ({
  foreground,
  background,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
  >
    <g fill="none">
      <rect width="28" height="28" fill={foreground} rx="2" opacity={0.7} />
      <rect
        width="21.778"
        height="21.778"
        x="3.111"
        y="3.111"
        fill={background}
        rx="1"
      />
      <path
        fill={foreground}
        d="M13.222 8.556L17.889 8.556 21 14 17.889 19.444 13.222 19.444 16.333 14z"
      />
      <path
        fill={foreground}
        d="M7 8.556L11.667 8.556 14.778 14 11.667 19.444 7 19.444 10.111 14z"
        opacity=".7"
      />
    </g>
  </svg>
);
