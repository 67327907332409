/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';
import { red } from '../style/colors';
import { FC } from 'react';
import { isErrorMessage, None, QueryMessage } from '../../domain/query-message';
import { desktop } from '../common/media/media-type';

export type QueryMessageProps = {
  message: QueryMessage;
};

export const QueryDisplayMessage: FC<QueryMessageProps> = ({ message }) =>
  message.type === None ? null : (
    <div
      css={css`
        color: ${isErrorMessage(message) ? red : 'inherit'};
        margin: 5px 0;
        font-size: 16px;
        max-width: 1040px;

        @media ${desktop} {
          position: absolute;
        }
      `}
    >
      {message.display}
    </div>
  );
