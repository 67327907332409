export const NotAnAsinError = 'NOT_AN_ASIN';
export type NotAnAsinError = typeof NotAnAsinError;

export const BadDimsError = 'BAD_DIMS';
export type BadDimsError = typeof BadDimsError;

export const ProductNotFoundError = 'PRODUCT_NOT_FOUND';
export type ProductNotFoundError = typeof ProductNotFoundError;

export type GetDetailError =
  | NotAnAsinError
  | BadDimsError
  | ProductNotFoundError;
