import React, { FC } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';

import { All } from './all';
import { ActionsProvider } from './redux/actions-middleware/actions-middleware';
import { Effects } from './effects';
import { Store, CombinedState } from 'redux';
import { Root } from './redux/state/root';
import { ThemeProvider } from 'emotion-theming';
import { defaultTheme } from '@deliverr/ui';
import { FontFamily } from '@deliverr/ui/lib-esm/shared/theme/font';
import { APP_BASE_PATH, B2B_APP_PATH } from './paths/paths';
import { B2BApp } from '../app/b2b/B2bApp';

export type ViewProps = {
  store: Store<CombinedState<Root>>;
};

const theme = {
  ...defaultTheme,
  font: {
    ...defaultTheme.font,
    family: {
      [FontFamily.STANDARD]: 'gt-america, sans-serif',
      [FontFamily.TITLE]: 'gt-america-extended, sans-serif',
    },
  },
};

export const View: FC<ViewProps> = ({ store }) => (
  <Provider store={store}>
    <ActionsProvider>
      <Effects>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <Switch>
              {/** Adding specific path before. '/' will match all the other path **/}
              <Route exact path={B2B_APP_PATH} component={B2BApp} />
              <Route path={APP_BASE_PATH} component={All} />
            </Switch>
          </ThemeProvider>
        </BrowserRouter>
      </Effects>
    </ActionsProvider>
  </Provider>
);
