/* eslint-disable no-template-curly-in-string */
import { MessageDescriptor, defineMessages } from 'react-intl';
import { OutboundShipmentType } from '../../../types/OutboundShipmentType';

export const b2bOutboundingCostMessages: Record<
  OutboundShipmentType,
  MessageDescriptor
> = defineMessages({
  [OutboundShipmentType.FREIGHT_PALLETS]: {
    id: 'b2b.rateCalculator.outboundingCost.shipmentType.freightPalletized',
    defaultMessage: 'Freight (pallets)',
  },
  [OutboundShipmentType.SMALL_PARCEL]: {
    id: 'b2b.rateCalculator.outboundingCost.shipmentType.smallParcel',
    defaultMessage: 'Small parcel (cases)',
  },
});
