import React, { createContext, FC } from 'react';
import { Subject } from 'rxjs';
export type ActionsContext = {
  actions: Subject<TypedAction<string, {}>>;
};

export type TypedAction<T extends string, U extends object> = {
  type: T;
} & U;

const actions = new Subject<TypedAction<string, {}>>();

export const ActionsContext = createContext({ actions: undefined });

export const ActionsProvider: FC = ({ children }) => {
  return (
    <ActionsContext.Provider value={{ actions }}>
      {children}
    </ActionsContext.Provider>
  );
};

export const actionsMiddleware = () => {
  return () => (next) => (action) => {
    const result = next(action);

    actions.next(action);

    return result;
  };
};
