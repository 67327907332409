/** @jsx jsx */
import { jsx } from '@emotion/core';
import css from '@emotion/css/macro';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { desktop } from '../common/media/media-type';
import { UnstyledButton } from '../common/unstyled-button';
import { getIsLanguageTypeChinese } from '../redux/selectors/get-is-language-type-chinese';
import { grey_600, indigo } from '../style/colors';
import { hoverTransition } from '../style/defaults';
import { footerMessages } from './footerMessages';

const languageContainer = css`
  cursor: pointer;
  font-weight: bold;
  margin-top: 30px;
  position: relative;
  ${hoverTransition('color')}

  &:hover {
    color: ${indigo};
    padding-bottom: 10px;
  }
`;

const languageDropdown = (expanded: boolean) => css`
  align-items: center;
  background-color: white;
  border-radius: 4px;
  color: black;
  display: ${expanded ? 'flex' : 'none'};
  flex-direction: column;
  position: absolute;
  top: 25px;
  z-index: 10;

  @media ${desktop} {
    left: 10px;
  }
`;

const languageItem = css`
  color: ${grey_600};
  font-weight: bold;
  margin: 0.5rem 0 0.5rem 0;
  padding: 10px 1rem;
  width: 200px;

  &:hover {
    color: ${indigo};
  }
`;

const languageChevron = css`
  width: 8px;
  margin-left: 8px;
`;

export const LanguageSelect = () => {
  const { formatMessage } = useIntl();
  const isLanguageTypeChinese = useSelector(getIsLanguageTypeChinese);
  const history = useHistory();

  const [expanded, setExpanded] = useState(false);

  return (
    <div
      css={languageContainer}
      onMouseOver={() => setExpanded(true)}
      onMouseOut={() => setExpanded(false)}
    >
      {formatMessage(footerMessages.language, {
        language: isLanguageTypeChinese ? 'CN' : 'EN',
      })}
      {expanded ? (
        <FontAwesomeIcon icon={faChevronUp} css={languageChevron} />
      ) : (
        <FontAwesomeIcon icon={faChevronDown} css={languageChevron} />
      )}
      <div css={languageDropdown(expanded)}>
        <UnstyledButton css={languageItem} onClick={() => history.push('/')}>
          English
        </UnstyledButton>
        <UnstyledButton css={languageItem} onClick={() => history.push('/cn')}>
          中文 (简体)
        </UnstyledButton>
      </div>
    </div>
  );
};
