import { useIntl } from 'react-intl';
import { useB2BCostCalculatorContext } from '../../../store/B2BCostCalculatorContext';
import { OutboundShipmentType } from '../../../types/OutboundShipmentType';
import { b2bOutboundingCostMessages } from './b2bOutboundingCostMessages';

export const useB2BOutboundingCost = () => {
  const {
    outboundShipmentType,
    setOutboundShipmentType,
    outboundHandlingCost,
    outboundLabellingCost,
  } = useB2BCostCalculatorContext();
  const { formatMessage } = useIntl();
  const outboundShipmentTypeOptions = Object.values(OutboundShipmentType).map(
    (value) => ({
      value,
      label: formatMessage(b2bOutboundingCostMessages[value]),
    }),
  );

  return {
    outboundShipmentType,
    setOutboundShipmentType,
    outboundShipmentTypeOptions,
    outboundHandlingCost,
    outboundLabellingCost,
  };
};
