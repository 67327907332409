import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { InitConfiguration } from '@datadog/browser-core';

export function initDataDog() {
  if (!(process.env.NODE_ENV === 'production')) {
    console.log('not production');
    return;
  }

  const baseConfig: Pick<
    InitConfiguration,
    'clientToken' | 'site' | 'service' | 'env'
  > = {
    clientToken: 'pubf49de049dde5b7d081fd51d53a7509ba',
    site: 'datadoghq.com',
    service: 'cost-calculator-prod',
    env: 'production',
  };

  datadogLogs.init({
    ...baseConfig,
    forwardErrorsToLogs: true,
    sampleRate: 100,
  });

  datadogRum.init({
    ...baseConfig,
    applicationId: 'd9cfef1e-6dc2-4ba1-b6e2-cd917eb50a50',
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    replaySampleRate: 100,
  });

  datadogRum.startSessionReplayRecording();
}

export const logError = (message: string, ctx) => {
  datadogLogs.logger.error(message, ctx);
};
