import { defineMessages } from 'react-intl';

export const footerMessages = defineMessages({
  heading: {
    id: 'footer.header',
    defaultMessage: 'Learn why top sellers are talking about Flexport',
  },
  subText: {
    id: 'footer.header.subText',
    defaultMessage:
      'Experience the speed and efficiency of a network that fulfills thousands of orders daily across multiple channels',
  },
  getStarted: {
    id: 'footer.header.getStarted',
    defaultMessage: 'Get Started',
  },
  letsChat: {
    id: 'footer.header.letsChat',
    defaultMessage: 'Need more info? {letsChat}',
  },
  products: {
    id: 'footer.column.header.products',
    defaultMessage: 'Products',
  },
  d2c: {
    id: 'footer.column.products.d2c',
    defaultMessage: 'D2C Fulfillment',
  },
  wholesale: {
    id: 'footer.column.products.fulfillment',
    defaultMessage: 'Wholesale Fulfillment',
  },
  parcel: {
    id: 'footer.column.products.parcel',
    defaultMessage: 'Parcel',
  },
  replenishment: {
    id: 'footer.column.products.replenishment',
    defaultMessage: 'Replenishment',
  },
  prep: {
    id: 'footer.column.products.prep',
    defaultMessage: 'Prep',
  },
  company: {
    id: 'footer.column.header.company',
    defaultMessage: 'Company',
  },
  aboutUs: {
    id: 'footer.column.company.aboutUs',
    defaultMessage: 'About Us',
  },
  careers: {
    id: 'footer.column.company.careers',
    defaultMessage: 'Careers',
  },
  blog: {
    id: 'footer.column.company.blog',
    defaultMessage: 'Blog',
  },
  caseStudies: {
    id: 'footer.column.header.caseStudies',
    defaultMessage: 'Case Studies',
  },
  ritualZeroProof: {
    id: 'footer.column.caseStudies.ritualZeroProof',
    defaultMessage: 'How Ritual Zero Proof went from entrepreneur to enterprise with configurable logistics',
  },
  kizik: {
    id: 'footer.column.caseStudies.kizik',
    defaultMessage: 'Kizik puts its best foot forward, grows business 4x year-over-year with DTC fulfillment and logistics services',
  },
  followUs: {
    id: 'footer.followUs',
    defaultMessage: 'Follow us on',
  },
  termsOfService: {
    id: 'footer.termsOfService',
    defaultMessage: 'Terms of Service',
  },
  privacy: {
    id: 'footer.privacy',
    defaultMessage: 'Privacy',
  },
  language: {
    id: 'footer.language',
    defaultMessage: 'Language ({language})',
  },
});
