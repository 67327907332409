import { TabSelected } from '../../../product-entry-type/change-tab';
import {
  defaultManualEntryForm,
  ManualEntryForm,
} from '../../../../domain/entry/manual-entry-form';
import { ManualQuerySucceeded } from '../../../product-entry/manual-entry/manual-query-success';
import { ProductQuerySucceeded } from '../../../main/product-query-success';
import { AmazonQuerySucceeded } from '../../../product-entry/amazon-entry/amazon-query-success';

export type ManualEntry = ManualEntryForm;

export const initialManualEntry: ManualEntry = defaultManualEntryForm;

export type ManualEntryAction =
  | ManualQuerySucceeded
  | TabSelected
  | AmazonQuerySucceeded
  | ProductQuerySucceeded;

export const manualEntry = (
  state = initialManualEntry,
  action: ManualEntryAction,
) => {
  switch (action.type) {
    case ManualQuerySucceeded:
      // reset form
      return { ...initialManualEntry };
    case AmazonQuerySucceeded:
    case ProductQuerySucceeded:
    case TabSelected:
      return initialManualEntry;
    default:
      return state;
  }
};
